@media (max-width: 768px) {
	.insght_lst_link {
		display: inline-block !important;
		width: 100% !important;
		word-wrap: break-word !important;
		white-space: normal !important;
	}
}
@media (max-width: 768px) {
	.secBlue {
		width: 120px;
		color: #ffffff !important;
		background-color: #02aee8 !important;
		border: none !important;
		border-radius: 20px;
		height: 100% !important;
		padding-top: 8px;
		transition: background-color 0.3s, color 0.3s, border 0.3s;
		margin-bottom: 0;
		margin-top: 30px;
		margin-left: 10px;
	}
}
.secBlue {
	min-width: 108px !important;
	min-height: 40px !important;
	display: flex !important;
	align-items: center !important;
	color: #ffffff !important;
	background-color: #02aee8 !important;
	border: none !important;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 8px;
	transition: background-color 0.3s, color 0.3s, border 0.3s;
	margin-bottom: 0;
}

/* Disabled state styles */
.secBlue:disabled {
	color: #a8e1f5 !important;
	background-color: #e3f6fc !important;

	border: none !important;
	pointer-events: none; /* Prevents hover styles from being applied */
}

/* Hover state styles */
.secBlue:hover {
	background-color: #0099cc !important;
}

/* Prevent hover styles when disabled */
.secBlue:disabled:hover {
	color: #a8e1f5 !important;
	background-color: #e3f6fc;
	border: none !important;
}
.homeContainer {
	width: 100%;
	background-color: #fff;
	.homeContent {
		.body {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			padding: 30px 40px;
			background-color: #fff;
			.bodyItem {
				display: flex;
				flex-direction: column;
				width: 498px;
				height: 720px;
				.bodyItemTitle {
					margin-bottom: 20px;
					color: #333;
					font-size: 24px;
					font-weight: 600;
				}
				.bodyItemCon {
					position: relative;
					display: flex;
					flex-direction: column;
					border: 1px solid #ccc;
					border-radius: 4px;
					padding: 40px 20px;
					.tip {
						position: absolute;
						left: -1px;
						top: -1px;
					}
					.top {
						display: flex;
						align-items: center;
						justify-content: space-between;
						.left {
							display: flex;
							align-items: center;
							.img {
								width: 100px;
								height: 100px;
								border-radius: 50%;
								.pic {
									width: 100px;
									height: 100px;
									border-radius: 50%;
								}
							}
							.name {
								margin-left: 16px;
								color: #333;
								font-size: 24px;
							}
						}
						.right {
							.score {
								color: #f49420;
								font-size: 40px;
							}
						}
					}
					.info {
						display: flex;
						justify-content: space-between;
						margin-top: 30px;
						padding: 20px 0;
						background-image: linear-gradient(
							to bottom right,
							#f1f1f1,
							#fcfcfc
						);
						.left {
							display: flex;
							align-items: center;
							.text2 {
								margin-left: 80px;
							}
						}
					}
					.level {
						display: flex;
						flex-direction: column;
						padding-top: 30px;
						.levelItem {
							display: flex;
							align-items: center;
							justify-content: space-between;
							.levelItemL {
								display: flex;
								align-items: center;
								margin-bottom: 36px;
								.ItemRank {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 32px;
									height: 32px;
									border-radius: 50%;
									background-color: #ccc;
									color: #fff;
									font-size: 24px;
								}
								.two {
									background-color: #d0d3dc;
								}
								.three {
									background-color: #f1bf78;
								}
								.ItemInfo {
									display: flex;
									align-items: center;
									justify-content: center;
									margin-left: 40px;
									.ItemImg {
										width: 32px;
										height: 32px;
										.pic {
											width: 32px;
											height: 32px;
											border-radius: 50%;
										}
									}
									.ItemName {
										width: 120px;
										margin-left: 10px;
									}
								}
							}
						}
					}
				}
			}
		}
		.footer {
			display: flex;
			padding-bottom: 100px;
			background-color: #fff;
			overflow: hidden;
			.bgc {
				position: relative;
				z-index: 1;
				left: -130px;
				width: 40rem;
				height: 40rem;
				border-radius: 50%;
				background-image: linear-gradient(
					rgba(87, 160, 209, 0.1),
					rgba(44, 103, 164, 0.1)
				);
			}
			.polyLine {
				display: flex;
				position: relative;
				margin: 100px 0 0 -530px;
				z-index: 999;
				.polyLineTitle {
					display: flex;
					flex-direction: column;
					margin-right: 50px;
					.info {
						color: #333;
						font-size: 40px;
						font-weight: 600;
					}
					.question {
						margin: 36px 0 50px 0;
						width: 478px;
						color: #666;
						font-size: 20px;
						line-height: 28px;
					}
					.type {
						display: flex;
						align-items: center;
						.left,
						.right {
							display: flex;
							align-items: center;
							justify-content: center;
							.l {
								width: 22px;
								height: 8px;
								margin-right: 20px;
								background-color: #5883f7;
							}
							.r {
								color: #333;
								font-size: 16px;
							}
						}
						.left {
							margin-right: 40px;
						}
						.right {
							.l {
								background-color: #5dd29d;
							}
						}
					}
				}
			}
		}
	}
}
.newDetail {
	background-size: 100% 100% !important;
	// background: linear-gradient(
	// 	180deg,
	// 	rgba(223, 236, 253, 1) 0%,
	// 	rgba(255, 255, 255, 1) 100%
	// );
	padding-top: 68px;
	width: 100%;
	.newContainer {
		padding-left: 15px !important;
		padding-right: 15px !important;
		@media (max-width: 768px) {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
		// margin: 0 2rem;
		.title {
			font-size: 3rem;
			font-weight: 700;
			color: rgba(40, 57, 94, 1);
			@media (max-width: 768px) {
				font-size: 1.5rem;
			}
		}
		.companyTags,
		.gameTags {
			display: inline-block;
			padding: 2px 10px;
			border-radius: 5px;
			margin-top: 10px;
			margin-right: 10px;
			cursor: pointer;
			font-weight: 500;
		}
		.gameTags {
			background-color: #def4e7;
			color: #19ae54;
		}
		.gameTags:hover {
			color: white;
			background-color: #19ae54;
		}
		.companyTags {
			background-color: #f1dff1;
			color: #9e219e;
		}
		.companyTags:hover {
			color: white;
			background-color: #9e219e;
		}

		.headerInfo {
			margin-top: 27px;
			display: flex;
			justify-content: space-between;
			padding-bottom: 33px;
			border-bottom: 1px solid rgba(199, 217, 242, 1);
			@media (max-width: 768px) {
				flex-direction: column-reverse;
				gap: 20px;
			}
			span {
				margin-right: 2.5rem;
				font-size: 16px;
				font-weight: 400;
				color: rgba(153, 153, 153, 1);
			}
			span:last-child {
				margin-right: 0;
			}
			.link:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}
		.contentBox {
			margin-top: 18px;
			width: 100%;
			p,
			span,
			font {
				font-size: 18px !important;
			}
			img {
				width: 100% !important;
				height: fit-content !important;
				border-radius: 12px;
			}
			ul li {
				list-style: disc inside !important;
				padding-left: 1em; /* Adjust the padding as needed */
				font-size: medium;
			}

			ol li {
				list-style: decimal inside !important;
				padding-left: 1em; /* Adjust the padding as needed */
				font-size: medium;
			}
		}
		.footerInfo {
			margin-top: 8px;
			.link {
				display: flex;
				font-size: 18px;
				font-weight: 400;
				margin-bottom: 3rem;
				color: #02aee8;
				span {
					color: rgba(153, 153, 153);
				}
				a {
					display: flex;
					font-weight: 500;
					color: #02aee8;
					span {
						color: #02aee8;
					}
				}
				a:hover {
					text-decoration: underline;
				}
			}

			.btns {
				display: flex;
				justify-content: flex-end;
				margin-top: 24px;

				.last-btn {
					margin-left: 1rem;
				}
				.btnIcon {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 50px;
					height: 50px;
					border-radius: 50%;
					cursor: pointer;
					border: 1px solid #88acce;
					img {
						width: 45%;
						height: 45%;
					}
				}
				.btnIcon:hover {
					background-color: #ecf4fa;
				}
			}
		}
		.topNews,
		.topBlogs {
			margin-top: 74px;
			height: fit-content;
			border-radius: 12px;
			box-sizing: border-box;
			.title {
				width: 100%;
				border-radius: 8px 8px 0px 0px;
				height: 83px;
				line-height: 83px;
				font-size: 30px;
				font-weight: 700;
				color: #2864a1;
				border-bottom: 1px solid rgba(199, 217, 242, 1);
			}
			.newsItem {
				padding: 25px 30px 35px 30px;
				border-bottom: 1px solid rgba(199, 217, 242, 1);

				.head {
					cursor: pointer;
					font-size: 27px;
					font-weight: 700;
					color: #2e373c;
					letter-spacing: -0.1px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.topDate {
						color: #70727b;
						font-size: 14px;
						font-weight: normal;
					}
				}
				.text {
					margin-top: 8px;
					font-size: 18px;
					font-weight: 400;
					color: rgba(51, 51, 51, 1);
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;
					cursor: pointer;
				}
			}
			.newsItem:hover {
				background-color: #f4fbff;
			}
		}
	}
}
.breaker {
	border-bottom: 1px solid rgba(199, 217, 242, 1);
}

.listLink {
	display: flex;
	font-size: 18px;
	font-weight: 400;
	margin: 1rem 1rem 1.5rem 0;
	color: rgba(153, 153, 153);
}
@media (max-width: 768px) {
	.text-element {
		font-size: 12px;
	}
}
@media (max-width: 768px) {
	.listLink {
		margin: 0rem 0rem 0rem 0;
	}
}
.listLink:hover {
	text-decoration: underline;
	cursor: pointer;
}

@primary-color: #003778;@border-radius-base: 6px;@layout-header-background: #ffffff;@layout-sider-background: #003778;@menu-bg: #003778;@menu-inline-toplevel-item-height: 94px;@menu-item-height: 53px;@menu-item-color: #ffffff;@menu-highlight-color: #ffffff;@menu-inline-submenu-bg: #003778;@menu-item-active-bg: rgba(128, 160, 231, 0.3);@menu-popup-bg: #003778;@menu-item-active-border-width: 0px;