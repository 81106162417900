* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}
#root,
body,
html {
  height: calc(100vh / 0.75) !important;
  overflow: hidden;
  /* Prevents vertical scrolling */
}
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none !important;
}
.custom-pagination .ant-pagination-total-text {
  order: 1;
  margin-right: 16px;
  margin-left: 32px;
  color: #70727b !important;
  padding-top: 2px;
}
.custom-class .ant-notification-notice-message {
  margin-left: 0px !important;
}
.sc .ant-message-notice-content {
  color: white !important;
  /* Green text color */
  font-weight: bold !important;
  background-color: green !important;
  /* Light green background */
  border: none !important;
  /* Green border */
  border-radius: 4px !important;
}
.ant-menu-title-content {
  font-size: 15px !important;
}
.custom-pagination .ant-pagination-options {
  order: 2;
}
.custom-pagination .ant-pagination-item {
  order: 0;
}
.ant-selec {
  padding-top: 0 !important;
}
.custom-pagination .ant-pagination-options .ant-select-selector {
  height: 32px !important;
  /* Set the height */
  min-height: 32px !important;
  padding: 0 8px;
  /* Adjust padding inside the select */
  font: normal normal medium 14px/32px Inter;
  /* Adjust font styling */
  line-height: 32px;
  /* Align text vertically */
  border: 1px solid #d9d9d9;
  /* Customize border */
  border-radius: 4px;
  /* Optional: round the corners */
}
.ant-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.ant-pagination-item {
  margin: 2px !important;
  padding: 2px !important;
  color: #2e373c;
  font: normal normal medium 14px/32px Inter;
  letter-spacing: 0px;
  text-align: center;
  border: none !important;
  border-radius: 5px !important;
  opacity: 1;
}
.ant-pagination-item:hover {
  color: white !important;
  background: #02aee8;
  cursor: pointer;
  /* Change cursor to pointer */
}
.ant-pagination-item-active {
  background: #02aee8;
  color: white !important;
  border-radius: 4px !important;
}
.ant-pagination-item-active a {
  color: white !important;
}
.ant-pagination-prev .ant-pagination-item-link {
  border: none;
}
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}
.ant-table-pagination-right {
  justify-content: flex-start !important;
}
.ant-pagination-options {
  position: absolute;
  right: 0;
}
.ant-pagination-options-quick-jumper {
  display: none;
}
.ant-pagination-item-link:hover {
  color: white !important;
  background-color: #0099cc !important;
  border-radius: 4px !important;
}
@media (max-width: 768px) {
  .custom-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* Allow items to wrap */
  }
  .ant-pagination-options {
    position: static !important;
    /* Change to static if absolute is causing issues */
    margin-top: 8px !important;
    /* Add space if necessary */
  }
  .custom-pagination .ant-pagination-options {
    display: flex;
    align-items: center;
  }
  .custom-pagination .ant-pagination-total-text {
    width: 50%;
    /* Make the total text take the full width */
    text-align: left;
    /* Align text to the left */
    margin-top: 5px;
    /* Add space below the total text */
    margin-right: 0;
    margin-left: 0;
  }
  .custom-pagination .ant-pagination-total-text {
    width: 48%;
    order: -2;
    /* Move total text above pagination items */
  }
  .custom-pagination .ant-pagination-options {
    order: -1;
    /* Move total text above pagination items */
    width: 49%;
    margin: 0;
    justify-content: flex-end;
    display: flex;
  }
  .custom-pagination .ant-pagination-item,
  .custom-pagination .ant-pagination-prev,
  .custom-pagination .ant-pagination-next {
    margin: 0 5px;
    /* Space between pagination items */
  }
  .custom-pagination .ant-pagination-options {
    margin-bottom: 10px;
    /* Space between options and total text */
  }
  .ant-pagination-options-quick-jumper {
    display: none;
  }
}
.applySearchBtn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 40px !important;
  height: 40px !important;
  margin: 0 30px 0 8px !important;
  background-color: #02aee8 !important;
  border: none !important;
  border-radius: 50% !important;
  transition: background-color 0.3s ease;
  /* Smooth transition */
}
.applySearchBtn:hover {
  background-color: #028fc2 !important;
  /* Darker shade for hover */
}
@media (max-width: 768px) {
  .applySearchBtn {
    margin: 0 10px 0 5px !important;
    /* Reduced margin for mobile */
  }
}
.searchBlog {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 3vw;
  height: 60px;
  margin-bottom: -20px;
  margin-top: 10px;
}
.compBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 3vw;
  height: 60px;
  margin-bottom: -20px;
  margin-top: 10px;
}
.customInput {
  width: 250px;
  height: 36px;
  margin-top: 0 !important;
  border: 1px solid #b6b6be !important;
}
@media (max-width: 400px) {
  .customInput {
    width: 200px;
    height: 36px;
    margin-top: 0 !important;
  }
}
.customInputBlog {
  width: 250px;
  height: 36px;
  margin-top: 28px !important;
}
.vanaDetails {
  margin: 0px 15rem;
}
@media (max-width: 768px) {
  .vanaDetails {
    margin: 0px 2rem;
  }
}
.areaRegionBox {
  display: flex;
}
@media (max-width: 768px) {
  .areaRegionBox {
    flex-direction: column;
    gap: 10px;
  }
}
@media (max-width: 768px) {
  .searchBlog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 60px;
  }
  .compBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 60px;
  }
  .customInputBlog {
    width: 200px;
    height: 36px;
    margin-top: 28px !important;
  }
}
@media (max-width: 345px) {
  .searchBlog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 60px;
  }
  .customInputBlog {
    width: 150px;
    height: 36px;
    margin-top: 28px !important;
  }
}
.primarySearch {
  align-items: center;
  min-width: 74px !important;
  min-height: 32px !important;
  color: #ffffff !important;
  padding-top: 6px !important;
  background-color: #f47b23 !important;
  border: none !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
}
.primarySearch:hover {
  background-color: #d3691c !important;
  /* Slightly darker shade */
}
.secReset {
  display: flex !important;
  align-items: center;
  min-width: 74px !important;
  min-height: 32px !important;
  color: #2864a1 !important;
  padding-top: 7px !important;
  background-color: #ffffff !important;
  border: 1px solid #88acce !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}
.secReset:hover {
  color: #2864a1 !important;
  border: #2864a1 2px solid !important;
}
.primaryBig {
  align-items: center;
  min-width: 74px !important;
  min-height: 32px !important;
  color: #ffffff !important;
  background-color: #f47b23 !important;
  padding-top: 6px !important;
  border: none !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
}
.primaryBig:hover {
  background-color: #d3691c !important;
  /* Slightly darker shade */
}
.secBig {
  display: flex !important;
  align-items: center;
  min-width: 74px !important;
  min-height: 32px !important;
  color: #2864a1 !important;
  background-color: #ffffff !important;
  border: 1px solid #88acce !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  padding-top: 6px !important;
}
.secBig:hover {
  color: #2864a1 !important;
  border: #2864a1 2px solid !important;
}
.ant-select-dropdown {
  top: 60px !important;
  left: 0 !important;
}
@media (max-width: 768px) {
  .ant-layout {
    padding-top: 0px !important;
  }
}
.ant-picker-range {
  min-height: 46px !important;
}
.ant-picker-dropdown {
  top: 53px !important;
  left: -60px !important;
}
.ant-layout-sider {
  height: 100% !important;
  flex: 0 0 252px !important;
  max-width: 252px !important;
  min-width: 252px !important;
  width: 252px !important;
}
.ant-layout-sider-collapsed {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;
}
.ant-layout-sider-children {
  overflow: hidden !important;
  /* Hides the scrollbar */
}
.ant-layout.ant-layout-has-sider {
  height: 100vh;
}
.ant-tooltip {
  margin-top: calc(890px / 0.75) !important;
  margin-left: calc(800px / 0.75) !important;
}
.ant-dropdown {
  width: fit-content !important;
  top: 90px !important;
  /* Adjusts the vertical position */
  right: 1% !important;
  /* Positions it 10% from the right side */
  left: auto !important;
  /* Resets the left property */
}
.ant-select-selector {
  min-height: 46px !important;
  display: flex;
  align-items: center;
  border: 1px solid #b6b6be !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}
.ant-picker {
  min-height: 46px !important;
  display: flex;
  align-items: center;
  border: 1px solid #b6b6be !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}
.ant-picker .ant-picker-suffix .anticon {
  transition: color 0.3s ease;
  /* Smooth transition for the color change */
}
.ant-picker:hover .ant-picker-suffix .anticon {
  color: #02aee8;
  /* Change the icon color to red on hover */
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
}
.ant-menu-submenu-popup {
  margin-top: 1100px;
  margin-left: 1070px;
}
.ant-layout {
  background-color: #fff;
  display: flex;
  width: 100%;
  min-height: 100%;
}
@media (max-width: 600px) {
  .ant-picker-panels {
    display: inline-flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    direction: ltr !important;
  }
}
@media (max-width: 600px) {
  .contavtText {
    display: none;
  }
}
.primarySubmit {
  align-items: center;
  min-width: 102px !important;
  min-height: 40px !important;
  color: #ffffff !important;
  background-color: #f47b23 !important;
  border: none !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
}
.primarySubmit:hover {
  background-color: #d3691c !important;
  /* Slightly darker shade */
}
.primarySubmit:disabled {
  color: #fad2b5 !important;
  background-color: #fff2e8 !important;
  border: none !important;
  pointer-events: none;
  /* Prevents hover styles from being applied */
}
/* Prevent hover styles when disabled */
.primarySubmit:disabled:hover {
  color: #d8d8d8 !important;
  background-color: #ffffff !important;
  border: none !important;
}
.secCancel {
  align-items: center;
  min-width: 102px !important;
  min-height: 40px !important;
  color: #2864a1 !important;
  background-color: #ffffff !important;
  border: 1px solid #88acce !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}
/* Disabled state styles */
.secCancel:disabled {
  color: #d8d8d8 !important;
  border: none !important;
  pointer-events: none;
  /* Prevents hover styles from being applied */
}
/* Hover state styles */
.secCancel:hover {
  color: #2864a1 !important;
  border: #2864a1 2px solid !important;
}
/* Prevent hover styles when disabled */
.secCancel:disabled:hover {
  color: #d8d8d8 !important;
  background-color: #ffffff !important;
  border: none !important;
}
.overflowText {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.wd25 {
  max-width: calc(25% - 5px);
}
.wd33 {
  width: calc(33.33% - 10px);
}
.wd50 {
  max-width: calc(50% - 10px);
}
.wd66 {
  width: calc(66.66% - 10px);
}
.wd100 {
  width: 100%;
}
.mt50 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt30 {
  margin-top: 30px;
}
.mt20 {
  margin-top: 20px;
}
.mt15 {
  margin-top: 15px;
}
.ml20 {
  margin-left: 20px;
}
.pd30 {
  padding: 30px;
}
.pd20 {
  padding: 20px;
}
.pd22 {
  padding: 22px;
}
.DetailBg {
  background: url(../../../assets/images/backgroupimg.png) top center no-repeat #fff;
  background-size: 100%;
}
.BorderBox {
  border: 1px solid rgba(40, 57, 94, 0.2);
  border-radius: 8px;
  overflow: hidden;
}
.Global_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Global_title img {
  width: 16px;
  height: 16px;
}
.dark_row {
  background-color: #fbfcfe;
}
.BG_white {
  background-image: none;
  background-color: white;
}
.FCblue {
  color: #7297e8;
}
.Global_Jb_title {
  background-image: linear-gradient(to right, #eff4f8, #fff);
  background-color: #44609d;
}
.linkUnderline {
  text-decoration: underline;
  color: #2e65dd;
}
.textcolor1 {
  color: #80a0e7 !important;
}
.textcolor2 {
  color: #ea9419 !important;
}
.textcolor3 {
  color: #2e65dd !important;
}
.textcolor4 {
  color: #6d41dd !important;
}
.textcolor5 {
  color: #80a0e7 !important;
}
.rank {
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
}
.rank1,
.rank2,
.rank3 {
  margin: 0 auto;
  width: 23px;
  height: 23px;
  font-size: 0;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.rank1 {
  background-image: url("../../../assets/menu/rank1.png");
}
.ant-menu-submenu-title {
  white-space: pre-wrap;
  /* CSS to allow wrapping */
}
.rank2 {
  background-image: url("../../../assets/menu/rank2.png");
}
.rank3 {
  background-image: url("../../../assets/menu/rank3.png");
}
h2.ant-typography,
.ant-typography h2 {
  line-height: 1 !important;
}
.ant-menu-submenu-popup > .ant-menu {
  border-radius: 4px !important;
  padding: 5px !important;
  background-color: #0d67af;
  margin-left: 8px !important;
}
.ant-menu-submenu-popup > .ant-menu > li {
  height: 50px !important;
  line-height: 50px !important;
  margin-top: 0 !important;
  margin-bottom: 2px !important;
}
.ant-menu-submenu-popup > .ant-menu > li:hover {
  border-radius: 4px;
  background-color: #418dc8;
}
.ant-menu-submenu > div:hover {
  border-radius: 4px;
  background-color: #418dc8;
}
.pdb40 {
  padding-bottom: 40px;
}
.ant-empty {
  padding: 40px 0;
}
.New_Home_Title {
  font-size: 32px;
  color: #003778;
  font-weight: 650;
}
.box_show {
  box-shadow: #6b5d5d 0 2px 6px 0;
  border-radius: 5px;
  padding: 35px;
}
@media (max-width: 800px) {
  .active .rich_pdf .controls {
    margin-top: 300px;
  }
}
@media (max-width: 2560px) {
  .New_Home_Box .New_Home_Stream_Box .footer_Mod_Label .Niko_Index_Box .Niko_Index_Text_Box {
    font-size: 30px !important;
  }
  .New_Home_Box .New_Home_Stream_Box .footer_Mod_Label .Niko_Index_Box {
    justify-content: flex-start;
  }
  .New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img,
  .New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img {
    width: 600px;
    max-height: 450px !important;
    height: 100%;
    margin-left: -20px;
  }
}
@media (max-width: 1280px) {
  .ant-layout.layout_container .New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img,
  .ant-layout.layout_container .New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img {
    width: 450px !important;
    margin-left: 0px !important;
  }
}
@media (max-width: 1920px) {
  .ant-layout.layout_container .New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img,
  .ant-layout.layout_container .New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img {
    width: 450px !important;
    margin-left: 0px !important;
  }
}
html {
  zoom: 0.75;
}
.ant-input .ant-input-password {
  align-items: center;
  min-height: 30px !important;
  border: 1px solid #b6b6be !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}
#advanced_search_keyWord {
  margin-top: 0px !important;
}
.ant-input:focus {
  border-color: #4ad1ff !important;
  box-shadow: none !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
		.ant-select-customize-input
	) .ant-select-selector {
  box-shadow: none !important;
}
.ant-select-selection-placeholder {
  margin-top: 2px !important;
}
.main_main {
  /* Add these properties to make it scrollable */
  height: 125vh;
  /* Adjust based on your layout */
  overflow: auto;
  /* Enables scrolling */
}
@-moz-document url-prefix() {
  .ant-layout-content {
    -moz-transform: scale(0.9);
    -moz-transform-origin: center top;
    /* Add these properties to make it scrollable */
  }
  .ant-layout.layout_container .New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img,
  .ant-layout.layout_container .New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img {
    margin-left: 80px !important;
    width: 417px !important;
  }
}
.layout_container .ant-layout-header {
  z-index: 9999999;
}
.custom-tabs .ant-tabs-tab {
  border: none !important;
  padding: 8px 16px !important;
  margin: 10px 8px !important;
  color: black !important;
  /* Customize the text color */
  background-color: white !important;
  transition: color 0.3s ease, background-color 0.3s ease;
  /* Smooth transition for text color and background */
  position: relative;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #02aee8 !important;
  /* Customize the text color */
}
.ant-tabs-tab {
  font-size: 1.2rem !important;
}
@media (max-width: 768px) {
  .ant-tabs-tab {
    font-size: 1rem !important;
    margin-bottom: 20px !important;
  }
}
.custom-tabs .ant-tabs-tab-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  /* Customize the thickness of the border */
  background-color: #02aee8;
  /* Customize the border color */
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}
/* Apply a hover effect to show how the border moves */
.custom-tabs .ant-tabs-tab-active:hover::after,
.custom-tabs .ant-tabs-tab-active::after {
  transform: scaleX(1);
}
.custom-tabs .ant-tabs-tab:not(.ant-tabs-tab-active)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  /* Customize the thickness of the border */
  background-color: transparent;
  /* Hidden by default */
  transition: transform 0.3s ease-in-out;
  transform: scaleX(0);
}
.custom-tabs .ant-tabs-ink-bar {
  display: none !important;
  /* Ensure the ink bar is completely hidden */
  width: 0 !important;
  /* Prevent any potential rendering */
}
.ant-tabs-nav {
  margin-left: 26px !important;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border: none !important;
}
.custom-tabs .ant-tabs-tab:not(.ant-tabs-tab-active):hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  /* Same thickness as the active border */
  background-color: gray;
  /* Set the underline color to gray */
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
  transform: scaleX(1);
  /* Show the underline on hover */
}
.custom-tabs .ant-tabs-tab:not(.ant-tabs-tab-active)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  /* Customize the thickness of the border */
  background-color: transparent;
  /* Hidden by default */
  transition: transform 0.3s ease-in-out;
  transform: scaleX(0);
}
.custom-checkbox .ant-checkbox-inner {
  border-color: #707070;
}
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important;
  border-color: #02aee8 !important;
}
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #02aee8;
  /* This changes the checkmark symbol to black */
}
.custom-radio .ant-radio-inner {
  position: relative !important;
  top: 0 !important;
  left: 1px !important;
  margin-bottom: 5px;
  border-color: black;
}
/* Change the border and the selected symbol to red when the radio is checked */
.custom-radio .ant-radio-checked .ant-radio-inner {
  border-color: #02aee8 !important;
}
.custom-radio .ant-radio-checked .ant-radio-inner::after {
  background-color: #02aee8;
  /* This changes the inner circle (select symbol) to red */
}
.custom-radio .ant-radio-inner:hover {
  border-color: #02aee8;
  /* Change border color on hover */
}
.ant-radio-group {
  display: flex !important;
  align-items: start !important;
  justify-content: space-evenly !important;
}
.custom-radio-item {
  display: flex !important;
  align-items: center !important;
  /* Centers items vertically */
  justify-content: center !important;
}
.ant-switch-inner {
  color: #02aee8 !important;
}
.custom-switch-checked {
  background-color: white;
  /* Green when checked */
  border: 1px solid #b6b6be;
}
.ant-switch {
  min-width: 40px !important;
}
.custom-switch-unchecked {
  background-color: white;
  /* Green when checked */
  border: 1px solid #b6b6be;
}
.custom-switch-checked .ant-switch-handle {
  border-color: white;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
		.ant-select-customize-input
	) .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
		.ant-select-customize-input
	) .ant-select-arrow {
  color: #02aee8 !important;
}
.custom-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  /* Force the color to white */
  font-size: 12px;
  /* Adjust size as needed */
  background-color: #02aee8;
  /* Background color of the switch handle */
  border-radius: 50% !important;
  transform: translate(2%, -16%);
  content: "\2713";
}
.custom-switch-unchecked .ant-switch-handle::before {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  font-size: 12px;
  /* Adjust size as needed */
  background-color: #b6b6be;
  border-radius: 50% !important;
  transform: translate(-13%, -13%);
}
.custom-switch-unchecked .ant-switch-handle:hover::before {
  background-color: #70727b;
  /* Change to desired hover color */
}
.custom-switch-checked .ant-switch-handle {
  width: 21px;
  /* Adjust the width as needed */
  height: 22px;
  /* Adjust the height as needed */
}
.custom-switch-unchecked .ant-switch-handle {
  width: 21px;
  /* Ensure it's consistent when unchecked */
  height: 22px;
}
.purbleTag {
  cursor: pointer;
  color: #9e219e !important;
  background-color: #f1dff1;
  border: none !important;
  border-radius: 2 !important;
  padding: 2px 8px 3px 8px;
  transition: all 0.3s ease;
  /* Smooth transition */
  margin: 3px;
  max-width: 150px !important;
  margin-left: 0px !important;
  white-space: nowrap;
  /* Prevents the text from wrapping to the next line */
  overflow: hidden;
  /* Hides the overflowed text */
  text-overflow: ellipsis;
  /* Adds "..." to indicate overflow */
}
.ant-form-item-label > label {
  text-transform: uppercase;
}
.purbleTag:hover {
  background-color: #9e219e !important;
  /* Optional: change background on hover */
  color: white !important;
  /* Optional: change text color on hover */
}
.greenTag {
  cursor: pointer;
  color: #19ae54 !important;
  background-color: #def4e7;
  border: none !important;
  border-radius: 2 !important;
  padding: 2px 8px 3px 8px;
  transition: all 0.3s ease;
  /* Smooth transition */
  margin: 3px;
  max-width: 150px !important;
  white-space: nowrap;
  /* Prevents the text from wrapping to the next line */
  overflow: hidden;
  /* Hides the overflowed text */
  text-overflow: ellipsis;
  /* Adds "..." to indicate overflow */
  margin-left: 0px !important;
}
.greenTag:hover {
  background-color: #19ae54 !important;
  /* Optional: change background on hover */
  color: white !important;
  /* Optional: change text color on hover */
}
.MobileList .ant-collapse-item {
  background-color: #ecf4fa;
  /* Red background */
  border-radius: 5px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #02aee8 !important;
}
.custom-arrow {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #b6b6be;
  transition: transform 0.3s ease;
}
.panel-header {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  /* Adjust the height as needed */
}
.head {
  color: #2e373c !important;
  font-weight: 500;
}
.text {
  color: #2e373c !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  background-color: #f9fcfe;
}
.collapse-panel .ant-collapse-content > .ant-collapse-content-box {
  background-color: transparent;
}
.tournament-image {
  width: 32px !important;
  margin-right: 10px;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background-color: #f0faff !important;
}
.ant-table-tbody > tr > td:not(.ant-table-column-sort) {
  background-color: #f9fcfe !important;
  color: #999;
}
.ant-table-thead > tr > th {
  background-color: #ecf4fa !important;
  color: #2e373c;
}
.CompanyTableBox {
  border-radius: 8px;
  overflow: hidden;
  /* Ensures the border-radius applies properly */
}
.CompanyTableBox .ant-table {
  border-radius: 8px;
}
.CompanyTableBox .ant-table-thead > tr > th,
.CompanyTableBox .ant-table-tbody > tr > td {
  border-radius: 0;
  /* Prevents individual cells from having a border-radius */
}
.CompanyTableBox .ant-table-tbody tr td {
  border-bottom: 2px solid #b1c7dc !important;
  border-left: none !important;
  border-right: none !important;
}
.CompanyTableBox .ant-table-thead th {
  border-left: none !important;
  border-right: none !important;
}
.ant-table-column-sorter-up.active {
  color: #f4791f !important;
}
.ant-table-column-sorter-down.active {
  color: #f4791f !important;
}
.ant-table-cell span {
  color: #2e373c;
}
.ant-table-cell div {
  color: #2e373c;
}
.ant-table-cell {
  color: #2e373c !important;
}
.CompanyBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo {
  box-shadow: none !important;
  border: 1px solid #b1c7dc;
  border-radius: 8px;
  background-color: white;
  padding: 2px;
  width: 50px !important;
  height: 50px !important;
  margin-right: 15px !important;
}
.GameBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo {
  box-shadow: none !important;
  border: 1px solid #b1c7dc;
  border-radius: 8px;
  background-color: white;
  padding: 2px;
  width: 50px !important;
  height: 50px !important;
  margin-right: 15px !important;
}
.Tabel_Company_Title {
  margin-left: 0 !important;
}
.Tabel_Company_Title p a {
  overflow: hidden;
  /* Hides overflowing text */
  text-overflow: ellipsis;
  /* Adds ellipsis (...) for truncated text */
  display: -webkit-box;
  /* Creates a flexible box for limiting text */
  -webkit-line-clamp: 3;
  /* Limits the number of visible lines */
  -webkit-box-orient: vertical;
  /* Sets the orientation of the box to vertical */
  word-wrap: break-word;
  /* Breaks long words to prevent overflow */
  max-width: 350px;
  /* Sets a maximum width */
}
.mainheader {
  margin-left: 50px;
  margin-top: 100px;
  color: #2e373c !important;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
}
.mainheader2 {
  margin-left: 50px;
  margin-top: 100px;
  color: #2864a1 !important;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: -10px;
}
.note {
  font-size: 1.2rem;
  font-weight: 600;
}
@media (max-width: 768px) {
  .note {
    word-wrap: break-word;
    /* Allows breaking long words */
    overflow-wrap: break-word;
    /* Ensures compatibility */
    white-space: normal;
    /* Breaks lines instead of keeping them on a single line */
  }
}
@media (max-width: 768px) {
  .mainheader2 {
    margin-left: 20px;
    margin-top: 50px;
    font-size: 32px;
    margin-bottom: -10px;
  }
}
.mainheader3 {
  margin-top: 100px;
  color: #2864a1 !important;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: -10px;
}
.blogvIEW {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .blogvIEW {
    justify-content: unset !important;
    padding-left: 30px;
  }
}
.subHeader {
  margin-left: 50px;
  /* margin-top: 20px; */
  color: #70727b;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 50px;
}
/* Mobile styles */
@media (max-width: 768px) {
  .mainheader {
    margin-left: 20px;
    /* Adjust margin for smaller screens */
    font-size: 30px;
    /* Smaller font size for mobile */
  }
  .subHeader {
    margin-left: 20px;
    font-size: 14px;
    /* Smaller font size for mobile */
    margin-bottom: 10px;
  }
}
.ant-tooltip-inner {
  background-color: gray;
  /* Change background color */
  color: white;
  /* Change text color */
  font-size: 14px;
  /* Customize font size */
  border-radius: 8px;
  /* Make the corners rounded */
  min-height: auto;
}
.ant-tooltip-arrow {
  display: none;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  border-radius: 4px !important;
  background-color: #418dc8 !important;
}
.ant-menu-item,
.ant-menu-submenu-title {
  white-space: unset !important;
  line-height: 48px !important;
}
.ant-table-column-sorters {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  /* This ensures the sort arrows are right next to the column header text */
  gap: 0 !important;
}
.ant-table-column-sorter {
  margin-left: 8px !important;
  /* Adjust the space between the header text and the sort arrows if needed */
}
.ant-table-column-title {
  display: inline-flex !important;
  align-items: center !important;
  gap: 0 !important;
  max-width: fit-content !important;
}
.custom-publisher-column .ant-table-column-sorters {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* This ensures the sort arrows are right next to the column header text */
  gap: 0 !important;
}
.ant-table-column-sorter {
  pointer-events: none !important;
  /* Disable tooltip appearance */
}
.ant-input-affix-wrapper {
  border-color: #d9d9d9;
  box-shadow: none !important;
  height: 48px !important;
  margin-top: 0px !important;
  transition: border-color 0.3s ease;
  /* Add transition for border */
}
.ant-input-affix-wrapper-focused {
  border-color: #4ad1ff !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #4ad1ff !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper > .ant-input {
  height: 35px !important;
  border: none !important;
}
.ant-input-affix-wrapper .ant-input-prefix {
  color: #d9d9d9;
  transition: color 0.3s ease;
  /* Smooth transition for the icon color */
}
.ant-input-affix-wrapper-focused .ant-input-prefix,
.ant-input-affix-wrapper:hover .ant-input-prefix,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover .ant-input-prefix {
  color: #4ad1ff !important;
}
.filterBtnSelected {
  width: 108px !important;
  height: 42px !important;
  padding-top: 6px;
  color: #2864a1 !important;
  background-color: white !important;
  border: 3px solid #4ad1ff !important;
  border-radius: 20px !important;
  transition: background-color 0.3s ease;
  /* Smooth transition */
}
.filterresetBtn {
  color: #2864a1 !important;
  width: 108px !important;
  height: 42px !important;
  border-radius: 20px !important;
  border: 1px solid #88acce !important;
  padding-top: 6px;
  transition: background-color 0.3s ease;
  /* Smooth transition */
  background-color: white !important;
}
.filterresetBtn:hover {
  background-color: #ecf4fa !important;
  /* Darker shade for hover */
}
.ant-col-24.ant-form-item-label {
  padding: 0 !important;
}
.filter-modal .ant-modal-content {
  border: 1px solid #b6b6be;
  border-radius: 8px;
  box-shadow: 0px 4px 8px #27313b43;
  opacity: 1;
}
.filter-modal .ant-modal-body {
  padding-top: 45px !important;
}
.ant-modal-close-x {
  font-size: 18px !important;
  color: #1f1f1f !important;
}
.listSearch {
  display: flex;
  justify-content: space-between;
  margin-bottom: -30px;
}
.topBox {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}
@media (max-width: 768px) {
  .listSearch {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-bottom: -10px;
  }
}
@media (max-width: 768px) {
  .checkTitle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start !important;
  }
}
@media (max-width: 768px) {
  .topBox {
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}
.exclusiveBox {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  align-items: center;
  height: 60px;
}
@media (max-width: 768px) {
  .exclusiveBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    height: 60px;
  }
}
.ant-form-item-explain-connected {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ant-form-item-explain-error {
  font-size: 0.8rem;
}
.IcafeBox {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding: 0 3vw !important;
  height: 60px !important;
  margin-bottom: -20px !important;
  margin-top: 10px !important;
}
.password {
  height: 60px !important;
  border: 1px solid #b6b6be !important;
  border-radius: 8px !important;
}
.LoginBox .ant-input-affix-wrapper > input.ant-input {
  border: none !important;
  margin-top: 0px !important;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 40px !important;
}
.customInputBlog {
  height: 45px !important;
  border: 1px solid #b6b6be !important;
  border-radius: 8px !important;
  width: 100% !important;
}
.anticon-search {
  font-size: 20px !important;
}
.anticon svg {
  width: 18px !important;
  height: 18px !important;
}
.layout_container .layout_main .ant-layout-sider-trigger:hover {
  /* Add your hover styles here */
  background-color: #0297c9;
  /* Example: change background color */
  cursor: pointer;
  /* Change cursor to pointer */
  transition: background-color 0.3s ease;
  /* Smooth transition */
}
.layout_container .layout_main .ant-layout-sider-trigger {
  width: 100% !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: black !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
.greyTag {
  max-width: 150px !important;
  margin: 3px !important;
  margin-left: 0px !important;
  padding: 2px 8px 3px 8px !important;
  overflow: hidden;
  /* Hides the overflowed text */
  color: #70727b !important;
  white-space: nowrap;
  /* Prevents the text from wrapping to the next line */
  text-overflow: ellipsis;
  /* Adds "..." to indicate overflow */
  background-color: #efefef !important;
  border: none !important;
  border-radius: 6px !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  /* Smooth transition */
}
.greyTag:hover {
  color: #efefef !important;
  /* Optional: change text color on hover */
  background-color: #70727b !important;
  /* Optional: change background on hover */
}
.radio-new-line {
  display: block;
  /* This makes the div block-level, putting it on a new line */
}
@media (max-width: 373px) {
  .inputBox {
    width: 60%;
  }
}
@media (max-width: 355px) {
  .inputBoxvana input {
    width: 100%;
  }
  .inputBoxvana {
    width: 65%;
  }
}
.wide {
  color: #2864a1 !important;
  width: 140px !important;
  height: 42px !important;
  border-radius: 20px !important;
  border: 1px solid #88acce !important;
  padding-top: 6px;
  transition: background-color 0.3s ease;
  /* Smooth transition */
  background-color: white !important;
}
.wide:hover {
  background-color: #ecf4fa !important;
  /* Darker shade for hover */
}
.insight-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  appearance: none;
  /* Hides the default checkbox */
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: white;
  /* Default background color */
  transition: background-color 0.3s ease, border-color 0.3s ease;
  position: relative;
  /* Needed for the pseudo-element */
}
.insight-checkbox:checked {
  background-color: #02aee8;
  border-color: #02aee8;
}
.insight-checkbox:checked::before {
  content: "✔";
  /* Unicode character for checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  /* Adjust size as needed */
  color: white;
  /* Checkmark color */
  font-weight: bold;
}
.TeamsBox,
.insightsFeedForEsportsBox,
.CompanyDetail_Box,
.GameBox,
.CompanyBox,
.BlogDetailBox,
.BlogBox,
.FreeReportsBox {
  width: 95%;
  margin: 0 auto;
  color: #333333;
}
.TeamsBox .a_style,
.insightsFeedForEsportsBox .a_style,
.CompanyDetail_Box .a_style,
.GameBox .a_style,
.CompanyBox .a_style,
.BlogDetailBox .a_style,
.BlogBox .a_style,
.FreeReportsBox .a_style {
  text-decoration: none;
  color: #02aee8;
  font-weight: 500;
}
.TeamsBox .a_style:hover,
.insightsFeedForEsportsBox .a_style:hover,
.CompanyDetail_Box .a_style:hover,
.GameBox .a_style:hover,
.CompanyBox .a_style:hover,
.BlogDetailBox .a_style:hover,
.BlogBox .a_style:hover,
.FreeReportsBox .a_style:hover {
  text-decoration: underline;
  color: #02aee8;
  font-weight: 500;
}
.TeamsBox ul.ProlistBox > li:hover,
.insightsFeedForEsportsBox ul.ProlistBox > li:hover,
.CompanyDetail_Box ul.ProlistBox > li:hover,
.GameBox ul.ProlistBox > li:hover,
.CompanyBox ul.ProlistBox > li:hover,
.BlogDetailBox ul.ProlistBox > li:hover,
.BlogBox ul.ProlistBox > li:hover,
.FreeReportsBox ul.ProlistBox > li:hover {
  background-color: #f4fbff;
}
.TeamsBox ul.ProlistBox > li,
.insightsFeedForEsportsBox ul.ProlistBox > li,
.CompanyDetail_Box ul.ProlistBox > li,
.GameBox ul.ProlistBox > li,
.CompanyBox ul.ProlistBox > li,
.BlogDetailBox ul.ProlistBox > li,
.BlogBox ul.ProlistBox > li,
.FreeReportsBox ul.ProlistBox > li {
  border-top: 1px solid rgba(40, 57, 94, 0.2);
  border-bottom: 1px solid rgba(40, 57, 94, 0.2);
  min-height: 160px;
  padding: 25px 0px;
  padding-bottom: 5px;
}
.TeamsBox ul.ProlistBox > li .insght_pic,
.insightsFeedForEsportsBox ul.ProlistBox > li .insght_pic,
.CompanyDetail_Box ul.ProlistBox > li .insght_pic,
.GameBox ul.ProlistBox > li .insght_pic,
.CompanyBox ul.ProlistBox > li .insght_pic,
.BlogDetailBox ul.ProlistBox > li .insght_pic,
.BlogBox ul.ProlistBox > li .insght_pic,
.FreeReportsBox ul.ProlistBox > li .insght_pic {
  margin-right: 28px;
  cursor: pointer;
  overflow: hidden;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 10px;
  background-color: #fdfdfd;
  border: 1px solid #eee;
  object-fit: contain;
}
.TeamsBox ul.ProlistBox > li > span,
.insightsFeedForEsportsBox ul.ProlistBox > li > span,
.CompanyDetail_Box ul.ProlistBox > li > span,
.GameBox ul.ProlistBox > li > span,
.CompanyBox ul.ProlistBox > li > span,
.BlogDetailBox ul.ProlistBox > li > span,
.BlogBox ul.ProlistBox > li > span,
.FreeReportsBox ul.ProlistBox > li > span {
  font-size: 20px;
  font-weight: bold;
}
.TeamsBox ul.ProlistBox > li .insight_list_cont,
.insightsFeedForEsportsBox ul.ProlistBox > li .insight_list_cont,
.CompanyDetail_Box ul.ProlistBox > li .insight_list_cont,
.GameBox ul.ProlistBox > li .insight_list_cont,
.CompanyBox ul.ProlistBox > li .insight_list_cont,
.BlogDetailBox ul.ProlistBox > li .insight_list_cont,
.BlogBox ul.ProlistBox > li .insight_list_cont,
.FreeReportsBox ul.ProlistBox > li .insight_list_cont {
  min-height: 120px;
  display: block;
}
.TeamsBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box,
.insightsFeedForEsportsBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box,
.CompanyDetail_Box ul.ProlistBox > li .insight_list_cont .Tag_List_Box,
.GameBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box,
.CompanyBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box,
.BlogDetailBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box,
.BlogBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box,
.FreeReportsBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box {
  padding: 5px 0;
}
.TeamsBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag,
.insightsFeedForEsportsBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag,
.CompanyDetail_Box ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag,
.GameBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag,
.CompanyBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag,
.BlogDetailBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag,
.BlogBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag,
.FreeReportsBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag {
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.TeamsBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag:first-child,
.insightsFeedForEsportsBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag:first-child,
.CompanyDetail_Box ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag:first-child,
.GameBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag:first-child,
.CompanyBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag:first-child,
.BlogDetailBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag:first-child,
.BlogBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag:first-child,
.FreeReportsBox ul.ProlistBox > li .insight_list_cont .Tag_List_Box .ant-tag:first-child {
  margin-left: 0;
}
.TeamsBox ul.ProlistBox > li .insight_list_cont h3,
.insightsFeedForEsportsBox ul.ProlistBox > li .insight_list_cont h3,
.CompanyDetail_Box ul.ProlistBox > li .insight_list_cont h3,
.GameBox ul.ProlistBox > li .insight_list_cont h3,
.CompanyBox ul.ProlistBox > li .insight_list_cont h3,
.BlogDetailBox ul.ProlistBox > li .insight_list_cont h3,
.BlogBox ul.ProlistBox > li .insight_list_cont h3,
.FreeReportsBox ul.ProlistBox > li .insight_list_cont h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TeamsBox ul.ProlistBox > li .insight_list_cont h3 div,
.insightsFeedForEsportsBox ul.ProlistBox > li .insight_list_cont h3 div,
.CompanyDetail_Box ul.ProlistBox > li .insight_list_cont h3 div,
.GameBox ul.ProlistBox > li .insight_list_cont h3 div,
.CompanyBox ul.ProlistBox > li .insight_list_cont h3 div,
.BlogDetailBox ul.ProlistBox > li .insight_list_cont h3 div,
.BlogBox ul.ProlistBox > li .insight_list_cont h3 div,
.FreeReportsBox ul.ProlistBox > li .insight_list_cont h3 div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
}
.TeamsBox ul.ProlistBox > li .insight_list_cont h3 div span,
.insightsFeedForEsportsBox ul.ProlistBox > li .insight_list_cont h3 div span,
.CompanyDetail_Box ul.ProlistBox > li .insight_list_cont h3 div span,
.GameBox ul.ProlistBox > li .insight_list_cont h3 div span,
.CompanyBox ul.ProlistBox > li .insight_list_cont h3 div span,
.BlogDetailBox ul.ProlistBox > li .insight_list_cont h3 div span,
.BlogBox ul.ProlistBox > li .insight_list_cont h3 div span,
.FreeReportsBox ul.ProlistBox > li .insight_list_cont h3 div span {
  font-weight: 300;
  margin-right: 10px;
  cursor: pointer;
  color: #70727b;
}
.TeamsBox ul.ProlistBox > li .insight_list_cont h3 div .insight_list_title,
.insightsFeedForEsportsBox ul.ProlistBox > li .insight_list_cont h3 div .insight_list_title,
.CompanyDetail_Box ul.ProlistBox > li .insight_list_cont h3 div .insight_list_title,
.GameBox ul.ProlistBox > li .insight_list_cont h3 div .insight_list_title,
.CompanyBox ul.ProlistBox > li .insight_list_cont h3 div .insight_list_title,
.BlogDetailBox ul.ProlistBox > li .insight_list_cont h3 div .insight_list_title,
.BlogBox ul.ProlistBox > li .insight_list_cont h3 div .insight_list_title,
.FreeReportsBox ul.ProlistBox > li .insight_list_cont h3 div .insight_list_title {
  cursor: pointer;
}
.TeamsBox ul.ProlistBox > li .insight_list_cont h3 .insight_list_date_style,
.insightsFeedForEsportsBox ul.ProlistBox > li .insight_list_cont h3 .insight_list_date_style,
.CompanyDetail_Box ul.ProlistBox > li .insight_list_cont h3 .insight_list_date_style,
.GameBox ul.ProlistBox > li .insight_list_cont h3 .insight_list_date_style,
.CompanyBox ul.ProlistBox > li .insight_list_cont h3 .insight_list_date_style,
.BlogDetailBox ul.ProlistBox > li .insight_list_cont h3 .insight_list_date_style,
.BlogBox ul.ProlistBox > li .insight_list_cont h3 .insight_list_date_style,
.FreeReportsBox ul.ProlistBox > li .insight_list_cont h3 .insight_list_date_style {
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
  color: #999999;
}
.TeamsBox ul.ProlistBox > li .insght_lst_info,
.insightsFeedForEsportsBox ul.ProlistBox > li .insght_lst_info,
.CompanyDetail_Box ul.ProlistBox > li .insght_lst_info,
.GameBox ul.ProlistBox > li .insght_lst_info,
.CompanyBox ul.ProlistBox > li .insght_lst_info,
.BlogDetailBox ul.ProlistBox > li .insght_lst_info,
.BlogBox ul.ProlistBox > li .insght_lst_info,
.FreeReportsBox ul.ProlistBox > li .insght_lst_info {
  font-size: 16px;
  table-layout: fixed;
  word-wrap: break-word;
  word-break: normal;
  cursor: pointer;
  margin-bottom: 20px;
}
.TeamsBox ul.ProlistBox:last-child,
.insightsFeedForEsportsBox ul.ProlistBox:last-child,
.CompanyDetail_Box ul.ProlistBox:last-child,
.GameBox ul.ProlistBox:last-child,
.CompanyBox ul.ProlistBox:last-child,
.BlogDetailBox ul.ProlistBox:last-child,
.BlogBox ul.ProlistBox:last-child,
.FreeReportsBox ul.ProlistBox:last-child {
  border-bottom: 1px solid rgba(40, 57, 94, 0.2);
}
.BlogDetailLabel {
  background-size: 100% auto !important;
  font-weight: lighter;
  background: url(../../../assets/images/backgroupimg.png) top center no-repeat #fff;
  min-height: 100vh;
  padding-top: 30px;
}
.BlogDetailLabel img {
  max-width: 1200px;
  object-fit: contain;
}
.BlogDetailLabel .BlogDetailBox {
  margin: 0 auto;
}
.BlogDetailLabel .BlogDetailBox .Global_title {
  margin-bottom: 1rem !important;
  background: none;
  font-size: 24px;
  color: #28395e;
  font-weight: bold;
}
.BlogDetailLabel .BlogDetailBox .Article_Info {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-between;
}
.BlogDetailLabel .BlogDetailBox .Article_Info .Article_Info_Lf {
  display: flex;
  align-items: center;
}
.BlogDetailLabel .BlogDetailBox .Article_Info .Article_Info_Lf > span {
  color: #999999;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
}
.BlogDetailLabel .BlogDetailBox .Article_Info .Article_Info_Lf > span:hover {
  text-decoration: underline;
}
.BlogDetailLabel .BlogDetailBox .Article_Info .Article_Info_Rg > span {
  color: #999999;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
}
.BlogDetailLabel .BlogDetailBox .Article_Info .Article_Info_Rg > span:hover {
  text-decoration: underline;
}
.BlogDetailLabel .BlogDetailBox .report_Box {
  color: #999999;
  font-size: 16px;
  margin-right: 10px;
}
.ant-row > .ant-col:nth-last-child(2) .Statistic_Card_Sub_a {
  border-right: 1px solid #e9e9e9;
}
.SearchList_Box .Statistic_List,
.user_Info_Box .Statistic_List,
.TeamsBox .Statistic_List,
.insightsFeedForEsportsBox .Statistic_List,
.GameBox .Statistic_List,
.CompanyBox .Statistic_List,
.SearchList_Box .Statistic_Box,
.user_Info_Box .Statistic_Box,
.TeamsBox .Statistic_Box,
.insightsFeedForEsportsBox .Statistic_Box,
.GameBox .Statistic_Box,
.CompanyBox .Statistic_Box {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SearchList_Box .Statistic_List .Organizations_Statistic_Card img,
.user_Info_Box .Statistic_List .Organizations_Statistic_Card img,
.TeamsBox .Statistic_List .Organizations_Statistic_Card img,
.insightsFeedForEsportsBox .Statistic_List .Organizations_Statistic_Card img,
.GameBox .Statistic_List .Organizations_Statistic_Card img,
.CompanyBox .Statistic_List .Organizations_Statistic_Card img,
.SearchList_Box .Statistic_Box .Organizations_Statistic_Card img,
.user_Info_Box .Statistic_Box .Organizations_Statistic_Card img,
.TeamsBox .Statistic_Box .Organizations_Statistic_Card img,
.insightsFeedForEsportsBox .Statistic_Box .Organizations_Statistic_Card img,
.GameBox .Statistic_Box .Organizations_Statistic_Card img,
.CompanyBox .Statistic_Box .Organizations_Statistic_Card img {
  width: 112.24px;
}
.SearchList_Box .Statistic_List .Statistic_Card,
.user_Info_Box .Statistic_List .Statistic_Card,
.TeamsBox .Statistic_List .Statistic_Card,
.insightsFeedForEsportsBox .Statistic_List .Statistic_Card,
.GameBox .Statistic_List .Statistic_Card,
.CompanyBox .Statistic_List .Statistic_Card,
.SearchList_Box .Statistic_Box .Statistic_Card,
.user_Info_Box .Statistic_Box .Statistic_Card,
.TeamsBox .Statistic_Box .Statistic_Card,
.insightsFeedForEsportsBox .Statistic_Box .Statistic_Card,
.GameBox .Statistic_Box .Statistic_Card,
.CompanyBox .Statistic_Box .Statistic_Card {
  width: 68px;
}
.SearchList_Box .Statistic_List .Statistic_Card img,
.user_Info_Box .Statistic_List .Statistic_Card img,
.TeamsBox .Statistic_List .Statistic_Card img,
.insightsFeedForEsportsBox .Statistic_List .Statistic_Card img,
.GameBox .Statistic_List .Statistic_Card img,
.CompanyBox .Statistic_List .Statistic_Card img,
.SearchList_Box .Statistic_Box .Statistic_Card img,
.user_Info_Box .Statistic_Box .Statistic_Card img,
.TeamsBox .Statistic_Box .Statistic_Card img,
.insightsFeedForEsportsBox .Statistic_Box .Statistic_Card img,
.GameBox .Statistic_Box .Statistic_Card img,
.CompanyBox .Statistic_Box .Statistic_Card img {
  width: 68px;
  height: 68px;
  object-fit: contain;
}
.SearchList_Box .Statistic_List .Statistic_Card_Sub_a,
.user_Info_Box .Statistic_List .Statistic_Card_Sub_a,
.TeamsBox .Statistic_List .Statistic_Card_Sub_a,
.insightsFeedForEsportsBox .Statistic_List .Statistic_Card_Sub_a,
.GameBox .Statistic_List .Statistic_Card_Sub_a,
.CompanyBox .Statistic_List .Statistic_Card_Sub_a,
.SearchList_Box .Statistic_Box .Statistic_Card_Sub_a,
.user_Info_Box .Statistic_Box .Statistic_Card_Sub_a,
.TeamsBox .Statistic_Box .Statistic_Card_Sub_a,
.insightsFeedForEsportsBox .Statistic_Box .Statistic_Card_Sub_a,
.GameBox .Statistic_Box .Statistic_Card_Sub_a,
.CompanyBox .Statistic_Box .Statistic_Card_Sub_a {
  padding-left: 55px;
  padding-right: 70px;
}
.SearchList_Box .Statistic_List .Statistic_Card_Sub_a p,
.user_Info_Box .Statistic_List .Statistic_Card_Sub_a p,
.TeamsBox .Statistic_List .Statistic_Card_Sub_a p,
.insightsFeedForEsportsBox .Statistic_List .Statistic_Card_Sub_a p,
.GameBox .Statistic_List .Statistic_Card_Sub_a p,
.CompanyBox .Statistic_List .Statistic_Card_Sub_a p,
.SearchList_Box .Statistic_Box .Statistic_Card_Sub_a p,
.user_Info_Box .Statistic_Box .Statistic_Card_Sub_a p,
.TeamsBox .Statistic_Box .Statistic_Card_Sub_a p,
.insightsFeedForEsportsBox .Statistic_Box .Statistic_Card_Sub_a p,
.GameBox .Statistic_Box .Statistic_Card_Sub_a p,
.CompanyBox .Statistic_Box .Statistic_Card_Sub_a p {
  margin-bottom: 0;
  font-size: 20px;
  color: #999;
}
.SearchList_Box .Statistic_List .Statistic_Card_Sub_a h2,
.user_Info_Box .Statistic_List .Statistic_Card_Sub_a h2,
.TeamsBox .Statistic_List .Statistic_Card_Sub_a h2,
.insightsFeedForEsportsBox .Statistic_List .Statistic_Card_Sub_a h2,
.GameBox .Statistic_List .Statistic_Card_Sub_a h2,
.CompanyBox .Statistic_List .Statistic_Card_Sub_a h2,
.SearchList_Box .Statistic_Box .Statistic_Card_Sub_a h2,
.user_Info_Box .Statistic_Box .Statistic_Card_Sub_a h2,
.TeamsBox .Statistic_Box .Statistic_Card_Sub_a h2,
.insightsFeedForEsportsBox .Statistic_Box .Statistic_Card_Sub_a h2,
.GameBox .Statistic_Box .Statistic_Card_Sub_a h2,
.CompanyBox .Statistic_Box .Statistic_Card_Sub_a h2 {
  font-size: 36px;
  font-weight: bold;
  color: #28395e;
  margin-bottom: 0;
}
.SearchList_Box .Statistic_List .Statistic_Card,
.user_Info_Box .Statistic_List .Statistic_Card,
.TeamsBox .Statistic_List .Statistic_Card,
.insightsFeedForEsportsBox .Statistic_List .Statistic_Card,
.GameBox .Statistic_List .Statistic_Card,
.CompanyBox .Statistic_List .Statistic_Card,
.SearchList_Box .Statistic_Box .Statistic_Card,
.user_Info_Box .Statistic_Box .Statistic_Card,
.TeamsBox .Statistic_Box .Statistic_Card,
.insightsFeedForEsportsBox .Statistic_Box .Statistic_Card,
.GameBox .Statistic_Box .Statistic_Card,
.CompanyBox .Statistic_Box .Statistic_Card {
  height: calc(100% - 1px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100%);
}
.SearchList_Box .Statistic_List .Statistic_Card .Statistic_Card_Sub,
.user_Info_Box .Statistic_List .Statistic_Card .Statistic_Card_Sub,
.TeamsBox .Statistic_List .Statistic_Card .Statistic_Card_Sub,
.insightsFeedForEsportsBox .Statistic_List .Statistic_Card .Statistic_Card_Sub,
.GameBox .Statistic_List .Statistic_Card .Statistic_Card_Sub,
.CompanyBox .Statistic_List .Statistic_Card .Statistic_Card_Sub,
.SearchList_Box .Statistic_Box .Statistic_Card .Statistic_Card_Sub,
.user_Info_Box .Statistic_Box .Statistic_Card .Statistic_Card_Sub,
.TeamsBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub,
.insightsFeedForEsportsBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub,
.GameBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub,
.CompanyBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub {
  padding-left: 55px;
  padding-right: 70px;
}
.SearchList_Box .Statistic_List .Statistic_Card .Statistic_Card_Sub p,
.user_Info_Box .Statistic_List .Statistic_Card .Statistic_Card_Sub p,
.TeamsBox .Statistic_List .Statistic_Card .Statistic_Card_Sub p,
.insightsFeedForEsportsBox .Statistic_List .Statistic_Card .Statistic_Card_Sub p,
.GameBox .Statistic_List .Statistic_Card .Statistic_Card_Sub p,
.CompanyBox .Statistic_List .Statistic_Card .Statistic_Card_Sub p,
.SearchList_Box .Statistic_Box .Statistic_Card .Statistic_Card_Sub p,
.user_Info_Box .Statistic_Box .Statistic_Card .Statistic_Card_Sub p,
.TeamsBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub p,
.insightsFeedForEsportsBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub p,
.GameBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub p,
.CompanyBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub p {
  margin-bottom: 0;
  font-size: 20px;
  color: #999;
}
.SearchList_Box .Statistic_List .Statistic_Card .Statistic_Card_Sub h2,
.user_Info_Box .Statistic_List .Statistic_Card .Statistic_Card_Sub h2,
.TeamsBox .Statistic_List .Statistic_Card .Statistic_Card_Sub h2,
.insightsFeedForEsportsBox .Statistic_List .Statistic_Card .Statistic_Card_Sub h2,
.GameBox .Statistic_List .Statistic_Card .Statistic_Card_Sub h2,
.CompanyBox .Statistic_List .Statistic_Card .Statistic_Card_Sub h2,
.SearchList_Box .Statistic_Box .Statistic_Card .Statistic_Card_Sub h2,
.user_Info_Box .Statistic_Box .Statistic_Card .Statistic_Card_Sub h2,
.TeamsBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub h2,
.insightsFeedForEsportsBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub h2,
.GameBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub h2,
.CompanyBox .Statistic_Box .Statistic_Card .Statistic_Card_Sub h2 {
  font-size: 36px;
  font-weight: bold;
  color: #28395e;
  margin-bottom: 0;
}
.SearchList_Box .CompanyChartBox,
.user_Info_Box .CompanyChartBox,
.TeamsBox .CompanyChartBox,
.insightsFeedForEsportsBox .CompanyChartBox,
.GameBox .CompanyChartBox,
.CompanyBox .CompanyChartBox {
  font-size: 16px;
}
.SearchList_Box .ant-form-item,
.user_Info_Box .ant-form-item,
.TeamsBox .ant-form-item,
.insightsFeedForEsportsBox .ant-form-item,
.GameBox .ant-form-item,
.CompanyBox .ant-form-item {
  margin-bottom: 0;
}
.SearchList_Box .CompanyTableBox .ant-table,
.user_Info_Box .CompanyTableBox .ant-table,
.TeamsBox .CompanyTableBox .ant-table,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table,
.GameBox .CompanyTableBox .ant-table,
.CompanyBox .CompanyTableBox .ant-table {
  font-size: 16px;
  border: 1px solid rgba(40, 57, 94, 0.2);
  border-radius: 8px;
  overflow: hidden;
}
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Related_Games,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Related_Games,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Related_Games,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Related_Games,
.GameBox .CompanyTableBox .ant-table .Tabel_Related_Games,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Related_Games,
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Company,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Company,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Company,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Company,
.GameBox .CompanyTableBox .ant-table .Tabel_Company,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Company {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo,
.GameBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo,
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo,
.GameBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo {
  width: 43px;
  height: 43px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.33);
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
}
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo img,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo img,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo img,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo img,
.GameBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo img,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Logo img,
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo img,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo img,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo img,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo img,
.GameBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo img,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Title,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Title,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Title,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Title,
.GameBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Title,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Title,
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Company .Tabel_Related_Games_Title,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Company .Tabel_Related_Games_Title,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Related_Games_Title,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Related_Games_Title,
.GameBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Related_Games_Title,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Related_Games_Title,
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Title,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Title,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Title,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Title,
.GameBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Title,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Company_Title,
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Title,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Title,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Title,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Title,
.GameBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Title,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Title {
  margin-left: 15px;
}
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Related_Games p,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Related_Games p,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Related_Games p,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Related_Games p,
.GameBox .CompanyTableBox .ant-table .Tabel_Related_Games p,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Related_Games p,
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Company p,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Company p,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Company p,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Company p,
.GameBox .CompanyTableBox .ant-table .Tabel_Company p,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Company p {
  margin-bottom: 0;
}
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Related_Games,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Related_Games,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Related_Games,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Related_Games,
.GameBox .CompanyTableBox .ant-table .Tabel_Related_Games,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Related_Games {
  margin-bottom: 10px;
}
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Logo img,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Logo img,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Logo img,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Logo img,
.GameBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Logo img,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Related_Games .Tabel_Related_Games_Logo img {
  width: 64px;
  height: 64px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgba(233, 233, 233, 0.5);
  border-radius: 5px;
}
.SearchList_Box .CompanyTableBox .ant-table .Tabel_Information,
.user_Info_Box .CompanyTableBox .ant-table .Tabel_Information,
.TeamsBox .CompanyTableBox .ant-table .Tabel_Information,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table .Tabel_Information,
.GameBox .CompanyTableBox .ant-table .Tabel_Information,
.CompanyBox .CompanyTableBox .ant-table .Tabel_Information {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-right: 50px;
}
.SearchList_Box .CompanyTableBox .ant-table-thead,
.user_Info_Box .CompanyTableBox .ant-table-thead,
.TeamsBox .CompanyTableBox .ant-table-thead,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table-thead,
.GameBox .CompanyTableBox .ant-table-thead,
.CompanyBox .CompanyTableBox .ant-table-thead {
  background-image: linear-gradient(to right, #eff4f8, #fff);
  line-height: 32px;
  height: 32px;
}
.SearchList_Box .CompanyTableBox .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(
				.ant-table-row-expand-icon-cell
			):not([colspan])::before,
.user_Info_Box .CompanyTableBox .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(
				.ant-table-row-expand-icon-cell
			):not([colspan])::before,
.TeamsBox .CompanyTableBox .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(
				.ant-table-row-expand-icon-cell
			):not([colspan])::before,
.insightsFeedForEsportsBox .CompanyTableBox .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(
				.ant-table-row-expand-icon-cell
			):not([colspan])::before,
.GameBox .CompanyTableBox .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(
				.ant-table-row-expand-icon-cell
			):not([colspan])::before,
.CompanyBox .CompanyTableBox .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(
				.ant-table-row-expand-icon-cell
			):not([colspan])::before {
  width: 0;
}
.SearchList_Box .Statistic_List,
.user_Info_Box .Statistic_List,
.TeamsBox .Statistic_List,
.insightsFeedForEsportsBox .Statistic_List,
.GameBox .Statistic_List,
.CompanyBox .Statistic_List {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.SearchList_Box .Statistic_List .Team_Area_List,
.user_Info_Box .Statistic_List .Team_Area_List,
.TeamsBox .Statistic_List .Team_Area_List,
.insightsFeedForEsportsBox .Statistic_List .Team_Area_List,
.GameBox .Statistic_List .Team_Area_List,
.CompanyBox .Statistic_List .Team_Area_List {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}
.SearchList_Box .Statistic_List .Team_Area_List div,
.user_Info_Box .Statistic_List .Team_Area_List div,
.TeamsBox .Statistic_List .Team_Area_List div,
.insightsFeedForEsportsBox .Statistic_List .Team_Area_List div,
.GameBox .Statistic_List .Team_Area_List div,
.CompanyBox .Statistic_List .Team_Area_List div {
  font-size: 20px;
  color: #999;
  font-weight: lighter;
}
@media (max-width: 768px) {
  .gameCompDet {
    display: flex;
    flex-direction: column;
  }
}
.TransactionsTableBox .ant-table {
  font-size: 16px;
  overflow: hidden;
}
.TransactionsTableBox .ant-table-thead {
  line-height: 32px;
  height: 32px;
}
.TransactionsTableBox .ant-table-thead > tr > th {
  background: none;
  color: #999;
}
.TransactionsTableBox .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
  width: 0;
}
.home_Card_Row {
  display: flex;
  justify-content: space-between;
}
.home_Card_Row .home_Card_Box {
  border-radius: 8px;
  border: none;
  width: calc(25% - 10px);
}
.home_Card_Row .home_Card_Box .statistic_Card {
  padding-left: 50px;
}
.home_Card_Row .home_Card_Box .statistic_title {
  color: rgba(0, 0, 0, 0.45);
  font-size: 22px;
}
.home_Card_Row .home_Card_Box .statistic_value {
  color: #28395e;
  font-size: 30px;
  font-weight: 600;
}
.home_Card_Row .home_Card_Box > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border-radius: 2px;
  overflow: hidden;
}
.home_Card_Row .home_Card_Box > div div:nth-child(2) {
  text-align: center;
}
.home_Card_Row > div:nth-child(1) {
  background-color: rgba(255, 152, 152, 0.1);
}
.home_Card_Row > div:nth-child(2) {
  background-color: rgba(109, 65, 221, 0.1);
}
.home_Card_Row > div:nth-child(3) {
  background-color: rgba(234, 148, 25, 0.1);
}
.home_Card_Row > div:nth-child(4) {
  background-color: rgba(128, 160, 231, 0.1);
}
.SearchList_Box .Detail_Title_Box,
.GameDetail_Box .Detail_Title_Box,
.CompanyDetail_Box .Detail_Title_Box {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
}
.SearchList_Box .Detail_Title_Box .companyDtIco,
.GameDetail_Box .Detail_Title_Box .companyDtIco,
.CompanyDetail_Box .Detail_Title_Box .companyDtIco {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  line-height: 120px;
  border: 2px solid #b1c7dc;
}
.SearchList_Box .Detail_Title_Box .companyDtIco img,
.GameDetail_Box .Detail_Title_Box .companyDtIco img,
.CompanyDetail_Box .Detail_Title_Box .companyDtIco img {
  height: 100%;
  width: 100%;
  line-height: 100%;
}
.SearchList_Box .Detail_Title_Box .companyDttitle,
.GameDetail_Box .Detail_Title_Box .companyDttitle,
.CompanyDetail_Box .Detail_Title_Box .companyDttitle {
  width: 65%;
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: space-around;
}
.SearchList_Box .Detail_Title_Box .companyDttitle h1,
.GameDetail_Box .Detail_Title_Box .companyDttitle h1,
.CompanyDetail_Box .Detail_Title_Box .companyDttitle h1 {
  font-size: 36px;
  color: #2e373c;
}
.SearchList_Box .Detail_Title_Box .companyDttitle p a,
.GameDetail_Box .Detail_Title_Box .companyDttitle p a,
.CompanyDetail_Box .Detail_Title_Box .companyDttitle p a {
  text-decoration: underline;
}
.SearchList_Box .Detail_Title_Box .companyDttitle .website-link,
.GameDetail_Box .Detail_Title_Box .companyDttitle .website-link,
.CompanyDetail_Box .Detail_Title_Box .companyDttitle .website-link {
  display: flex;
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 1rem;
  color: #02aee8;
  font-weight: normal;
  text-decoration: none;
}
.SearchList_Box .Detail_Title_Box .companyDttitle .website-link img,
.GameDetail_Box .Detail_Title_Box .companyDttitle .website-link img,
.CompanyDetail_Box .Detail_Title_Box .companyDttitle .website-link img {
  margin-right: 5px;
}
.SearchList_Box .Detail_Title_Box .companyDttitle .website-link:hover,
.GameDetail_Box .Detail_Title_Box .companyDttitle .website-link:hover,
.CompanyDetail_Box .Detail_Title_Box .companyDttitle .website-link:hover {
  text-decoration: underline;
}
.SearchList_Box .Atom_layout,
.GameDetail_Box .Atom_layout,
.CompanyDetail_Box .Atom_layout {
  padding: 25px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.SearchList_Box .Atom_layout .Atom_Box p,
.GameDetail_Box .Atom_layout .Atom_Box p,
.CompanyDetail_Box .Atom_layout .Atom_Box p {
  font-size: 16px;
  color: #999;
  margin-bottom: 0.5em;
}
.SearchList_Box .Atom_layout .Atom_Box p.Fsb,
.GameDetail_Box .Atom_layout .Atom_Box p.Fsb,
.CompanyDetail_Box .Atom_layout .Atom_Box p.Fsb {
  font-size: 24px;
  color: #333;
  font-weight: bold;
}
.SearchList_Box .RowBox .Global_title,
.GameDetail_Box .RowBox .Global_title,
.CompanyDetail_Box .RowBox .Global_title {
  line-height: 1;
  padding: 24px 0px;
}
.SearchList_Box .RowBox .Global_title h2,
.GameDetail_Box .RowBox .Global_title h2,
.CompanyDetail_Box .RowBox .Global_title h2 {
  margin-bottom: 0;
  color: #2864a1;
  font-weight: bold;
  font-size: 2.2rem;
}
@media (max-width: 768px) {
  .SearchList_Box .RowBox .Global_title h2,
  .GameDetail_Box .RowBox .Global_title h2,
  .CompanyDetail_Box .RowBox .Global_title h2 {
    font-size: 1.2rem;
  }
}
.SearchList_Box .RowBox .Global_title .right_more,
.GameDetail_Box .RowBox .Global_title .right_more,
.CompanyDetail_Box .RowBox .Global_title .right_more {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 0px;
  color: #f47b20;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
}
.SearchList_Box .RowBox .data_list,
.GameDetail_Box .RowBox .data_list,
.CompanyDetail_Box .RowBox .data_list {
  padding-bottom: 24px;
  border-radius: 10.67px;
}
.SearchList_Box .RowBox .data_list .data_item,
.GameDetail_Box .RowBox .data_list .data_item,
.CompanyDetail_Box .RowBox .data_list .data_item {
  margin-bottom: 40px;
  padding-left: 56px;
  padding-right: 40px;
  padding-top: 30px;
  border-top: 1px solid rgba(40, 57, 94, 0.2);
}
.SearchList_Box .RowBox .data_list .data_item .head,
.GameDetail_Box .RowBox .data_list .data_item .head,
.CompanyDetail_Box .RowBox .data_list .data_item .head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.SearchList_Box .RowBox .data_list .data_item .head .title,
.GameDetail_Box .RowBox .data_list .data_item .head .title,
.CompanyDetail_Box .RowBox .data_list .data_item .head .title {
  font-size: 20px;
  font-weight: 700;
  color: #333333;
}
.SearchList_Box .RowBox .data_list .data_item .head .time,
.GameDetail_Box .RowBox .data_list .data_item .head .time,
.CompanyDetail_Box .RowBox .data_list .data_item .head .time {
  font-size: 18px;
  font-weight: 400;
  color: #999999;
}
.SearchList_Box .RowBox .data_list .data_item .mid,
.GameDetail_Box .RowBox .data_list .data_item .mid,
.CompanyDetail_Box .RowBox .data_list .data_item .mid {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 21px;
  color: #333333;
}
.SearchList_Box .RowBox .data_list .data_item .bottom,
.GameDetail_Box .RowBox .data_list .data_item .bottom,
.CompanyDetail_Box .RowBox .data_list .data_item .bottom {
  display: flex;
  justify-content: space-between;
  width: 480px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 0px;
  color: #003778;
  text-align: left;
  vertical-align: top;
}
.SearchList_Box .RowBox .data_list .data_item .bottom .action,
.GameDetail_Box .RowBox .data_list .data_item .bottom .action,
.CompanyDetail_Box .RowBox .data_list .data_item .bottom .action {
  cursor: pointer;
  width: 50%;
}
.SearchList_Box .RowBox .data_list .data_item .bottom .action img,
.GameDetail_Box .RowBox .data_list .data_item .bottom .action img,
.CompanyDetail_Box .RowBox .data_list .data_item .bottom .action img {
  object-fit: contain;
  width: 21.33px;
  height: 21.33px;
  margin-right: 11px;
}
.SearchList_Box .RowBox .data_list:last-child,
.GameDetail_Box .RowBox .data_list:last-child,
.CompanyDetail_Box .RowBox .data_list:last-child {
  border-bottom: 1px solid rgba(40, 57, 94, 0.2);
}
.SearchList_Box .RowBox .slider,
.GameDetail_Box .RowBox .slider,
.CompanyDetail_Box .RowBox .slider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  min-height: 312px;
  flex-direction: column;
}
.SearchList_Box .RowBox .slider img,
.GameDetail_Box .RowBox .slider img,
.CompanyDetail_Box .RowBox .slider img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.SearchList_Box .RowBox .Details_Pic_List_Box,
.GameDetail_Box .RowBox .Details_Pic_List_Box,
.CompanyDetail_Box .RowBox .Details_Pic_List_Box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 20px 0;
  min-height: 312px;
}
.SearchList_Box .RowBox .Details_Pic_List_Box > .ant-empty,
.GameDetail_Box .RowBox .Details_Pic_List_Box > .ant-empty,
.CompanyDetail_Box .RowBox .Details_Pic_List_Box > .ant-empty {
  padding: 110px 0;
}
.SearchList_Box .RowBox .Details_Pic_List_Box > div,
.GameDetail_Box .RowBox .Details_Pic_List_Box > div,
.CompanyDetail_Box .RowBox .Details_Pic_List_Box > div {
  width: 30%;
}
.SearchList_Box .RowBox .Details_Pic_List_Box > div img,
.GameDetail_Box .RowBox .Details_Pic_List_Box > div img,
.CompanyDetail_Box .RowBox .Details_Pic_List_Box > div img {
  width: 300px;
  max-height: 300px;
  object-fit: scale-down;
}
.SearchList_Box .RowBox .Details_Pic_List_Box > div .ant-empty-image img,
.GameDetail_Box .RowBox .Details_Pic_List_Box > div .ant-empty-image img,
.CompanyDetail_Box .RowBox .Details_Pic_List_Box > div .ant-empty-image img {
  width: 160px;
  height: 100px;
  object-fit: contain;
}
.SearchList_Box .RowBox .ProlistBox li,
.GameDetail_Box .RowBox .ProlistBox li,
.CompanyDetail_Box .RowBox .ProlistBox li {
  padding: 20px;
  word-wrap: break-word;
  word-break: normal;
  border-bottom: none;
}
.SearchList_Box .RowBox .ant-table-thead > tr > th,
.GameDetail_Box .RowBox .ant-table-thead > tr > th,
.CompanyDetail_Box .RowBox .ant-table-thead > tr > th {
  background: none;
}
.GameDetail_Box .Game_Detail_Top {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
}
.GameDetail_Box .Game_Detail_Top .Game_Pic {
  width: 438px;
  height: 438px;
  overflow: hidden;
  border-radius: 10px;
  line-height: 438px;
}
.GameDetail_Box .Game_Detail_Top .Game_Pic img {
  width: 100%;
}
.GameDetail_Box .Game_Detail_Top .Game_Info {
  width: calc(100% - 468px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.GameDetail_Box .Game_Detail_Top .Game_Info .Game_Info_Title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.GameDetail_Box .Game_Detail_Top .Game_Info .Game_Info_Title h2 {
  color: #28395e;
  font-size: 36px;
  font-style: italic;
  margin-bottom: 0;
}
.GameDetail_Box .Game_Detail_Top .Game_Info .Game_Info_Title .Game_Info_Tag_List {
  margin-left: 20px;
}
.GameDetail_Box .Game_Detail_Top .Game_Info .Game_Info_Link a {
  font-size: 20px;
  text-decoration: underline;
  color: #2e65dd;
}
.GameDetail_Box .Game_Detail_Top .Game_Info .Game_Info_Amot {
  margin-top: 12px;
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.GameDetail_Box .Game_Detail_Top .Game_Info .Game_Info_Amot .Game_Info_Amot_Label {
  max-width: 33%;
}
.GameDetail_Box .Game_Detail_Top .Game_Info .Game_Info_Amot .Game_Info_Amot_Label > div p {
  font-size: 18px;
  margin-bottom: 0.5em;
}
.GameDetail_Box .Game_Detail_Top .Game_Info .Game_Info_Amot .Game_Info_Amot_Label > div .Game_Info_Blink {
  font-size: 16px;
  font-weight: bold;
}
.left-column {
  padding: 30px 40px;
  background-color: rgba(236, 244, 250, 0.55);
  border-radius: 8px;
  border-left: 8px solid #02aee8;
  font-size: 18px;
}
@media (max-width: 768px) {
  .left-column {
    margin-top: 10px;
    padding: 30px 10px;
  }
}
.left-column div {
  margin-bottom: 0.5rem;
}
.left-column .item-label,
.left-column .item-value {
  display: inline-block;
}
.left-column .item-label {
  width: 35%;
  color: #70727b;
}
.left-column .item-value {
  width: 65%;
  font-weight: bold;
  color: #2e373c;
}
.game-title,
.org-title,
.show-all {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 500;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #f0faff !important;
}
.game-title {
  background-color: #def4e7;
  color: #19ae54;
}
.game-title:hover {
  color: white;
  background-color: #19ae54;
}
.org-title {
  background-color: #f1dff1;
  color: #9e219e;
}
.org-title:hover {
  color: white;
  background-color: #9e219e;
}
.show-all {
  background-color: #efefef;
  color: #70727b;
}
.show-all:hover {
  color: white;
  background-color: #70727b;
}
.viewWrapper {
  display: flex;
  justify-content: flex-end;
}
.viewWrapper .moreBtn {
  width: 145px;
  height: 38px;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 20px;
  padding-top: 4px !important;
}
.viewWrapper .moreBtn:hover {
  background-color: #ecf4fa !important;
}
.ant-tabs-ink-bar {
  background-color: #02aee8;
  height: 3px !important;
}
.link {
  display: flex;
  font-size: 1rem;
  color: #02aee8;
}
.link span {
  color: rgba(153, 153, 153);
}
.link a {
  display: flex;
  color: #02aee8;
}
.link a span {
  color: #02aee8;
}
.link a:hover {
  text-decoration: underline;
}
.link .moreBtn:hover {
  background-color: #ecf4fa !important;
}
.listLink {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  margin: 1rem 1rem 1.5rem 0;
  color: rgba(153, 153, 153);
}
@media (max-width: 768px) {
  .text-element {
    font-size: 12px;
  }
}
.listLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.listLink2 {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  color: rgba(153, 153, 153);
}
.listLink2:hover {
  text-decoration: underline;
  cursor: pointer;
}
.marketNotes ol,
.marketNotes ul {
  margin-left: 18px;
}
