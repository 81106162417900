.custom-pagination .ant-pagination-total-text {
  order: 1;
  margin-right: 16px;
  margin-left: 32px;
}
.custom-pagination .ant-pagination-options {
  order: 2;
}
.custom-pagination .ant-pagination-item {
  order: 0;
}
.leftSection {
  width: 50%;
}
@media screen and (max-width: 1024px) {
  .leftSection {
    width: 100% !important;
    padding-left: 20px;
  }
}
.pdf {
  height: 100%;
}
.rich_pdf {
  width: 50%;
  margin-bottom: 8rem;
}
@media screen and (max-width: 1024px) {
  .rich_pdf {
    width: 100% !important;
  }
}
.ant-spin-nested-loading {
  height: 100%;
  width: 100%;
}
.ant-spin-container {
  height: 100%;
}
.subControls {
  margin-left: -20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.controls {
  padding-right: 20px;
  margin-top: 40px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 53px;
}
@media screen and (max-width: 480px) {
  .controls {
    margin-top: 0;
    margin-left: 0;
    display: flex;
    align-self: center;
    justify-content: space-between;
    padding-right: 0px;
  }
}
.controls .btn_action {
  border: 1px solid #88acce;
  border-radius: 25px;
  display: flex;
  padding: 5px;
  padding-left: 20px;
  padding-right: 25px;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .controls .btn_action {
    margin-bottom: unset;
    padding: 3px;
    padding-left: 10px;
    padding-right: 15px;
  }
}
.controls .btn_action .icon_action {
  width: 20px;
  height: 20px;
}
.controls .btn_action .icon_action .icon {
  width: 20px;
  height: 20px;
  color: #88acce;
}
.controls .btn_action .option {
  font-size: 18.67px;
  font-weight: 500;
  color: #2864a1;
  margin-left: 15px;
  margin-top: 1px;
  text-align: -webkit-center;
}
.controls .btn_action:hover {
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  color: #2864a1 !important;
  background-color: #ecf4fa !important;
  border: #2864a1 2px solid !important;
}
@media screen and (max-width: 480px) {
  .controls {
    width: 100% !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.exclusives_container {
  padding: 48px 0px 0px 48px;
}
@media screen and (max-width: 480px) {
  .exclusives_container {
    padding: 12px 15px 0px 15px;
  }
}
.exclusives_container .active {
  display: flex;
  /* Optional: Show toolbar fully on hover */
}
.exclusives_container .active .left_title {
  width: 90%;
  font-size: 32px;
  font-weight: 400;
  color: #2e373c;
  vertical-align: top;
}
@media screen and (max-width: 480px) {
  .exclusives_container .active .left_title {
    font-size: 25px;
    width: 100% !important;
  }
}
.exclusives_container .active .left_title .icon_article {
  margin-right: 21px;
}
.exclusives_container .active .right_time {
  font: normal normal normal 14px/20px Inter;
  font-size: 16.33px;
  font-weight: 400;
  color: #70727b;
}
@media screen and (max-width: 480px) {
  .exclusives_container .active .right_time {
    font-size: 16px;
  }
}
.exclusives_container .active .title {
  border-top: 1px solid #bdcbd8;
  border-bottom: 1px solid #bdcbd8;
  width: 97%;
  text-align: left;
  font: normal normal normal 18px/28px Inter;
  letter-spacing: 0px;
  font-weight: 400;
  color: #2e373c;
  padding-top: 29px;
  padding-bottom: 29px;
}
@media screen and (max-width: 480px) {
  .exclusives_container .active .title {
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;
    max-width: 100%;
    /* Adjust width as needed */
    word-wrap: break-word;
    /* Forces text to wrap to the next line */
    white-space: normal;
    /* Allows line breaks within the element */
  }
}
.exclusives_container .active .rich_pdf {
  position: relative;
}
.exclusives_container .active .pdf-toolbar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  /* Low opacity */
  background-color: rgba(255, 255, 255, 0.7);
  /* Semi-transparent background */
  padding: 10px;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  transition: opacity 0.3s;
}
.exclusives_container .active .rich_pdf:hover .pdf-toolbar {
  opacity: 1;
}
.exclusives_container .active .rich_pdf {
  transform-origin: top center;
  display: flex;
  justify-content: center;
  height: 45rem;
  /* Default height for normal 100% DPI screens */
  /* Adjustments for 150% DPI on desktop */
}
@media screen and (max-width: 500px) {
  .exclusives_container .active .rich_pdf {
    height: 30rem;
  }
}
@media screen and (max-width: 400px) {
  .exclusives_container .active .rich_pdf {
    height: 20rem;
  }
}
@media screen and (max-width: 1024px) {
  .exclusives_container .active {
    flex-direction: column;
  }
}
.exclusives_container .history_box {
  zoom: 0.8;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.exclusives_container .history_box .history {
  margin-top: 0;
  margin-bottom: 3.4rem;
  width: 492px;
  height: 66.67px;
  border-radius: 30px;
  font-size: 26px;
  font-weight: 400;
  color: white;
  background-color: #02aee8 !important;
  border: none !important;
  transition: background-color 0.3s, color 0.3s;
}
@media screen and (max-width: 768px) {
  .exclusives_container .history_box .history {
    width: 100%;
    height: fit-content !important;
  }
  .exclusives_container .history_box .history span {
    display: inline-block;
    /* Ensures the span behaves like a block for breaking lines */
    word-wrap: break-word;
    /* Break long words onto the next line */
    word-break: break-word;
    /* Supports breaking words across multiple lines */
    white-space: normal;
    /* Allows wrapping instead of keeping text on a single line */
  }
}
.exclusives_container .history_box .history:hover {
  background-color: #0099cc !important;
  /* Slightly darker shade */
  color: white;
  /* Slightly less bright */
}
.exclusives_container .form_box {
  margin-top: 40px;
  background-color: #f7f9fb;
  padding: 30px 50px;
  margin-bottom: 32px;
}
.exclusives_container .form_box .ant-form-item-label > label {
  font-size: 20px;
}
.exclusives_container .area {
  margin-left: 2px;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 18px/28px Inter;
  letter-spacing: 0px;
  color: #70727b;
  opacity: 1;
}
.exclusives_container .area_Tag_List_Box {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .exclusives_container .area_Tag_List_Box {
    width: 100%;
  }
}
.exclusives_container .data_list {
  padding-top: 10px;
  padding-bottom: 24px;
}
.exclusives_container .data_list .data_item {
  border-top: 1px solid #bdcbd8;
  padding: 25px 20px;
}
@media screen and (max-width: 480px) {
  .exclusives_container .data_list .data_item {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.exclusives_container .data_list .data_item .head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.exclusives_container .data_list .data_item .head .title {
  /* font: normal normal medium 30px/30px Inter; */
  font-size: 1.5rem;
  font-weight: 700;
  color: #2e373c;
  max-width: 100%;
  /* Adjust width as needed */
  word-wrap: break-word;
  /* Forces text to wrap to the next line */
  white-space: normal;
  /* Allows line breaks within the element */
}
.exclusives_container .data_list .data_item .head .time {
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
  color: #999999;
}
.exclusives_container .data_list .data_item .mid {
  font-weight: 400;
  margin-bottom: 21px;
  text-align: left;
  font-size: 1.4rem;
  color: #2e373c;
  padding-top: 10px;
}
.exclusives_container .data_list .data_item .bottom {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  width: 480px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 0px;
  color: #02aee8;
  text-align: left;
  vertical-align: top;
}
.exclusives_container .data_list .data_item .bottom .action {
  cursor: pointer;
  width: 30%;
}
.exclusives_container .data_list .data_item .bottom .action img {
  object-fit: contain;
  width: 21.33px;
  height: 21.33px;
  margin-right: 11px;
}
