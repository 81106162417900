.homeContainer {
	width: 100%;
	background-color: #fff;

	.homeContent {
		.body {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			padding: 30px 40px;
			background-color: #fff;

			.bodyItem {
				display: flex;
				flex-direction: column;
				width: 498px;
				height: 720px;

				.bodyItemTitle {
					margin-bottom: 20px;
					color: #333;
					font-size: 20px;
					font-weight: 600;
				}

				.bodyItemCon {
					position: relative;
					display: flex;
					flex-direction: column;
					border: 1px solid #ccc;
					border-radius: 4px;
					padding: 40px 20px;

					.tip {
						position: absolute;
						left: -1px;
						top: -1px;
					}

					.top {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.left {
							display: flex;
							align-items: center;

							.img {
								width: 100px;
								height: 100px;
								border-radius: 50%;

								.pic {
									width: 100px;
									height: 100px;
									border-radius: 50%;
								}
							}

							.name {
								margin-left: 16px;
								color: #333;
								font-size: 16px;
							}
						}

						.right {
							.score {
								color: #f49420;
								font-size: 36px;
							}
						}
					}

					.info {
						display: flex;
						justify-content: space-between;
						margin-top: 30px;
						padding: 20px 0;
						background-image: linear-gradient(
							to bottom right,
							#f1f1f1,
							#fcfcfc
						);

						.left {
							display: flex;
							align-items: center;

							.text2 {
								margin-left: 80px;
							}
						}
					}

					.level {
						display: flex;
						flex-direction: column;
						padding-top: 30px;

						.levelItem {
							display: flex;
							align-items: center;
							justify-content: space-between;

							.levelItemL {
								display: flex;
								align-items: center;
								margin-bottom: 36px;

								.ItemRank {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 32px;
									height: 32px;
									border-radius: 50%;
									background-color: #ccc;
									color: #fff;
									font-size: 20px;
								}

								.two {
									background-color: #d0d3dc;
								}

								.three {
									background-color: #f1bf78;
								}

								.ItemInfo {
									display: flex;
									align-items: center;
									justify-content: center;
									margin-left: 40px;

									.ItemImg {
										width: 32px;
										height: 32px;

										.pic {
											width: 32px;
											height: 32px;
											border-radius: 50%;
										}
									}

									.ItemName {
										width: 120px;
										margin-left: 10px;
									}
								}
							}
						}
					}
				}
			}
		}

		.footer {
			display: flex;
			padding-bottom: 100px;
			background-color: #fff;
			overflow: hidden;

			.bgc {
				position: relative;
				z-index: 1;
				left: -130px;
				width: 40rem;
				height: 40rem;
				border-radius: 50%;
				background-image: linear-gradient(
					rgba(87, 160, 209, 0.1),
					rgba(44, 103, 164, 0.1)
				);
			}

			.polyLine {
				display: flex;
				position: relative;
				margin: 100px 0 0 -530px;
				z-index: 999;

				.polyLineTitle {
					display: flex;
					flex-direction: column;
					margin-right: 50px;

					.info {
						color: #333;
						font-size: 36px;
						font-weight: 600;
					}

					.question {
						margin: 36px 0 50px 0;
						width: 478px;
						color: #666;
						font-size: 16px;
						line-height: 28px;
					}

					.type {
						display: flex;
						align-items: center;

						.left,
						.right {
							display: flex;
							align-items: center;
							justify-content: center;

							.l {
								width: 22px;
								height: 8px;
								margin-right: 20px;
								background-color: #5883f7;
							}

							.r {
								color: #333;
								font-size: 16px;
							}
						}

						.left {
							margin-right: 40px;
						}

						.right {
							.l {
								background-color: #5dd29d;
							}
						}
					}
				}
			}
		}
	}
}

.insightsFeedForEsportsBox {
	.CompanyChartBox {
		min-height: 450px;

		.Chart_Run_Title {
			padding: 40px 80px;

			span,
			p {
				color: rgba(153, 153, 153, 100);
				font-size: 24px;
				font-weight: lighter;
			}

			p::before {
				content: "●";
			}

			span {
				display: block;
			}
		}
	}
}

.Organizations_Box {
	.Organizations_Statistic_Box {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-content: center;

		.Organizations_Statistic_Card {
			height: 90%;
			// width:50%;
			display: flex;
			// width: 30%;
			// flex-direction: column;
			align-items: center;
			justify-content: center;
			// background-color: #FAFBFC;
		}
		.Statistic_Card_Sub {
			margin-top: 20px;
			text-align: center;
			padding-left: 20px;
		}
		> div:nth-child(2) {
			display: flex;
			height: 100%;
			flex-direction: column;
			width: 60%;
			> div:nth-child(1) {
				border-bottom: 1px solid #fafbfc;
			}
		}
	}
}

.Tournament_Info_Link {
	a {
		font-size: 20px;
		text-decoration: underline;
		color: #2e65dd;
	}
}

.Tag_List {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 600px;

	.Tag_Item {
		font-size: 1.3rem;
		width: fit-content;
		margin: 10px 0;
		padding: 5px 10px;
		cursor: pointer;
	}
}

.tournament-container {
	width: 98%;
	margin: 0 auto;
	padding: 20px;

	.title {
		font-size: 3rem;
		font-weight: 500;
		color: #2e373c;
		margin-bottom: 0;
	}
	@media (max-width: 768px) {
		.title {
			font-size: 1.5rem;
		}
	}

	.game-title,
	.org-title {
		display: inline-block;
		padding: 2px 10px;
		border-radius: 5px;
		margin-top: 10px;
		margin-right: 10px;
		cursor: pointer;
		font-weight: 500;
	}

	.game-title {
		background-color: #def4e7;
		color: #19ae54;
	}
	.game-title:hover {
		color: white;
		background-color: #19ae54;
	}

	.website-link {
		display: inline-block;
		margin-top: 20px;
		margin-bottom: 50px;
		font-size: 1rem;
		color: #02aee8;
		font-weight: normal;
		text-decoration: none;

		img {
			margin-right: 5px;
		}
	}
	.website-link:hover {
		text-decoration: underline;
	}

	.details {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
		@media (max-width: 768px) {
			flex-direction: column;
			gap: 5px;
		}
		.left-column,
		.right-column {
			width: 49%;
			padding: 30px 40px;
			background-color: rgba(236, 244, 250, 0.55);
			border-radius: 8px;
			border-left: 8px solid #02aee8;

			.item-label,
			.item-value {
				display: inline-block;
			}

			.item-label {
				width: 35%;
				color: #70727b;
			}
			.item-value {
				width: 65%;
				font-weight: bold;
				color: #2e373c;
			}

			.hostsContainer {
				display: flex;
			}

			div {
				margin-bottom: 10px;
				font-size: 1.1rem;

				strong {
					font-weight: bold;
					margin-right: 5px;
				}
			}
		}
		@media (max-width: 768px) {
			.left-column,
			.right-column {
				width: 100%;
				padding: 30px 10px;
			}
		}
		.hosts {
			display: inline-block;
			width: 70%;

			.org-title {
				background-color: #f1dff1;
				color: #9e219e;
				font-size: 0.9rem;
			}
			.org-title:hover {
				color: white;
				background-color: #9e219e;
			}
		}
	}
}

@primary-color: #003778;@border-radius-base: 6px;@layout-header-background: #ffffff;@layout-sider-background: #003778;@menu-bg: #003778;@menu-inline-toplevel-item-height: 94px;@menu-item-height: 53px;@menu-item-color: #ffffff;@menu-highlight-color: #ffffff;@menu-inline-submenu-bg: #003778;@menu-item-active-bg: rgba(128, 160, 231, 0.3);@menu-popup-bg: #003778;@menu-item-active-border-width: 0px;