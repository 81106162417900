@import "../../../index.less";

.TeamsBox,
.insightsFeedForEsportsBox,
.CompanyDetail_Box,
.GameBox,
.CompanyBox,
.BlogDetailBox,
.BlogBox,
.FreeReportsBox {
	width: 95%;
	margin: 0 auto;
	color: #333333;
	.a_style {
		text-decoration: none;
		color: #02aee8;
		font-weight: 500;
	}
	.a_style:hover {
		text-decoration: underline;
		color: #02aee8;
		font-weight: 500;
	}
	ul.ProlistBox {
		> li:hover {
			background-color: #f4fbff;
		}

		> li {
			border-top: 1px solid rgba(40, 57, 94, 0.2);
			border-bottom: 1px solid rgba(40, 57, 94, 0.2);
			min-height: 160px;
			padding: 25px 0px;
			padding-bottom: 5px;

			.insght_pic {
				margin-right: 28px;
				cursor: pointer;
				overflow: hidden;
				width: 100px;
				height: 100px;
				line-height: 100px;
				border-radius: 10px;
				background-color: #fdfdfd;
				border: 1px solid #eee;
				object-fit: contain;
			}

			> span {
				font-size: 20px;
				font-weight: bold;
			}

			.insight_list_cont {
				min-height: 120px;
				// width: calc(100% - 88px);
				display: block;

				.Tag_List_Box {
					padding: 5px 0;

					.ant-tag {
						font-size: 14px;
						margin-left: 10px;
						cursor: pointer;
					}

					.ant-tag:first-child {
						margin-left: 0;
					}
				}

				h3 {
					display: flex;
					justify-content: space-between;
					align-items: center;

					div {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						font-size: 20px;
						span {
							font-weight: 300;
							margin-right: 10px;
							cursor: pointer;
							color: #70727b;
						}

						.insight_list_title {
							cursor: pointer;
						}
					}

					.insight_list_date_style {
						font-size: 16px;
						font-weight: 600;
						margin-right: 10px;
						color: rgba(153, 153, 153, 100);
					}
				}
			}

			.insght_lst_info {
				font-size: 16px;
				table-layout: fixed;
				word-wrap: break-word;
				word-break: normal;
				cursor: pointer;
				margin-bottom: 20px;
			}
		}
	}
	ul.ProlistBox:last-child {
		border-bottom: 1px solid rgba(40, 57, 94, 0.2);
	}
}

.BlogDetailLabel {
	background-size: 100% auto !important;
	font-weight: lighter;
	background: url(../../../assets/images/backgroupimg.png) top center no-repeat
		#fff;
	min-height: 100vh;
	padding-top: 30px;
	img {
		max-width: 1200px;
		object-fit: contain;
	}

	.BlogDetailBox {
		margin: 0 auto;
		.Global_title {
			margin-bottom: 1rem !important;
			background: none;
			font-size: 24px;
			color: #28395e;
			font-weight: bold;
		}

		.Article_Info {
			display: flex;
			align-items: center;
			margin-bottom: 30px;
			justify-content: space-between;
			.Article_Info_Lf {
				display: flex;
				align-items: center;

				> span {
					color: #999999;
					font-size: 16px;
					margin-right: 10px;
					cursor: pointer;
				}
				.Article_Info_Taglist {
				}
			}
			.Article_Info_Lf > span:hover {
				text-decoration: underline;
			}

			.Article_Info_Rg {
				> span {
					color: #999999;
					font-size: 16px;
					margin-right: 10px;
					cursor: pointer;
				}
			}
			.Article_Info_Rg > span:hover {
				text-decoration: underline;
			}
		}

		.report_Box {
			color: #999999;
			font-size: 16px;
			margin-right: 10px;
		}
	}
}

.ant-row > .ant-col:nth-last-child(2) .Statistic_Card_Sub_a {
	border-right: 1px solid #e9e9e9;
}

.SearchList_Box,
.user_Info_Box,
.TeamsBox,
.insightsFeedForEsportsBox,
.GameBox,
.CompanyBox {
	.Statistic_List,
	.Statistic_Box {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.Organizations_Statistic_Card {
			img {
				width: 112.24px;
			}
		}

		.Statistic_Card {
			width: 68px;

			img {
				width: 68px;
				height: 68px;
				object-fit: contain;
			}
		}
		.Statistic_Card_Sub_a {
			padding-left: 55px;
			padding-right: 70px;

			p {
				margin-bottom: 0;
				font-size: 20px;
				color: #999;
			}

			h2 {
				font-size: 36px;
				font-weight: bold;
				color: #28395e;
				margin-bottom: 0;
			}
		}

		.Statistic_Card {
			height: calc(100% - 1px);
			display: flex;
			justify-content: center;
			align-items: center;
			width: calc(100%);
			.Statistic_Card_Sub {
				padding-left: 55px;
				padding-right: 70px;
				p {
					margin-bottom: 0;
					font-size: 20px;
					color: #999;
				}
				h2 {
					font-size: 36px;
					font-weight: bold;
					color: #28395e;
					margin-bottom: 0;
				}
			}
		}
	}
	.CompanyChartBox {
		font-size: 16px;
	}
	.ant-form-item {
		margin-bottom: 0;
	}
	.CompanyTableBox {
		.ant-table {
			font-size: 16px;
			border: 1px solid rgba(40, 57, 94, 0.2);
			border-radius: 8px;
			overflow: hidden;
			.Tabel_Related_Games,
			.Tabel_Company {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.Tabel_Company_Logo {
					width: 43px;
					height: 43px;
					overflow: hidden;
					box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 33%);
					border-radius: 5px;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 100% auto;

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}

				.Tabel_Related_Games_Title,
				.Tabel_Company_Title {
					margin-left: 15px;
				}

				p {
					margin-bottom: 0;
				}
			}

			.Tabel_Related_Games {
				margin-bottom: 10px;

				.Tabel_Related_Games_Logo {
					img {
						width: 64px;
						height: 64px;
						overflow: hidden;
						box-shadow: 0px 2px 4px 0px rgba(233, 233, 233, 0.5);
						border-radius: 5px;
					}
				}

				.Tabel_Related_Games_Title {
				}
			}

			.Tabel_Information {
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				padding-right: 50px;
			}
		}

		.ant-table-thead {
			// .ant-table-thead {

			// }
			background-image: linear-gradient(to right, #eff4f8, #fff);
			line-height: 32px;
			height: 32px;
		}

		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(
				.ant-table-row-expand-icon-cell
			):not([colspan])::before {
			width: 0;
		}
	}

	.Statistic_List {
		width: 100%;
		display: flex;
		flex-direction: column;

		.Team_Area_List {
			width: 100%;
			padding: 10px 20px;
			display: flex;
			justify-content: space-between;

			div {
				font-size: 20px;
				color: #999;
				font-weight: lighter;
			}
		}
	}
}
.gameCompDet {
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
	}
}
.TransactionsTableBox {
	.ant-table {
		font-size: 16px;
		overflow: hidden;
	}
	.ant-table-thead {
		line-height: 32px;
		height: 32px;
	}

	.ant-table-thead > tr > th {
		background: none;
		color: #999;
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		width: 0;
	}
}
.home_Card_Row {
	display: flex;
	justify-content: space-between;
	.home_Card_Box {
		border-radius: 8px;
		border: none;
		width: calc(25% - 10px);
		.statistic_Card {
			padding-left: 50px;
		}
		.statistic_title {
			color: rgba(0, 0, 0, 0.45);
			font-size: 22px;
		}
		.statistic_value {
			color: rgba(40, 57, 94, 100);
			font-size: 30px;
			font-weight: 600;
		}
		> div {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 200px;
			border-radius: 2px;
			overflow: hidden;

			div:nth-child(2) {
				text-align: center;
			}
		}
	}

	> div:nth-child(1) {
		background-color: rgba(255, 152, 152, 0.1);
	}

	> div:nth-child(2) {
		background-color: rgba(109, 65, 221, 0.1);
	}

	> div:nth-child(3) {
		background-color: rgba(234, 148, 25, 0.1);
	}

	> div:nth-child(4) {
		background-color: rgba(128, 160, 231, 0.1);
	}
}

.SearchList_Box,
.GameDetail_Box,
.CompanyDetail_Box {
	.Detail_Title_Box {
		display: flex;
		justify-content: flex-start;
		align-content: center;
		align-items: flex-start;
		margin-bottom: 10px;
		.companyDtIco {
			width: 200px;
			height: 200px;
			border-radius: 8px;
			overflow: hidden;
			line-height: 120px;
			border: 2px solid #b1c7dc;

			img {
				height: 100%;
				width: 100%;
				line-height: 100%;
			}
		}

		.companyDttitle {
			width: 65%;
			display: flex;
			margin-left: 20px;
			flex-direction: column;
			justify-content: space-around;

			h1 {
				font-size: 36px;
				color: #2e373c;
			}

			p {
				a {
					text-decoration: underline;
				}
			}

			.website-link {
				display: flex;
				margin-top: 20px;
				margin-bottom: 50px;
				font-size: 1rem;
				color: #02aee8;
				font-weight: normal;
				text-decoration: none;

				img {
					margin-right: 5px;
				}
			}
			.website-link:hover {
				text-decoration: underline;
			}
		}
	}

	.Atom_layout {
		padding: 25px 20px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.Atom_Box {
			p {
				font-size: 16px;
				color: #999;
				margin-bottom: 0.5em;
			}

			p.Fsb {
				font-size: 24px;
				color: #333;
				font-weight: bold;
			}
		}
	}

	.RowBox {
		.Global_title {
			line-height: 1;
			padding: 24px 0px;
			h2 {
				margin-bottom: 0;
				color: #2864a1;
				font-weight: bold;
				font-size: 2.2rem;
				@media (max-width: 768px) {
					font-size: 1.2rem;
				}
			}
			.right_more {
				font-size: 18px;
				font-weight: 400;
				letter-spacing: 0px;
				line-height: 0px;
				color: rgba(244, 123, 32, 1);
				text-align: center;
				vertical-align: top;
				cursor: pointer;
			}
		}
		.data_list {
			// padding-top: 47px;
			padding-bottom: 24px;
			border-radius: 10.67px;
			.data_item {
				margin-bottom: 40px;
				padding-left: 56px;
				padding-right: 40px;
				padding-top: 30px;
				border-top: 1px solid rgba(40, 57, 94, 0.2);
				.head {
					display: flex;
					justify-content: space-between;
					margin-bottom: 10px;
					.title {
						font-size: 20px;
						font-weight: 700;
						color: rgba(51, 51, 51, 1);
					}
					.time {
						font-size: 18px;
						font-weight: 400;
						color: rgba(153, 153, 153, 1);
					}
				}
				.mid {
					font-size: 18px;
					font-weight: 400;
					margin-bottom: 21px;
					color: rgba(51, 51, 51, 1);
				}
				.bottom {
					display: flex;
					justify-content: space-between;
					width: 480px;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: 0px;
					line-height: 0px;
					color: rgba(0, 55, 120, 1);
					text-align: left;
					vertical-align: top;
					.action {
						cursor: pointer;
						width: 50%;
						img {
							object-fit: contain;
							width: 21.33px;
							height: 21.33px;
							margin-right: 11px;
						}
					}
				}
			}
		}
		.data_list:last-child {
			border-bottom: 1px solid rgba(40, 57, 94, 0.2);
		}
		.slider {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 20px 20px;
			min-height: 312px;
			flex-direction: column;

			img {
				width: 200px;
				height: 200px;
				object-fit: contain;
			}
		}

		.Details_Pic_List_Box {
			display: flex;
			justify-content: space-around;
			align-items: center;
			text-align: center;
			padding: 20px 0;
			min-height: 312px;

			> .ant-empty {
				padding: 110px 0;
			}

			> div {
				width: 30%;

				img {
					width: 300px;
					max-height: 300px;
					object-fit: scale-down;
				}

				.ant-empty-image img {
					width: 160px;
					height: 100px;
					object-fit: contain;
				}
			}
		}

		.ProlistBox {
			li {
				padding: 20px;
				word-wrap: break-word;
				word-break: normal;
				border-bottom: none;
			}
		}

		.ant-table-thead > tr > th {
			background: none;
		}
	}
}

.GameDetail_Box {
	.Game_Detail_Top {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		align-items: flex-start;

		.Game_Pic {
			width: 438px;
			height: 438px;
			overflow: hidden;
			border-radius: 10px;
			line-height: 438px;

			img {
				width: 100%;
			}
		}

		.Game_Info {
			width: calc(100% - 468px);
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.Game_Info_Title {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-direction: row;

				h2 {
					color: #28395e;
					font-size: 36px;
					font-style: italic;
					margin-bottom: 0;
				}

				.Game_Info_Tag_List {
					margin-left: 20px;
				}
			}

			.Game_Info_Link {
				a {
					font-size: 20px;
					text-decoration: underline;
					color: #2e65dd;
				}
			}

			.Game_Info_Amot {
				margin-top: 12px;
				padding: 30px;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;

				.Game_Info_Amot_Label {
					max-width: 33%;

					> div {
						p {
							font-size: 18px;
							margin-bottom: 0.5em;
						}

						.Game_Info_Blink {
							font-size: 16px;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}

.left-column {
	padding: 30px 40px;
	background-color: rgba(236, 244, 250, 0.55);
	border-radius: 8px;
	border-left: 8px solid #02aee8;
	font-size: 18px;
	@media (max-width: 768px) {
		margin-top: 10px;
		padding: 30px 10px;
	}
	div {
		margin-bottom: 0.5rem;
	}

	.item-label,
	.item-value {
		display: inline-block;
	}

	.item-label {
		width: 35%;
		color: #70727b;
	}
	.item-value {
		width: 65%;
		font-weight: bold;
		color: #2e373c;
	}
}

.game-title,
.org-title,
.show-all {
	display: inline-block;
	padding: 2px 10px;
	border-radius: 5px;
	margin-top: 10px;
	margin-right: 10px;
	cursor: pointer;
	font-weight: 500;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
	background-color: #f0faff !important;
}
.game-title {
	background-color: #def4e7;
	color: #19ae54;
}
.game-title:hover {
	color: white;
	background-color: #19ae54;
}
.org-title {
	background-color: #f1dff1;
	color: #9e219e;
}
.org-title:hover {
	color: white;
	background-color: #9e219e;
}

.show-all {
	background-color: #efefef;
	color: #70727b;
}
.show-all:hover {
	color: white;
	background-color: #70727b;
}

.viewWrapper {
	display: flex;
	justify-content: flex-end;

	.moreBtn {
		width: 145px;
		height: 38px;

		justify-content: center;
		font-size: 1rem;
		font-weight: 500;
		border-radius: 20px;
		padding-top: 4px !important;
	}

	.moreBtn:hover {
		background-color: #ecf4fa !important;
	}
}

.ant-tabs-ink-bar {
	background-color: #02aee8;
	height: 3px !important;
}

.link {
	display: flex;
	font-size: 1rem;
	color: #02aee8;
	span {
		color: rgba(153, 153, 153);
	}
	a {
		display: flex;
		color: #02aee8;
		span {
			color: #02aee8;
		}
	}
	a:hover {
		text-decoration: underline;
	}

	.moreBtn:hover {
		background-color: #ecf4fa !important;
	}
}
.listLink {
	display: flex;
	font-size: 18px;
	font-weight: 400;
	margin: 1rem 1rem 1.5rem 0;
	color: rgba(153, 153, 153);
}
@media (max-width: 768px) {
	.text-element {
		font-size: 12px;
	}
}
.listLink:hover {
	text-decoration: underline;
	cursor: pointer;
}
.listLink2 {
	display: flex;
	font-size: 18px;
	font-weight: 400;
	align-items: center;
	color: rgba(153, 153, 153);
}

.listLink2:hover {
	text-decoration: underline;
	cursor: pointer;
}

.marketNotes {
	ol,
	ul {
		margin-left: 18px;
	}
}

@primary-color: #003778;@border-radius-base: 6px;@layout-header-background: #ffffff;@layout-sider-background: #003778;@menu-bg: #003778;@menu-inline-toplevel-item-height: 94px;@menu-item-height: 53px;@menu-item-color: #ffffff;@menu-highlight-color: #ffffff;@menu-inline-submenu-bg: #003778;@menu-item-active-bg: rgba(128, 160, 231, 0.3);@menu-popup-bg: #003778;@menu-item-active-border-width: 0px;