.filter {
  justify-content: baseline;
  align-items: center;
  justify-content: space-between;
  background-color: white !important;
  color: black !important;
  border: 1px solid black;
}
@media screen and (max-width: 768px) {
  .filter-modal {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 9999999;
    max-height: 100vh;
    /* Sets the maximum height to the full viewport height */
    overflow-y: auto;
    /* Enables vertical scrolling */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}
/* Optional: To make the modal fill the screen */
.filter-red-border {
  border-color: #02aee8 !important;
  /* Ensures border color is red when the modal is open */
}
.ant-modal-close-x {
  padding: 0px !important;
}
.ant-modal-body {
  padding-top: 60px !important;
}
.ant-select {
  align-items: center !important;
}
.ant-input {
  border-radius: 8px !important;
  margin-top: 10px !important;
  height: 46px !important;
}
.ant-input:hover {
  border-color: #4ad1ff !important;
}
.ant-picker:hover {
  border-color: #4ad1ff !important;
}
.ant-picker-range .ant-picker-active-bar {
  background: #4ad1ff !important;
}
.ant-picker-focused {
  border-color: #4ad1ff !important;
}
.ant-picker-range {
  border-radius: 8px !important;
  margin-top: 10px !important;
}
.reset {
  width: fit-content !important;
  padding: 0px !important;
  padding-bottom: 5px !important;
  color: #02aee8 !important;
  background-color: white !important;
  border: none !important;
  cursor: pointer !important;
  text-transform: none !important;
}
.resetDisabled {
  width: fit-content !important;
  padding: 0px !important;
  padding-bottom: 5px !important;
  color: #b2e7f8 !important;
  background-color: white !important;
  border: none !important;
  cursor: not-allowed !important;
  text-transform: none !important;
}
.label {
  color: #70727b !important;
}
.headBox {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  height: 25px !important;
}
.no-scroll {
  padding-right: var(--scrollbar-width);
  /* Adjust padding to prevent layout shift */
  overflow: hidden;
  /* Prevent scrolling */
}
.ant-modal {
  height: 200vh;
}
@media (resolution: 1.5dppx) {
  .ant-modal {
    height: 300vh;
  }
}
.ant-modal-wrap::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}
.ant-form-item-label > label {
  width: 100% !important;
  max-width: 100% !important;
}
.ant-select-selector {
  border-radius: 8px !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
		.ant-select-customize-input
	) .ant-select-selector {
  border-color: #4ad1ff !important;
}
.ant-select:not(.ant-select-disabled):not(
		.ant-select-customize-input
	).ant-select-focused .ant-select-selector {
  border-color: #4ad1ff !important;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):hover .ant-select-selector {
  border-color: #4ad1ff !important;
}
.applyBtn {
  background-color: #02aee8 !important;
  width: 108px !important;
  height: 40px !important;
  border-radius: 20px !important;
  border: none !important;
  padding-top: 6px;
  transition: background-color 0.3s ease;
  /* Smooth transition */
}
.applyBtn:hover {
  background-color: #028fc2 !important;
  /* Darker shade for hover */
}
.resetBtn {
  color: #2864a1 !important;
  width: 108px !important;
  height: 40px !important;
  border-radius: 20px !important;
  border: 1px solid #88acce !important;
  padding-top: 6px;
  transition: background-color 0.3s ease;
  /* Smooth transition */
  background-color: white !important;
}
.resetBtnSelected {
  color: #2864a1 !important;
  width: 108px !important;
  height: 40px !important;
  border-radius: 20px !important;
  border: 1px solid #02aee8 !important;
  padding-top: 6px;
  transition: background-color 0.3s ease;
  /* Smooth transition */
  background-color: white !important;
}
.resetBtn:hover {
  background-color: #ecf4fa !important;
  /* Darker shade for hover */
}
.filterresetBtn {
  color: #2864a1 !important;
  width: 108px !important;
  height: 43px !important;
  border-radius: 20px !important;
  border: 1px solid #88acce !important;
  padding-top: 6px;
  transition: background-color 0.3s ease;
  /* Smooth transition */
  background-color: white !important;
}
.filterresetBtn:hover {
  background-color: #ecf4fa !important;
  /* Darker shade for hover */
}
