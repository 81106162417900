@mobile: 480px;
.container {
	height: 100vh; /* Set the height to the full viewport height */
	background-image: url("../../assets/loginBG.png");
	background-size: cover; /* Ensures the image covers the entire container */
	background-position: center; /* Centers the image */
	background-repeat: no-repeat; /* Prevents the image from repeating */

	// overflow: auto; /* Enables scrolling when content overflows */
}
div.ant-typography {
	margin-bottom: 0 !important;
}
.content {
	background-color: aliceblue;
	height: 80%;
	overflow: auto; /* Enables scrolling when content overflows */
	max-width: 90%;
	margin: auto;
	padding: 70px 50px;
	border-radius: 40px;
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For IE and Edge */

	/* Hide scrollbar for Webkit browsers */
	&::-webkit-scrollbar {
		display: none; /* For Chrome, Safari, and Opera */
	}
}
@media screen and (max-width: @mobile) {
	.content {
		padding: 50px 20px;
	}
}
.contentfaq {
	background-color: white;
	height: 80%;
	overflow: auto; /* Enables scrolling when content overflows */
	max-width: 90%;
	margin: auto;
	padding: 70px 50px;
	border-radius: 40px;
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For IE and Edge */
	width: 100%;
	/* Hide scrollbar for Webkit browsers */
	&::-webkit-scrollbar {
		display: none; /* For Chrome, Safari, and Opera */
	}
}
@media screen and (max-width: @mobile) {
	.contentfaq {
		padding: 50px 5px;
	}
}
header {
	display: flex;
	justify-content: center;
	align-items: center;
	// margin-bottom: 20px;
}
.headerfaq {
	text-align: center;
	color: #2864a1;
	// font
}
.faqAnswer {
	font-size: 20px;
	// background-color: #f7f7f7;
}
.faqAnswer .anticon svg {
	width: 14px !important;
	height: 14px !important;
}
.pageTitle .ant-typography {
	@media screen and (max-width: @mobile) {
		width: unset !important;
	}
}
.ant-collapse-content > .ant-collapse-content-box {
	background-color: #f7f7f7 !important;
}
.container {
	padding: 0px 60px 52px 60px;
	@media screen and (max-width: @mobile) {
		padding: 1.25rem;
	}

	.pageTitle {
		text-align: center;
		color: #2864a1;
	}

	.subTitle {
		font-size: 1.5rem;
		color: #2864a1;
		margin-top: 0 !important;
		@media screen and (max-width: @mobile) {
			font-size: 1.5rem;
		}
	}

	.content {
		font-size: 1.5rem;
		@media screen and (max-width: @mobile) {
			font-size: 1rem;
		}
	}
}

@primary-color: #003778;@border-radius-base: 6px;@layout-header-background: #ffffff;@layout-sider-background: #003778;@menu-bg: #003778;@menu-inline-toplevel-item-height: 94px;@menu-item-height: 53px;@menu-item-color: #ffffff;@menu-highlight-color: #ffffff;@menu-inline-submenu-bg: #003778;@menu-item-active-bg: rgba(128, 160, 231, 0.3);@menu-popup-bg: #003778;@menu-item-active-border-width: 0px;