@import "../../../index.less";
.history {
	// margin-top: 28px;
	width: 90%;
	height: 40px;
	border-radius: 30px;
	font-size: 1rem;
	font-weight: 400;
	color: white;
	background-color: #02aee8 !important;
	border: none !important;
	transition: background-color 0.3s, color 0.3s;
}

.history:hover {
	background-color: #0099cc !important; /* Slightly darker shade */
	color: white; /* Slightly less bright */
}
.label {
	color: #70727b;
	font-size: 1rem;
	font-weight: 600;
}
.text {
	color: #2e373c;
	font-size: 1rem;
	margin-bottom: 20px;
	font-weight: 600;
}
.linkItem {
	color: #2e373c;
	font-weight: 500;
	font-size: 1rem;
}

.linkItem:hover {
	color: #f47b23;
	transition: 0.3s;
	text-decoration: underline;
}
.profileTabs {
	.ant-tabs-nav-list {
		margin-left: -10px;
	}

	margin-top: -15px;
	@media (max-width: 768px) {
		margin-top: 50px;
	}
}
.profile {
	@media (max-width: 768px) {
		padding: 0 !important;
	}
}
.user_Info_Box {
	background: #fff;
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	.user_Lft_Label {
		// margin-top: -100px;
		// background: url(../../../assets/images/userinfobg.png) no-repeat top left;
		// background-size: auto 100%;
		width: 380px;
		// min-height: 608px;
		padding-top: 20px;
		border: 2px solid #b1c7dc;
		border-radius: 8px;

		.u_Lft_Head_Info {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			text-align: center;
			flex-direction: column;
			gap: 15px;
			padding-bottom: 20px;
			border-bottom: 2px solid #b1c7dc;
			// height: 163px;

			.u_Head_Pic {
				width: 100px;
				height: 100px;
				border-radius: 50%;
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: center center;
			}
			.u_Lft_UserBox_info {
				width: 100%;
			}
			.u_Lft_User_Name {
				width: 100%;
				font-size: 30px;
				font-weight: bold;
				overflow-wrap: break-word;
			}

			.u_Lft_User_Pwd {
				color: rgba(153, 153, 153, 100);
				font-size: 16px;
				text-decoration: underline;
				cursor: pointer;
				img {
					padding-bottom: 3px;
					width: 22px;
					padding-right: 6px;
				}
			}
		}

		.u_Lft_Lst_Info {
			padding: 15px;
			background: #f9fcfe;
			border-radius: 15px;
		}
		@media (max-width: 768px) {
			width: 100%;
		}
	}

	.user_Rgt_Label {
		.ant-card-body {
			@media (max-width: 768px) {
				padding: 0 !important;
			}
		}
		width: calc(100% - 392px - 30px);
		@media (max-width: 768px) {
			width: 100%;
		}

		h3 {
			color: #333;
			line-height: 3em;
			padding-top: 2em;
			padding-left: 5px;
		}

		.historyBtnDiv {
			text-align: right;
			padding-right: 1rem;
			margin-bottom: 1rem;
		}

		// width: 70%;
		/* position: absolute;
		right: 0;
		bottom: 0; */
	}
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}
}

@primary-color: #003778;@border-radius-base: 6px;@layout-header-background: #ffffff;@layout-sider-background: #003778;@menu-bg: #003778;@menu-inline-toplevel-item-height: 94px;@menu-item-height: 53px;@menu-item-color: #ffffff;@menu-highlight-color: #ffffff;@menu-inline-submenu-bg: #003778;@menu-item-active-bg: rgba(128, 160, 231, 0.3);@menu-popup-bg: #003778;@menu-item-active-border-width: 0px;