@mobile: 480px;
.BlogDetailLabel img {
	max-width: 100vw !important;
}

.ant-breadcrumb ol li {
	cursor: unset;
}
.ant-breadcrumb ol li span {
	color: #2e373c;
}
.ant-select-dropdown {
	top: 35px !important;
	left: 0 !important;
}
// .nonpointer {
// 	cursor: unset !important;
// }

.truncate {
	//
	white-space: nowrap; /* Prevents text from wrapping to the next line */
	overflow: hidden; /* Hides the overflow */
	text-overflow: ellipsis; /* Adds ellipsis (...) for overflow text */
	display: block; /* Makes the div a block-level element */
	max-width: 90vw; /* Limits the width to the parent container */
	box-sizing: border-box; /* Ensures padding/border are included in the width */
	// font-size: 16px;
	// color: #999 !important;
}

.ant-breadcrumb {
	width: 80vw;
	margin-top: 3rem !important;
	margin-left: 50px !important;
}
@media (max-width: 768px) {
	.ant-breadcrumb {
		margin-left: 20px !important;
	}
}
/* Adjustments for small screens */
@media (max-width: 768px) {
	.layout_page_header {
		// justify-content: unset !important;
		padding-bottom: 10px;
	}
	.ant-breadcrumb {
		width: 77vw;
	}
	.truncate {
		max-width: 90vw; /* Limits the width to the parent container */
		margin-left: 0px !important;
	}
	.truncate2 {
		font-size: 16px;
	}
}
@media (max-width: 400px) {
	.footer-links {
		flex-wrap: wrap;
	}
}
@media (max-width: 400px) {
	.ant-typography {
		// width: 140px;
	}
}
@media (max-width: 550px) {
	.ant-breadcrumb ol {
		flex-wrap: wrap !important; /* Allow items to wrap onto multiple lines */
		align-items: center !important;
		width: 100%; /* Adjust width as needed */
		white-space: normal; /* Ensure text wraps properly */
	}
	.ant-breadcrumb ol li {
		cursor: pointer !important;
	}
	.ant-breadcrumb {
		// width: 50px; /* Set the width of the visible area */
		// overflow-x: scroll; /* Enable horizontal scrolling */
		// white-space: nowrap; /* Prevent line breaks */
		// width: 210px;
	}
	.ant-breadcrumb::-webkit-scrollbar {
		height: 2px !important; /* Set the height of the scrollbar (thickness) */
	}

	.ant-breadcrumb::-webkit-scrollbar-thumb {
		background-color: #b6b6b6; /* Set the color of the scrollbar thumb */
		border-radius: 40px; /* Optional: round the edges of the scrollbar thumb */
	}

	/* Custom scrollbar for Firefox */
	.ant-breadcrumb {
		scrollbar-width: thin; /* Make the scrollbar thinner */
		scrollbar-color: #b6b6b6 transparent; /* Set the thumb color and track color */

		// margin-top: 20px; /* Set the
	}
	.truncate {
		width: 300px; /* Set the desired width for the container */
		word-wrap: break-word; /* Allows breaking of long words */
		word-break: break-word; /* Ensures long words are broken */
		overflow-wrap: break-word; /* Modern equivalent for consistent behavior */
		white-space: normal; /* Allows text to wrap naturally */
		overflow: visible; /* Ensures no content is hidden */
	}

	.truncate2 {
		width: 1000px;
	}
	// .ant-layout {
	// 	padding-top: 0 !important;
	// }
	.ant-layout-header {
		position: static !important;
		// width: fit-content !important;
	}
}
@media (max-width: 400px) {
	.ant-breadcrumb {
		// overflow-x: scroll; /* Enable horizontal scrolling */
		white-space: nowrap; /* Prevent line breaks */
		// width: 175px;
		margin-top: 0.5rem !important;
		margin-left: 16px !important;
	}
}

.common {
	height: unset !important;

	.btn_1 {
		font-size: 18px;

		width: 228px;
		height: 54px;

		align-items: center;

		color: #2864a1 !important;
		background-color: #ffffff !important;
		border: 1px solid #d9d9d9 !important;
		border-radius: 6px;
		transition: background-color 0.3s, color 0.3s, border 0.3s;
	}
	.btn_1:hover {
		color: #2864a1 !important;

		border: #2864a1 2px solid !important;
	}
	.btn_2 {
		font-size: 18px;
		height: 54px;
		width: 228px;
		align-items: center;

		color: #ffffff !important;
		background-color: #f47b23 !important;

		border: none !important;
		border-radius: 6px;
		transition: background-color 0.3s, color 0.3s;
	}
	.btn_2:hover {
		background-color: #d3691c !important; /* Slightly darker shade */
	}
	@media (max-width: 600px) {
		.btn_1 {
			width: 128px;
		}
		.btn_2 {
			width: 128px;
		}
	}
	@media (max-width: 360px) {
		.btn_1 {
			width: 90px;
		}
		.btn_2 {
			width: 90px;
		}
	}
	.ant-result-title {
		color: rgba(0, 0, 0, 0.85);
		font-size: 20px;
		line-height: 1.8;
		text-align: center;
	}
	.pro {
		.ant-input {
			height: 20px !important;
		}
	}
	.ant-result-subtitle {
		color: rgba(0, 0, 0, 0.45);
		font-size: 16px;
		line-height: 1.6;
		text-align: center;
	}
	.ant-input- .change_Password {
		padding: 20px 0px 50px 0px;

		.ant-input-affix-wrapper {
			position: relative;
			display: inline-block;
			height: 43px;
			width: 100%;
			min-width: 0;
			padding: 4px 11px;
			color: rgba(0, 0, 0, 0.85);
			font-size: 16px;
			line-height: 1.5715;
			background-color: #fff;
			background-image: none;
			border: 1px solid #d9d9d9;
			border-radius: 2px;
			transition: all 0.3s;
			display: inline-flex;
		}

		.ant-input-affix-wrapper > input.ant-input {
			font-size: 16px;
			padding: 0;
			border: none;
			outline: none;
		}

		.ant-form-item-label > label {
			padding-top: 6px;
			position: relative;
			display: inline-flex;
			align-items: center;
			height: 32px;
			color: rgba(102, 102, 102, 100);
			font-size: 18px;
		}
	}

	.ant-modal-title {
		// border-left: 7px solid rgb(0, 55, 120, 1);
		padding-left: 17px;
		font-size: 20px;
	}

	.ant-modal-body {
		padding: 24px;
	}

	.ant-form {
		border-bottom: 1px solid #f0f0f0;
	}

	.ant-result {
		border-bottom: 1px solid #f0f0f0 !important;
	}
}

.layout_container {
	.ant-layout-header {
		width: 100%;
		box-shadow: 0px 2px 12px 0px rgba(40, 57, 94, 0.11);
		height: auto;
		line-height: unset;
		padding: 0;
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
	}

	.layout_page_header {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.logo {
			width: 137px;
			height: 31px;
			margin-right: 40px;
		}
		@media (max-width: 480px) {
			.logo {
				width: 120px;
				height: 31px;
				margin-right: 15px;
			}
		}
		.header_rows {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
		}

		.left_content {
			padding: 22px 72px 22px 23px;
		}
		@media (max-width: 480px) {
			.left_content {
				padding: 16px 0px 0px 0px;
			}
		}
		.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
			background-color: #eaebef;
			color: #8992a6;
		}

		.rows_search {
			.ant-input-affix-wrapper {
				width: 35vw;
				height: 40px;
				border-radius: 28px;
				overflow: hidden;
				margin: 0px 30px 0px 0px;
				padding: 0 32px;
				// background-color: rgba(247, 249, 251, 0.5);
				background-color: #eaebef;
				border: 1px solid #9ca4b5;
			}

			.ant-input-group {
				.ant-input-group-addon:last-child {
					display: none;
				}
			}
		}

		.help_doc {
			margin: 0 40px;
			cursor: pointer;
		}

		.suffix_icon {
			font-size: 20px;
			vertical-align: bottom;
		}

		.help_tips {
			font-size: 16px;
			color: #999;
		}

		.login_con {
			min-width: 200px;
			height: 100%;
			padding: 5px 22px 0px 10px;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			@media screen and (max-width: @mobile) {
				justify-content: flex-end;
			}

			.login_user_icon {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				width: 56px;
				height: 56px;
				background-color: #f5f5f5;
				border-radius: 50%;
				overflow: hidden;
			}

			.login_user_name {
				margin: 0 20px;
				font-size: 16px;
				color: #333;
			}
		}
		@media (max-width: 550px) {
			.login_con {
				min-width: unset !important;
				margin-top: 5px;
			}
		}
		@media (max-width: 550px) {
			.login_user_name {
				overflow-x: scroll; /* Enable horizontal scrolling */
				white-space: nowrap; /* Prevent line breaks */
				width: 80px;
				// max-width: 80px;
				// white-space: nowrap;
				// overflow: visible; /* Shows any overflow content */
				// text-overflow: clip;
			}
		}
		.login_con:hover {
			background-color: rgba(0, 0, 0, 0.025);
		}
	}

	.layout_main {
		.overflowY {
			overflow: hidden;
		}
		.ant-menu {
			background: none;
		}
		.ant-menu-item {
			height: 45px;
			// margin-top: 6px;
			margin-bottom: 6px;
		}
		.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
			padding: 0 calc(50% - 22px / 2) !important;
			height: 48px !important;
		}
		.ant-menu.ant-menu-inline-collapsed
			> .ant-menu-submenu
			> .ant-menu-submenu-title {
			padding: 0 calc(50% - 22px / 2) !important;
			height: 48px !important;
		}
		.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
			margin-bottom: 5px;
		}
		.ant-menu.ant-menu-inline-collapsed
			> .ant-menu-submenu
			> .ant-menu-submenu-title
			.ant-menu-item-icon {
			margin-bottom: 5px;
		}
		.ant-menu-submenu-title {
			line-height: 45px;
			height: 45px;

			// margin-top: 6px;
			margin-bottom: 6px;
		}

		.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
			margin-top: 5px;
		}
		.ant-menu-title-content {
			line-height: 45px;
			height: 45px;
		}

		// .ant-menu-inline.ant-menu-root
		// 	.ant-menu-submenu-title
		// 	> .ant-menu-title-content {
		// 	word-break: break-all;
		// 	overflow: hidden;
		// 	text-overflow: n;
		// }
		.ant-menu-item:hover {
			border-radius: 4px;
			background-color: #418dc8;
		}
		@media (max-width: 767px) {
			/* This is the breakpoint for mobile devices */
			.ant-menu-item {
				text-align: center;
			}
			.ant-menu-submenu {
				text-align: center;
			}
		}
		.ant-menu-inline {
			border-right: none;
		}
		.ant-menu-item-selected {
			border-radius: 4px;
			background-color: #418dc8;
		}
		.subMenu_icon {
			width: 34px;
			height: 32px;
		}
		.ant-layout-sider {
			background: transparent linear-gradient(180deg, #1272bc 0%, #024f91 100%)
				0% 0% no-repeat padding-box;
		}
		.ant-menu-vertical > .ant-menu-item {
			line-height: 0;
		}

		.ant-layout-sider-trigger {
			position: relative;
			background: #02aee8;
		}
	}
	@media (max-width: 767px) {
		/* This is the breakpoint for mobile devices */
		.ant-menu-title-content span {
			// margin-left: 0 !important;
			width: 0px !important;
		}
		.ant-menu-item span {
			// margin-left: 0 !important;
			text-align: center;
			// padding-left: 0 !important;
			// padding: auto !important;
			width: 0px !important;
			height: 0px !important;
		}
		.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
			// padding: 0 !important;
			text-align: center;
			// display: flex;
			// justify-content: center;
		}
	}
	// Footer
	.footer-container {
		display: flex;
		justify-content: center;
		width: 100%;
		padding-top: 4rem;

		.custom-footer {
			width: 100%;
			// position: fixed;
			background-color: #f7f7f7;
			padding: 30px 30px;
			height: fit-content;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.footer-links {
				width: 100%;
				display: flex;
				justify-content: flex-start !important;
				gap: 2.5rem;

				.footer-link {
					color: #656774;
					text-decoration: underline;
					font-size: 0.9rem;
					font-weight: 500;
				}
			}

			.footer-lower {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.footer-content {
					display: flex;
					justify-content: center;
					align-items: center;

					.footer-logo {
						margin-right: 2.5rem;
						width: 9rem;
						height: 2rem;
					}

					.footer-text {
						font-size: 0.8rem;
						color: #656774;
						text-align: left;
					}
				}

				.footer-copyright {
					font-size: 0.8rem;
					color: #656774;
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}
	}
	/* Media Queries for smaller screens */
	@media (max-width: 768px) {
		.custom-footer {
			padding: 10px 10px !important;
			flex-direction: column;
			align-items: center;

			.footer-links {
				gap: 0rem;
			}

			.footer-lower {
				flex-direction: column;
				align-items: center;

				.footer-content {
					flex-direction: column;
					align-items: center;

					.footer-logo {
						margin: 0 0 1rem 0;
					}
				}

				.footer-copyright {
					// margin-top: 1rem;
					text-align: center;
				}
			}
		}
	}

	@media (max-width: 480px) {
		.footer-link {
			font-size: 0.8rem;
			margin: 0;
			padding: 0;
		}

		.footer-text,
		.footer-copyright {
			font-size: 0.7rem;
		}
	}
}

.ant-breadcrumb-separator {
	margin: 0 16px;
	vertical-align: text-bottom;
	color: #2e373c !important;
}

.breadcrumb_item {
	font-size: 16px;
	color: #02aee8;
}
.breadcrumb_item_active {
	color: #2e373c;
}
.breadcrumb_item_esports span {
	color: #02aee8 !important;
	cursor: pointer !important;
}

.breadcrumb-three {
	li:nth-child(2) {
		span {
			color: #02aee8;
			cursor: pointer !important;
		}
	}
}

@primary-color: #003778;@border-radius-base: 6px;@layout-header-background: #ffffff;@layout-sider-background: #003778;@menu-bg: #003778;@menu-inline-toplevel-item-height: 94px;@menu-item-height: 53px;@menu-item-color: #ffffff;@menu-highlight-color: #ffffff;@menu-inline-submenu-bg: #003778;@menu-item-active-bg: rgba(128, 160, 231, 0.3);@menu-popup-bg: #003778;@menu-item-active-border-width: 0px;