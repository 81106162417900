.newHomeContainer {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  zoom: 0.75;
}
@media screen and (max-width: 480px) {
  .newHomeContainer {
    padding: 0px 15px 0px 15px;
  }
}
.newHomeContainer ul,
.newHomeContainer li,
.newHomeContainer ol {
  list-style: disc;
}
.newHomeContainer .row_spaceBetween {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}
@media screen and (max-width: 480px) {
  .newHomeContainer .row_spaceBetween {
    flex-direction: column;
  }
}
.newHomeContainer .head {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 4rem;
  color: #003778;
  background: linear-gradient(to top, #ffffff, #f0faff);
  padding: 5rem;
}
@media screen and (max-width: 480px) {
  .newHomeContainer .head {
    flex-direction: column;
  }
}
.newHomeContainer .head .divider {
  height: 5rem;
  border-left: 2px solid #15181a53;
}
@media screen and (max-width: 480px) {
  .newHomeContainer .head .divider {
    height: 0;
    border-left: 0;
    border-bottom: 2px solid #15181a53;
    width: 5rem;
  }
}
.newHomeContainer .head .head_img {
  object-fit: contain;
  height: 90px;
  margin-right: 53px;
}
@media screen and (max-width: 480px) {
  .newHomeContainer .head .head_img {
    margin-right: unset;
  }
}
.newHomeContainer .head .imgTitle {
  display: flex;
  align-items: center;
  color: #2864a1;
  font-size: 35px;
  font-weight: 500;
  margin-left: 3rem;
}
.newHomeContainer .head .imgTitle p {
  margin: 0;
}
.newHomeContainer .carouselContainer {
  width: 85%;
  margin: auto;
  border-radius: 10px;
}
.newHomeContainer .carouselContainer .carouselTitle {
  font-size: 2.5rem;
  font-weight: 600;
  color: #2864a1;
  text-align: center;
}
.newHomeContainer .carouselContainer .ant-carousel .slick-dots-bottom {
  bottom: -70px;
}
.newHomeContainer .carouselContainer .btns {
  display: flex;
  justify-content: flex-end;
  margin: 24px 0;
}
.newHomeContainer .carouselContainer .btns .last-btn {
  margin-left: 1rem;
}
.newHomeContainer .carouselContainer .btns .btnIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #88acce;
}
.newHomeContainer .carouselContainer .btns .btnIcon img {
  width: 45%;
  height: 45%;
}
.newHomeContainer .carouselContainer .btns .btnIcon:hover {
  background-color: #ecf4fa;
}
.newHomeContainer .carouselContainer .homeCarousel {
  max-height: 490px;
  height: fit-content;
  margin: 0;
  padding: 50px 40px;
  color: #2e373c;
  text-align: left;
  background: #ecf4faa3;
  border-radius: 15px;
}
.newHomeContainer .carouselContainer .homeCarousel .carouselTop {
  display: flex;
  justify-content: space-between;
}
.newHomeContainer .carouselContainer .homeCarousel .carouselTop h2 {
  font-size: 2rem;
  cursor: pointer;
}
.newHomeContainer .carouselContainer .homeCarousel .carouselTop h2:hover {
  color: #f4791f;
}
.newHomeContainer .carouselContainer .homeCarousel .carouselTop .moduleTitle {
  color: #70727b;
  font-size: 1.5rem;
}
.newHomeContainer .carouselContainer .homeCarousel .carouselDesc {
  font-size: 1.5rem;
}
.newHomeContainer .carouselContainer .homeCarousel .carouselDesc ol,
.newHomeContainer .carouselContainer .homeCarousel .carouselDesc ul {
  margin-left: 25px;
}
.newHomeContainer .carouselContainer .custom-dots li {
  width: 20px;
  height: 20px;
  margin: 0 11px;
}
.newHomeContainer .carouselContainer .custom-dots li button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #dbebfc;
  border: none;
  opacity: 1;
}
.newHomeContainer .carouselContainer .custom-dots li.slick-active button {
  background-color: #02aee8;
}
.newHomeContainer .newHomeBox {
  margin-top: 33px;
}
.newHomeContainer .newHomeBox .head {
  padding-left: 41px;
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 400;
  color: #f47b20;
  text-align: left;
  height: 110px;
  border-radius: 10.67px 10.67px, 0px, 0px;
  background: linear-gradient(90deg, rgba(44, 103, 164, 0.08) 0%, rgba(208, 222, 237, 0.08) 100%);
}
.newHomeContainer .newHomeBox .head img {
  width: 40px;
  margin-right: 16px;
}
.newHomeContainer .newHomeBox .head span {
  cursor: pointer;
}
.newHomeContainer .newHomeBox .exclusivesBox {
  border-radius: 10.67px;
  border: 1.33px solid rgba(40, 57, 94, 0.2);
}
.newHomeContainer .newHomeBox .exclusivesBox .list {
  margin-top: 27px;
}
.newHomeContainer .newHomeBox .exclusivesBox .list ul {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.newHomeContainer .newHomeBox .exclusivesBox .list li {
  margin-left: 5%;
  width: 45%;
  font-size: 28px;
  font-weight: 500;
  color: #323c47;
  margin-bottom: 20px;
}
.newHomeContainer .newHomeBox .articlesBox {
  width: 49%;
  margin-top: 30px;
  border-radius: 10.67px;
  border: 1.33px solid rgba(40, 57, 94, 0.2);
}
@media screen and (max-width: 480px) {
  .newHomeContainer .newHomeBox .articlesBox {
    width: 100%;
  }
}
.newHomeContainer .newHomeBox .articlesBox .list {
  margin-top: 27px;
}
.newHomeContainer .newHomeBox .articlesBox .list ul {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.newHomeContainer .newHomeBox .articlesBox .list li {
  margin-left: 70px;
  font-size: 28px;
  font-weight: 500;
  color: #323c47;
  margin-bottom: 45px;
}
@media screen and (max-width: 480px) {
  .newHomeContainer .newHomeBox .articlesBox .list li {
    margin-left: 50px;
  }
}
.newHomeContainer .newHomeBox .gamesBox {
  width: 49%;
  margin-top: 30px;
  border-radius: 10.67px;
  border: 1.33px solid rgba(40, 57, 94, 0.2);
}
@media screen and (max-width: 480px) {
  .newHomeContainer .newHomeBox .gamesBox {
    width: 100%;
  }
}
.newHomeContainer .newHomeBox .gamesBox .list {
  margin-top: 27px;
}
.newHomeContainer .newHomeBox .gamesBox .list ul {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.newHomeContainer .newHomeBox .gamesBox .list li {
  margin-left: 70px;
  font-size: 28px;
  font-weight: 500;
  color: #323c47;
  margin-bottom: 25px;
}
@media screen and (max-width: 480px) {
  .newHomeContainer .newHomeBox .gamesBox .list li {
    margin-left: 50px;
  }
}
.newHomeContainer .newHomeBox .gamesBox .list span {
  font-size: 28px;
  font-weight: 700;
  color: #003778;
  margin-right: 10px;
}
.newHomeContainer .newHomeBox .financialBox {
  margin-top: 30px;
  width: 49%;
  border-radius: 10.67px;
  border: 1.33px solid rgba(40, 57, 94, 0.2);
}
@media screen and (max-width: 480px) {
  .newHomeContainer .newHomeBox .financialBox {
    width: 100%;
  }
}
.newHomeContainer .newHomeBox .financialBox .info {
  margin-top: 27px;
  margin-left: 50px;
  font-size: 28px;
  font-weight: 500;
  color: #323c47;
}
@media screen and (max-width: 480px) {
  .newHomeContainer .newHomeBox .financialBox .info {
    margin-left: 15px;
  }
}
.newHomeContainer .newHomeBox .financialBox .list {
  margin-top: 47px;
}
.newHomeContainer .newHomeBox .financialBox .list ul {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.newHomeContainer .newHomeBox .financialBox .list li {
  width: 40%;
  margin-left: 70px;
  font-size: 28px;
  font-weight: 500;
  color: #323c47;
  margin-bottom: 25px;
}
@media screen and (max-width: 480px) {
  .newHomeContainer .newHomeBox .financialBox .list li {
    margin-left: 50px;
  }
}
.newHomeContainer .newHomeBox .esportsBox {
  margin-top: 30px;
  width: 49%;
  border-radius: 10.67px;
  border: 1.33px solid rgba(40, 57, 94, 0.2);
}
@media screen and (max-width: 480px) {
  .newHomeContainer .newHomeBox .esportsBox {
    width: 100%;
  }
}
.newHomeContainer .newHomeBox .esportsBox .info {
  margin-top: 27px;
  margin-left: 50px;
  font-size: 28px;
  font-weight: 500;
  color: #323c47;
}
@media screen and (max-width: 480px) {
  .newHomeContainer .newHomeBox .esportsBox .info {
    margin-left: 15px;
  }
}
.newHomeContainer .newHomeBox .esportsBox .list {
  margin-top: 17px;
}
.newHomeContainer .newHomeBox .esportsBox .list ul {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.newHomeContainer .newHomeBox .esportsBox .list li {
  width: 40%;
  margin-left: 70px;
  font-size: 28px;
  font-weight: 500;
  color: #323c47;
  margin-bottom: 18px;
}
@media screen and (max-width: 480px) {
  .newHomeContainer .newHomeBox .esportsBox .list li {
    margin-left: 50px;
  }
}
.flexRow {
  flex-direction: row;
}
.card {
  border: 3px solid #2864a15c;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 100%;
  /* Maintain consistent height */
  background-color: #f9fcfe;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card .card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.card .card-content .top-part {
  flex-grow: 1;
  margin-bottom: 2.2rem;
}
.card .card-content .bottom-part {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 1rem;
}
.card-description {
  font-size: 1.5vw;
  color: #15181a;
  margin-bottom: 1rem;
  text-align: center;
  letter-spacing: 0px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-link {
  font-size: 1.5rem;
  color: #f4791f;
  text-decoration: underline;
  font-weight: normal;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: auto;
  /* Push to the bottom */
  align-items: center;
  display: inline-flex;
}
.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}
.card-btm-link {
  margin-top: 5rem;
}
.card-link .linkIcon {
  transition: transform 0.4s;
  margin-left: 3px;
}
.card-link:hover .linkIcon {
  transform: translateX(10px);
}
.card-soon {
  color: #656774;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.trendHeader {
  color: #2864a1;
  margin-left: 50px;
  margin-top: 60px;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
}
@media screen and (max-width: 480px) {
  .trendHeader {
    margin-left: unset;
  }
}
.textOnly {
  font-size: 20px;
  font-style: italic;
  color: #f4791f;
  margin-right: 3rem;
}
.homeSelect {
  height: 50px !important;
  margin-right: 50px !important;
  margin-top: 70px !important;
}
.homeSelect .ant-select-selector {
  border: none !important;
  font-size: 1.5rem;
}
.homeSelect .ant-select-arrow {
  color: black !important;
}
.homeSelect .ant-select-selection-item {
  padding-right: 30px !important;
}
.headCollapse {
  color: #2e373c !important;
  font-weight: 500;
}
.textCollapse {
  color: #2e373c !important;
}
@media (min-width: 2500px) {
  .newHomeContainer {
    zoom: 0.7;
  }
}
@media (max-width: 500px) {
  .card-description {
    font-size: 1.7rem;
  }
}
@media (min-width: 500px) and (max-width: 995px) {
  .card-description {
    font-size: 1.5rem;
  }
}
@media (max-width: 995px) {
  .cardContainer {
    margin-bottom: 2rem;
  }
}
@media (min-width: 995px) {
  .card-description {
    font-size: 1.5rem;
  }
  .customCol {
    display: block;
    flex: 0 0 24%;
    max-width: 24%;
  }
}
