.homeContainer {
  width: 100%;
  background-color: #fff;
}
.homeContainer .homeContent .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 30px 40px;
  background-color: #fff;
}
.homeContainer .homeContent .body .bodyItem {
  display: flex;
  flex-direction: column;
  width: 498px;
  height: 720px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemTitle {
  margin-bottom: 20px;
  color: #333;
  font-size: 20px;
  font-weight: 600;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 40px 20px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .tip {
  position: absolute;
  left: -1px;
  top: -1px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left {
  display: flex;
  align-items: center;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left .img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left .img .pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left .name {
  margin-left: 16px;
  color: #333;
  font-size: 16px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .right .score {
  color: #f49420;
  font-size: 36px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .info {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 20px 0;
  background-image: linear-gradient(to bottom right, #f1f1f1, #fcfcfc);
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .info .left {
  display: flex;
  align-items: center;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .info .left .text2 {
  margin-left: 80px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemRank {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  font-size: 20px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .two {
  background-color: #d0d3dc;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .three {
  background-color: #f1bf78;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo .ItemImg {
  width: 32px;
  height: 32px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo .ItemImg .pic {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo .ItemName {
  width: 120px;
  margin-left: 10px;
}
.homeContainer .homeContent .footer {
  display: flex;
  padding-bottom: 100px;
  background-color: #fff;
  overflow: hidden;
}
.homeContainer .homeContent .footer .bgc {
  position: relative;
  z-index: 1;
  left: -130px;
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  background-image: linear-gradient(rgba(87, 160, 209, 0.1), rgba(44, 103, 164, 0.1));
}
.homeContainer .homeContent .footer .polyLine {
  display: flex;
  position: relative;
  margin: 100px 0 0 -530px;
  z-index: 999;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .info {
  color: #333;
  font-size: 36px;
  font-weight: 600;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .question {
  margin: 36px 0 50px 0;
  width: 478px;
  color: #666;
  font-size: 16px;
  line-height: 28px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type {
  display: flex;
  align-items: center;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left,
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left .l,
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right .l {
  width: 22px;
  height: 8px;
  margin-right: 20px;
  background-color: #5883f7;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left .r,
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right .r {
  color: #333;
  font-size: 16px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left {
  margin-right: 40px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right .l {
  background-color: #5dd29d;
}
.insightsFeedForEsportsBox .CompanyChartBox {
  min-height: 450px;
}
.insightsFeedForEsportsBox .CompanyChartBox .Chart_Run_Title {
  padding: 40px 80px;
}
.insightsFeedForEsportsBox .CompanyChartBox .Chart_Run_Title span,
.insightsFeedForEsportsBox .CompanyChartBox .Chart_Run_Title p {
  color: #999999;
  font-size: 24px;
  font-weight: lighter;
}
.insightsFeedForEsportsBox .CompanyChartBox .Chart_Run_Title p::before {
  content: "●";
}
.insightsFeedForEsportsBox .CompanyChartBox .Chart_Run_Title span {
  display: block;
}
.Organizations_Box .Organizations_Statistic_Box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
}
.Organizations_Box .Organizations_Statistic_Box .Organizations_Statistic_Card {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Organizations_Box .Organizations_Statistic_Box .Statistic_Card_Sub {
  margin-top: 20px;
  text-align: center;
  padding-left: 20px;
}
.Organizations_Box .Organizations_Statistic_Box > div:nth-child(2) {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 60%;
}
.Organizations_Box .Organizations_Statistic_Box > div:nth-child(2) > div:nth-child(1) {
  border-bottom: 1px solid #fafbfc;
}
.Tournament_Info_Link a {
  font-size: 20px;
  text-decoration: underline;
  color: #2e65dd;
}
.Tag_List {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
}
.Tag_List .Tag_Item {
  font-size: 1.3rem;
  width: fit-content;
  margin: 10px 0;
  padding: 5px 10px;
  cursor: pointer;
}
.tournament-container {
  width: 98%;
  margin: 0 auto;
  padding: 20px;
}
.tournament-container .title {
  font-size: 3rem;
  font-weight: 500;
  color: #2e373c;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .tournament-container .title {
    font-size: 1.5rem;
  }
}
.tournament-container .game-title,
.tournament-container .org-title {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 500;
}
.tournament-container .game-title {
  background-color: #def4e7;
  color: #19ae54;
}
.tournament-container .game-title:hover {
  color: white;
  background-color: #19ae54;
}
.tournament-container .website-link {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 1rem;
  color: #02aee8;
  font-weight: normal;
  text-decoration: none;
}
.tournament-container .website-link img {
  margin-right: 5px;
}
.tournament-container .website-link:hover {
  text-decoration: underline;
}
.tournament-container .details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .tournament-container .details {
    flex-direction: column;
    gap: 5px;
  }
}
.tournament-container .details .left-column,
.tournament-container .details .right-column {
  width: 49%;
  padding: 30px 40px;
  background-color: rgba(236, 244, 250, 0.55);
  border-radius: 8px;
  border-left: 8px solid #02aee8;
}
.tournament-container .details .left-column .item-label,
.tournament-container .details .right-column .item-label,
.tournament-container .details .left-column .item-value,
.tournament-container .details .right-column .item-value {
  display: inline-block;
}
.tournament-container .details .left-column .item-label,
.tournament-container .details .right-column .item-label {
  width: 35%;
  color: #70727b;
}
.tournament-container .details .left-column .item-value,
.tournament-container .details .right-column .item-value {
  width: 65%;
  font-weight: bold;
  color: #2e373c;
}
.tournament-container .details .left-column .hostsContainer,
.tournament-container .details .right-column .hostsContainer {
  display: flex;
}
.tournament-container .details .left-column div,
.tournament-container .details .right-column div {
  margin-bottom: 10px;
  font-size: 1.1rem;
}
.tournament-container .details .left-column div strong,
.tournament-container .details .right-column div strong {
  font-weight: bold;
  margin-right: 5px;
}
@media (max-width: 768px) {
  .tournament-container .details .left-column,
  .tournament-container .details .right-column {
    width: 100%;
    padding: 30px 10px;
  }
}
.tournament-container .details .hosts {
  display: inline-block;
  width: 70%;
}
.tournament-container .details .hosts .org-title {
  background-color: #f1dff1;
  color: #9e219e;
  font-size: 0.9rem;
}
.tournament-container .details .hosts .org-title:hover {
  color: white;
  background-color: #9e219e;
}
