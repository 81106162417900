@import "../../index.less";

.GridBox {
	padding: 25px 30px;
	// background: url(../../assets/images/backgroupimg.png) top center no-repeat
	// 	#fff;
	// background-size: 100vw auto;
	background-size: 100%;
	@media (max-width: 768px) {
		padding: 10px;
	}
	.ant-table-thead > tr > th {
		background: rgb(240, 239, 239);
		// color: #999;
	}

	.Home_Title {
		color: rgb(68 96 157);
		font-size: 28px;
		margin: 0;
		margin-bottom: 12px;
	}

	.RowBox {
		.box_One {
			// width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
		}

		// .ant-table-cell {
		// 	background: none !important;
		// }

		.ant-table-thead
			> tr
			> th:not(:last-child):not(.ant-table-selection-column):not(
				.ant-table-row-expand-icon-cell
			):not([colspan])::before {
			width: 0;
		}

		.HomeBox {
			border-radius: 8px;
			border: 1px solid rgba(40, 57, 94, 0.2);
			overflow: hidden;
			ul.Lft_Box {
				> li {
					// margin-bottom: 20px;
					min-height: 80px;
					padding-bottom: 10px;

					.ant-typography {
						color: #333;
						font-weight: bold;
					}

					span {
						font-weight: lighter;
						// display: inline-block;
					}

					p {
						color: #333;
						font-size: 18px;
						/* word-break: break-all;
						margin-bottom: 10px;
						overflow:hidden;
						text-overflow:ellipsis;
						 white-space:nowrap;
						-webkit-box-orient: vertical;
   						 -webkit-line-clamp: 2;  */
						overflow: hidden;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
						box-orient: vertical;
					}

					p:nth-child(2) {
						line-height: 1em;
						min-height: 2.5em;
						font-weight: lighter;
						margin-bottom: 0px;
						font-size: 16px;
					}
				}
			}

			.home_games_box {
				li {
					.home_games_Lst {
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						// height: 100px;
						// line-height: 100px;
						padding: 5px 15px;

						div:nth-child(1) {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							width: 45%;

							.home_games_list_li_ico {
								width: 80px;
								height: 80px;
								overflow: hidden;
								border-radius: 8px;
								background-color: #efefef;
								border: 1px solid rgba(40, 57, 94, 0.1);
								background-size: 100% auto;
								background-position: center center;
								background-repeat: no-repeat;
							}

							div {
								margin-left: 10px;

								p {
									color: rgba(51, 51, 51, 100);
									font-size: 16px;
									text-align: left;
									font-family: HelveticaNeue-Medium;
								}
							}
						}

						> span {
							width: 25%;
							color: rgba(128, 160, 231, 100);
							font-size: 16px;
							text-align: left;
							font-family: HelveticaNeue-Medium;
						}

						div:nth-child(3) {
							width: 30%;
							color: rgba(51, 51, 51, 100);
							font-size: 16px;
							text-align: right;
							font-family: HelveticaNeue-Medium;
						}

						p {
							margin-bottom: 0;
						}
					}

					li:hover {
						background-color: #e4ecfb;
					}
				}
			}
		}

		.width_Max_Box {
			// 这里是首页Insight的样式
			.Insights_title {
				// padding: 30px;
			}

			ul {
				li:hover {
					background-color: #f2f5fc;
				}

				li {
					.Home_Insight_lst {
						display: flex;
						justify-content: space-between;
						align-items: center;

						/*   >img {
            width: 120px;
            height: 120px;
          } */

						.insght_pic {
							// width:120px;
							// background-image: url(images/QQ图片20181203100842.jpg);
							// display: inline-block;
							background-repeat: no-repeat;
							/*背景图像不平铺*/
							background-position: center;
							/*图片居中显示*/
							background-size: cover;
							/*隐藏不需要显示的图片*/
							height: 120px;
							width: 120px;
							border-radius: 8px;
							overflow: hidden;
						}

						.insight_list_cont {
							width: calc(100% - 130px);

							h3 {
								display: flex;
								justify-content: space-between;
								align-items: center;

								div {
									font-size: 18px;
									display: flex;
									justify-content: flex-start;
									align-items: center;

									span {
									}

									div {
									}
								}

								.insight_list_date_style {
									// float: right;
								}
							}
						}

						.Tag_List {
							margin-bottom: 10px;
						}

						.insght_lst_info {
						}
					}
				}
			}

			// width:100%;
		}
	}

	// 这里是首页esport的样式
	.Esport_Tabel {
		background: none;
		// width:50%;
	}

	.RM_Tabel {
		width: 100%;

		td.ant-table-cell {
			height: 76px;
		}
	}

	// 这里是ISBN样式
	.home_Card_Row {
		display: flex;
		justify-content: space-between;

		.home_Card_Box {
			border: none;
			width: calc(25% - 5px);

			> div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 239px;
				border-radius: 2px;
				overflow: hidden;
				text-align: center;

				div:nth-child(2) {
					width: 60%;
					text-align: center;
				}

				.ant-statistic-title {
					font-size: "24px";
				}

				.ant-statistic {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}
		}

		> div:nth-child(1) {
			// background-color: #19FF9898;
			background-color: rgba(255, 152, 152, 0.1);
		}

		> div:nth-child(2) {
			background-color: rgba(109, 65, 221, 0.1);
		}

		> div:nth-child(3) {
			background-color: rgba(234, 148, 25, 0.1);
		}

		> div:nth-child(4) {
			background-color: rgba(128, 160, 231, 0.1);
		}
	}

	//  这里是Streaming Tracker样式
	.footer_Mod_Box {
		.footer_Mod_Label {
			padding: 40px;
			background-color: #fcfbfb;

			.title {
				display: flex;
				justify-content: space-between;
			}

			.title_span {
				font-size: 18px;
				font-weight: 600;
			}

			.date_span {
				text-align: right;
				color: #999999;
				font-size: 16px;
			}

			h3 {
				font-size: 18px;
				color: #28395e;
				font-weight: bold;
				margin-bottom: 18px;

				span {
					float: right;
					color: #999;
					font-size: 16px;
					font-weight: lighter;
				}
			}

			.Niko_Index_Box {
				display: flex;
				align-items: center;
				margin: 0 auto;
				width: 300px;
				justify-content: space-between;
				padding: 9px 0;

				.Niko_Index_Text_Box {
					margin: 0;
					font-size: 22px;
					font-weight: bold;
					text-align: left;
				}
			}
		}
	}
}

.SearchList_Box {
	.Tab_box {
		padding: 0 30px;

		.BlogBox {
			padding: 0;
			width: 100%;
		}
	}
}

.Global_title {
	margin-bottom: 0 !important;
}

.SearchList_Box {
	min-height: 90vh;

	.ant-table {
		border: 0 !important;
	}

	.ant-table-thead {
		background: none !important;
	}
}

.New_Home_Box {
	.New_Home_Banner_Box {
		.slick-slide {
			img {
				width: 100%;
				max-height: 396px;
				object-fit: contain;
			}
		}

		.slick-list {
			border-radius: 5px;
			overflow: hidden;
		}

		.ant-carousel {
			.slick-dots-bottom {
				// bottom: -50px;
				bottom: 0;
			}

			.slick-dots {
				position: relative;
				margin: 35px 0;

				li.slick-active {
					width: 50px;

					button {
						background: rgb(18, 66, 129);
					}
				}

				li {
					width: 18px;

					button {
						background: #d8d8d8;
						opacity: 1;
					}
				}
			}
		}
	}

	.News_Box {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.News_lft_box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 425px;
			// width: calc(100% - 535px - 50px);

			.News_list_box {
				width: calc(34% - 35px);

				ul {
					li {
						h3 {
							margin-top: 30px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;

							.Type_span {
								font-weight: 400;
								margin-right: 10px;
							}

							a {
								font-size: 20px;
								color: #333;
								font-weight: 700;
							}
						}

						> div {
							min-height: 5em;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							font-weight: 400;
							font-size: 16px;
						}
					}
				}
			}
		}

		.News_rgt_box {
			width: 535px;
			height: 425px;

			.News_Gamelist_box {
				ul {
					li {
						margin-top: 4px;

						a {
							display: flex;
							justify-content: space-between;
							align-items: flex-start;
							font-size: 16px;
							font-weight: 600;
							color: #333;
							width: 100%;
							// height: 60px;
							padding: 5px;

							img {
								width: 60px;
								height: 60px;
								border-radius: 5px;
								overflow: hidden;
								object-fit: contain;
							}

							> div:nth-child(1) {
								width: 60%;
							}

							> div:nth-child(2) {
								width: 20%;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 1;
								-webkit-box-orient: vertical;
							}

							> div:nth-child(3) {
								width: 20%;
								text-align: right;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 1;
								-webkit-box-orient: vertical;
							}

							> div {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								font-size: 16px;
								font-weight: 700;

								p {
									height: 25px;
									overflow: hidden;
								}

								> div {
									margin-left: 20px;
									height: 60px;

									p {
										margin-bottom: 0px;
										color: #333;
									}
								}
							}

							> div.Home_Gamelist_Color {
								font-weight: 500;
							}

							> div.Home_Gamelist_platform {
								font-size: 12px;
								color: #333;
								font-weight: 500;
								line-height: 25px;
							}
						}

						a:hover {
							background-color: #f5f5f5;
						}
					}
				}
			}
		}
	}

	.New_Home_Stream_Box,
	.New_Home_RM_Box,
	.New_Home_ISBN_Box,
	.New_Home_Esport_Box,
	.Games_List_box {
		.New_Home_Title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 25px 0 25px 0;
			padding: 0 1px;

			.Insights_Slide_flip {
				a {
					padding: 0 22px;
					-moz-user-select: none;
					-webkit-user-select: none;
					-ms-user-select: none;
					-khtml-user-select: none;
					user-select: none;
				}
			}
		}

		.slick_slide {
			text-align: center;
			// width: 25vw !important;
			width: 100%;
			border: 1px solid #d3d3d3;
			border-radius: 4px;
			margin-right: 30px;

			.Game_Slider_Img {
				img {
					width: 100%;
					height: 203px;
					object-fit: contain;
					padding: 25px 25px 0 25px;
				}
			}

			.New_Home_Game_date {
				text-align: right;

				span {
					font-size: 12px;
					line-height: 10px;
					padding: 5px 5px 6px;
					display: inline-block;
					border-bottom: 3px solid rgb(0, 55, 120);
					color: #999999;
				}
			}

			.New_Home_Game_info {
				color: #333;
				padding: 10px 25px 25px 25px;

				h3 {
					font-size: 20px;
					overflow: hidden;
					height: 30px;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;

					a {
						color: #333;
					}
				}

				.New_Home_Game_info_content {
					overflow: hidden;
					font-size: 16px;
				}

				.New_Home_Game_Taglist {
					margin-top: 10px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;

					span.ant-tag {
						cursor: pointer;
					}
				}
			}
		}
	}

	.New_Home_RM_Box,
	.New_Home_Esport_Box {
		.New_Home_Title {
			margin-bottom: 0;
		}

		.New_Home_Esport_Label {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			.New_Home_RM_rgt,
			.New_Home_RM_lft,
			.New_Home_Esport_Lft {
				// width: calc(100% - 495px - 73px);

				.ant-table table {
					border-collapse: separate;
					border-spacing: 0px 20px;
				}

				.ant-table-tbody > tr > td,
				.ant-table-thead > tr > th {
					color: #003778;
					border: none;
					font-size: 16px;
				}

				.ant-table-tbody > tr {
					box-shadow: 0px 0px 4px rgb(214, 214, 214, 0.5);
					// margin-bottom: 20px;
					border-radius: 8px;
					height: 58px;

					// line-height: 58px;
				}

				.ant-table-tbody > tr > td {
					font-size: 14px;
					padding: 0 14px;
					color: #333;

					p {
						margin: 0;
					}
				}

				a.ant-typography,
				.ant-typography a {
					color: #2e65dd;
					text-decoration: underline;
				}
			}

			.New_Home_Esport_rgt {
				margin-left: 73px;
				// margin-top: 42px;
				// width: 495px;
				max-height: 481px;

				a.New_Home_Esport_rgt_AD_Box {
					// width: 495px;
					height: 100%;

					// line-height: 418px;
					img {
						width: 100%;
						max-height: 290px;
						margin-top: 20px;
						// object-fit: contain;
					}
				}
			}
		}
	}

	.New_Home_ISBN_Box {
		.New_Home_ISBN_Card_List {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			.New_Home_ISBN_Card_Lbale {
				width: calc(25% - 28px);
				height: 202px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 4px;

				img {
					width: 72px;
					height: 72px;
					object-fit: contain;
				}

				> div {
					margin-left: 16px;

					h2 {
						// margin: 0 0 15px 0;
						margin-bottom: 15px;
						color: #333;
						font-size: 32px;
						font-weight: 700;
						line-height: 38px;

						span {
							font-size: 16px;
						}
					}

					p {
						margin: 0;
						font-size: 16px;
						color: #999;
						line-height: 18px;
					}
				}
			}

			.New_Home_ISBN_Card_Lbale:nth-child(1) {
				background-color: rgb(250, 248, 244);
			}

			.New_Home_ISBN_Card_Lbale:nth-child(2) {
				background-color: rgb(241, 245, 255);
			}

			.New_Home_ISBN_Card_Lbale:nth-child(3) {
				background-color: rgb(237, 246, 247);
			}

			.New_Home_ISBN_Card_Lbale:nth-child(4) {
				background-color: rgb(230, 245, 249);
			}
		}
	}

	.New_Home_RM_Box {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.New_Home_RM_lft,
		.New_Home_RM_rgt {
			width: calc(50% - 20px);

			.New_Home_Title {
				margin-bottom: 0;
			}

			.ant-table table {
				border-collapse: separate;
				border-spacing: 0px 20px;
				min-height: 487px;
			}

			.ant-table-tbody > tr > td,
			.ant-table-thead > tr > th {
				color: #003778;
				border: none;
				font-size: 16px;
			}

			.ant-table-tbody > tr {
				box-shadow: 0px 0px 4px rgb(214, 214, 214, 0.5);
				// margin-bottom: 20px;
				border-radius: 8px;
				height: 58px;

				// line-height: 58px;
			}

			.ant-table-tbody > tr > td {
				font-size: 14px;
				padding: 0 14px;
				color: #333;

				p {
					margin: 0;
				}
			}

			a.ant-typography,
			.ant-typography a {
				color: #2e65dd;
				text-decoration: underline;
			}
		}

		.New_Home_RM_rgt {
			.New_Home_RM_Tabel {
				a {
					color: #2e65dd;
					text-decoration: underline;
				}
			}
		}
	}

	.New_Home_Stream_Box {
		padding-bottom: 50px;

		.New_Home_Title {
			margin-top: 22px;
		}

		.footer_Mod_Label {
			background: #fff;
			border: 0.94px solid rgba(210, 210, 210, 1);
			border-radius: 8px;
			padding: 24px 21px;

			h3 {
				font-size: 22.47px !important;
				color: rgba(40, 57, 94, 100);

				span {
					font-size: 18.73px;
					color: #999;
				}
			}

			.Niko_Index_Box {
				padding: 25px 0;
				margin: 0 auto;
				max-width: 330px;

				display: flex;
				justify-content: space-between;

				img {
					width: 65px;
					height: 72px;
					object-fit: contain;
				}

				.Niko_Index_Text_Box {
					font-size: 37.45px !important;
					// width: 50%;
					padding-left: 30px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
			}
		}
	}
}

@primary-color: #003778;@border-radius-base: 6px;@layout-header-background: #ffffff;@layout-sider-background: #003778;@menu-bg: #003778;@menu-inline-toplevel-item-height: 94px;@menu-item-height: 53px;@menu-item-color: #ffffff;@menu-highlight-color: #ffffff;@menu-inline-submenu-bg: #003778;@menu-item-active-bg: rgba(128, 160, 231, 0.3);@menu-popup-bg: #003778;@menu-item-active-border-width: 0px;