* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}
#root,
body,
html {
  height: calc(100vh / 0.75) !important;
  overflow: hidden;
  /* Prevents vertical scrolling */
}
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none !important;
}
.custom-pagination .ant-pagination-total-text {
  order: 1;
  margin-right: 16px;
  margin-left: 32px;
  color: #70727b !important;
  padding-top: 2px;
}
.custom-class .ant-notification-notice-message {
  margin-left: 0px !important;
}
.sc .ant-message-notice-content {
  color: white !important;
  /* Green text color */
  font-weight: bold !important;
  background-color: green !important;
  /* Light green background */
  border: none !important;
  /* Green border */
  border-radius: 4px !important;
}
.ant-menu-title-content {
  font-size: 15px !important;
}
.custom-pagination .ant-pagination-options {
  order: 2;
}
.custom-pagination .ant-pagination-item {
  order: 0;
}
.ant-selec {
  padding-top: 0 !important;
}
.custom-pagination .ant-pagination-options .ant-select-selector {
  height: 32px !important;
  /* Set the height */
  min-height: 32px !important;
  padding: 0 8px;
  /* Adjust padding inside the select */
  font: normal normal medium 14px/32px Inter;
  /* Adjust font styling */
  line-height: 32px;
  /* Align text vertically */
  border: 1px solid #d9d9d9;
  /* Customize border */
  border-radius: 4px;
  /* Optional: round the corners */
}
.ant-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.ant-pagination-item {
  margin: 2px !important;
  padding: 2px !important;
  color: #2e373c;
  font: normal normal medium 14px/32px Inter;
  letter-spacing: 0px;
  text-align: center;
  border: none !important;
  border-radius: 5px !important;
  opacity: 1;
}
.ant-pagination-item:hover {
  color: white !important;
  background: #02aee8;
  cursor: pointer;
  /* Change cursor to pointer */
}
.ant-pagination-item-active {
  background: #02aee8;
  color: white !important;
  border-radius: 4px !important;
}
.ant-pagination-item-active a {
  color: white !important;
}
.ant-pagination-prev .ant-pagination-item-link {
  border: none;
}
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}
.ant-table-pagination-right {
  justify-content: flex-start !important;
}
.ant-pagination-options {
  position: absolute;
  right: 0;
}
.ant-pagination-options-quick-jumper {
  display: none;
}
.ant-pagination-item-link:hover {
  color: white !important;
  background-color: #0099cc !important;
  border-radius: 4px !important;
}
@media (max-width: 768px) {
  .custom-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* Allow items to wrap */
  }
  .ant-pagination-options {
    position: static !important;
    /* Change to static if absolute is causing issues */
    margin-top: 8px !important;
    /* Add space if necessary */
  }
  .custom-pagination .ant-pagination-options {
    display: flex;
    align-items: center;
  }
  .custom-pagination .ant-pagination-total-text {
    width: 50%;
    /* Make the total text take the full width */
    text-align: left;
    /* Align text to the left */
    margin-top: 5px;
    /* Add space below the total text */
    margin-right: 0;
    margin-left: 0;
  }
  .custom-pagination .ant-pagination-total-text {
    width: 48%;
    order: -2;
    /* Move total text above pagination items */
  }
  .custom-pagination .ant-pagination-options {
    order: -1;
    /* Move total text above pagination items */
    width: 49%;
    margin: 0;
    justify-content: flex-end;
    display: flex;
  }
  .custom-pagination .ant-pagination-item,
  .custom-pagination .ant-pagination-prev,
  .custom-pagination .ant-pagination-next {
    margin: 0 5px;
    /* Space between pagination items */
  }
  .custom-pagination .ant-pagination-options {
    margin-bottom: 10px;
    /* Space between options and total text */
  }
  .ant-pagination-options-quick-jumper {
    display: none;
  }
}
.applySearchBtn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 40px !important;
  height: 40px !important;
  margin: 0 30px 0 8px !important;
  background-color: #02aee8 !important;
  border: none !important;
  border-radius: 50% !important;
  transition: background-color 0.3s ease;
  /* Smooth transition */
}
.applySearchBtn:hover {
  background-color: #028fc2 !important;
  /* Darker shade for hover */
}
@media (max-width: 768px) {
  .applySearchBtn {
    margin: 0 10px 0 5px !important;
    /* Reduced margin for mobile */
  }
}
.searchBlog {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 3vw;
  height: 60px;
  margin-bottom: -20px;
  margin-top: 10px;
}
.compBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 3vw;
  height: 60px;
  margin-bottom: -20px;
  margin-top: 10px;
}
.customInput {
  width: 250px;
  height: 36px;
  margin-top: 0 !important;
  border: 1px solid #b6b6be !important;
}
@media (max-width: 400px) {
  .customInput {
    width: 200px;
    height: 36px;
    margin-top: 0 !important;
  }
}
.customInputBlog {
  width: 250px;
  height: 36px;
  margin-top: 28px !important;
}
.vanaDetails {
  margin: 0px 15rem;
}
@media (max-width: 768px) {
  .vanaDetails {
    margin: 0px 2rem;
  }
}
.areaRegionBox {
  display: flex;
}
@media (max-width: 768px) {
  .areaRegionBox {
    flex-direction: column;
    gap: 10px;
  }
}
@media (max-width: 768px) {
  .searchBlog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 60px;
  }
  .compBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 60px;
  }
  .customInputBlog {
    width: 200px;
    height: 36px;
    margin-top: 28px !important;
  }
}
@media (max-width: 345px) {
  .searchBlog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 60px;
  }
  .customInputBlog {
    width: 150px;
    height: 36px;
    margin-top: 28px !important;
  }
}
.primarySearch {
  align-items: center;
  min-width: 74px !important;
  min-height: 32px !important;
  color: #ffffff !important;
  padding-top: 6px !important;
  background-color: #f47b23 !important;
  border: none !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
}
.primarySearch:hover {
  background-color: #d3691c !important;
  /* Slightly darker shade */
}
.secReset {
  display: flex !important;
  align-items: center;
  min-width: 74px !important;
  min-height: 32px !important;
  color: #2864a1 !important;
  padding-top: 7px !important;
  background-color: #ffffff !important;
  border: 1px solid #88acce !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}
.secReset:hover {
  color: #2864a1 !important;
  border: #2864a1 2px solid !important;
}
.primaryBig {
  align-items: center;
  min-width: 74px !important;
  min-height: 32px !important;
  color: #ffffff !important;
  background-color: #f47b23 !important;
  padding-top: 6px !important;
  border: none !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
}
.primaryBig:hover {
  background-color: #d3691c !important;
  /* Slightly darker shade */
}
.secBig {
  display: flex !important;
  align-items: center;
  min-width: 74px !important;
  min-height: 32px !important;
  color: #2864a1 !important;
  background-color: #ffffff !important;
  border: 1px solid #88acce !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  padding-top: 6px !important;
}
.secBig:hover {
  color: #2864a1 !important;
  border: #2864a1 2px solid !important;
}
.ant-select-dropdown {
  top: 60px !important;
  left: 0 !important;
}
@media (max-width: 768px) {
  .ant-layout {
    padding-top: 0px !important;
  }
}
.ant-picker-range {
  min-height: 46px !important;
}
.ant-picker-dropdown {
  top: 53px !important;
  left: -60px !important;
}
.ant-layout-sider {
  height: 100% !important;
  flex: 0 0 252px !important;
  max-width: 252px !important;
  min-width: 252px !important;
  width: 252px !important;
}
.ant-layout-sider-collapsed {
  width: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;
}
.ant-layout-sider-children {
  overflow: hidden !important;
  /* Hides the scrollbar */
}
.ant-layout.ant-layout-has-sider {
  height: 100vh;
}
.ant-tooltip {
  margin-top: calc(890px / 0.75) !important;
  margin-left: calc(800px / 0.75) !important;
}
.ant-dropdown {
  width: fit-content !important;
  top: 90px !important;
  /* Adjusts the vertical position */
  right: 1% !important;
  /* Positions it 10% from the right side */
  left: auto !important;
  /* Resets the left property */
}
.ant-select-selector {
  min-height: 46px !important;
  display: flex;
  align-items: center;
  border: 1px solid #b6b6be !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}
.ant-picker {
  min-height: 46px !important;
  display: flex;
  align-items: center;
  border: 1px solid #b6b6be !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}
.ant-picker .ant-picker-suffix .anticon {
  transition: color 0.3s ease;
  /* Smooth transition for the color change */
}
.ant-picker:hover .ant-picker-suffix .anticon {
  color: #02aee8;
  /* Change the icon color to red on hover */
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
}
.ant-menu-submenu-popup {
  margin-top: 1100px;
  margin-left: 1070px;
}
.ant-layout {
  background-color: #fff;
  display: flex;
  width: 100%;
  min-height: 100%;
}
@media (max-width: 600px) {
  .ant-picker-panels {
    display: inline-flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    direction: ltr !important;
  }
}
@media (max-width: 600px) {
  .contavtText {
    display: none;
  }
}
.primarySubmit {
  align-items: center;
  min-width: 102px !important;
  min-height: 40px !important;
  color: #ffffff !important;
  background-color: #f47b23 !important;
  border: none !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
}
.primarySubmit:hover {
  background-color: #d3691c !important;
  /* Slightly darker shade */
}
.primarySubmit:disabled {
  color: #fad2b5 !important;
  background-color: #fff2e8 !important;
  border: none !important;
  pointer-events: none;
  /* Prevents hover styles from being applied */
}
/* Prevent hover styles when disabled */
.primarySubmit:disabled:hover {
  color: #d8d8d8 !important;
  background-color: #ffffff !important;
  border: none !important;
}
.secCancel {
  align-items: center;
  min-width: 102px !important;
  min-height: 40px !important;
  color: #2864a1 !important;
  background-color: #ffffff !important;
  border: 1px solid #88acce !important;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}
/* Disabled state styles */
.secCancel:disabled {
  color: #d8d8d8 !important;
  border: none !important;
  pointer-events: none;
  /* Prevents hover styles from being applied */
}
/* Hover state styles */
.secCancel:hover {
  color: #2864a1 !important;
  border: #2864a1 2px solid !important;
}
/* Prevent hover styles when disabled */
.secCancel:disabled:hover {
  color: #d8d8d8 !important;
  background-color: #ffffff !important;
  border: none !important;
}
.overflowText {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.wd25 {
  max-width: calc(25% - 5px);
}
.wd33 {
  width: calc(33.33% - 10px);
}
.wd50 {
  max-width: calc(50% - 10px);
}
.wd66 {
  width: calc(66.66% - 10px);
}
.wd100 {
  width: 100%;
}
.mt50 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt30 {
  margin-top: 30px;
}
.mt20 {
  margin-top: 20px;
}
.mt15 {
  margin-top: 15px;
}
.ml20 {
  margin-left: 20px;
}
.pd30 {
  padding: 30px;
}
.pd20 {
  padding: 20px;
}
.pd22 {
  padding: 22px;
}
.DetailBg {
  background: url(../../assets/images/backgroupimg.png) top center no-repeat #fff;
  background-size: 100%;
}
.BorderBox {
  border: 1px solid rgba(40, 57, 94, 0.2);
  border-radius: 8px;
  overflow: hidden;
}
.Global_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Global_title img {
  width: 16px;
  height: 16px;
}
.dark_row {
  background-color: #fbfcfe;
}
.BG_white {
  background-image: none;
  background-color: white;
}
.FCblue {
  color: #7297e8;
}
.Global_Jb_title {
  background-image: linear-gradient(to right, #eff4f8, #fff);
  background-color: #44609d;
}
.linkUnderline {
  text-decoration: underline;
  color: #2e65dd;
}
.textcolor1 {
  color: #80a0e7 !important;
}
.textcolor2 {
  color: #ea9419 !important;
}
.textcolor3 {
  color: #2e65dd !important;
}
.textcolor4 {
  color: #6d41dd !important;
}
.textcolor5 {
  color: #80a0e7 !important;
}
.rank {
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
}
.rank1,
.rank2,
.rank3 {
  margin: 0 auto;
  width: 23px;
  height: 23px;
  font-size: 0;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.rank1 {
  background-image: url("../../assets/menu/rank1.png");
}
.ant-menu-submenu-title {
  white-space: pre-wrap;
  /* CSS to allow wrapping */
}
.rank2 {
  background-image: url("../../assets/menu/rank2.png");
}
.rank3 {
  background-image: url("../../assets/menu/rank3.png");
}
h2.ant-typography,
.ant-typography h2 {
  line-height: 1 !important;
}
.ant-menu-submenu-popup > .ant-menu {
  border-radius: 4px !important;
  padding: 5px !important;
  background-color: #0d67af;
  margin-left: 8px !important;
}
.ant-menu-submenu-popup > .ant-menu > li {
  height: 50px !important;
  line-height: 50px !important;
  margin-top: 0 !important;
  margin-bottom: 2px !important;
}
.ant-menu-submenu-popup > .ant-menu > li:hover {
  border-radius: 4px;
  background-color: #418dc8;
}
.ant-menu-submenu > div:hover {
  border-radius: 4px;
  background-color: #418dc8;
}
.pdb40 {
  padding-bottom: 40px;
}
.ant-empty {
  padding: 40px 0;
}
.New_Home_Title {
  font-size: 32px;
  color: #003778;
  font-weight: 650;
}
.box_show {
  box-shadow: #6b5d5d 0 2px 6px 0;
  border-radius: 5px;
  padding: 35px;
}
@media (max-width: 800px) {
  .active .rich_pdf .controls {
    margin-top: 300px;
  }
}
@media (max-width: 2560px) {
  .New_Home_Box .New_Home_Stream_Box .footer_Mod_Label .Niko_Index_Box .Niko_Index_Text_Box {
    font-size: 30px !important;
  }
  .New_Home_Box .New_Home_Stream_Box .footer_Mod_Label .Niko_Index_Box {
    justify-content: flex-start;
  }
  .New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img,
  .New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img {
    width: 600px;
    max-height: 450px !important;
    height: 100%;
    margin-left: -20px;
  }
}
@media (max-width: 1280px) {
  .ant-layout.layout_container .New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img,
  .ant-layout.layout_container .New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img {
    width: 450px !important;
    margin-left: 0px !important;
  }
}
@media (max-width: 1920px) {
  .ant-layout.layout_container .New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img,
  .ant-layout.layout_container .New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img {
    width: 450px !important;
    margin-left: 0px !important;
  }
}
html {
  zoom: 0.75;
}
.ant-input .ant-input-password {
  align-items: center;
  min-height: 30px !important;
  border: 1px solid #b6b6be !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}
#advanced_search_keyWord {
  margin-top: 0px !important;
}
.ant-input:focus {
  border-color: #4ad1ff !important;
  box-shadow: none !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
		.ant-select-customize-input
	) .ant-select-selector {
  box-shadow: none !important;
}
.ant-select-selection-placeholder {
  margin-top: 2px !important;
}
.main_main {
  /* Add these properties to make it scrollable */
  height: 125vh;
  /* Adjust based on your layout */
  overflow: auto;
  /* Enables scrolling */
}
@-moz-document url-prefix() {
  .ant-layout-content {
    -moz-transform: scale(0.9);
    -moz-transform-origin: center top;
    /* Add these properties to make it scrollable */
  }
  .ant-layout.layout_container .New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img,
  .ant-layout.layout_container .New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img {
    margin-left: 80px !important;
    width: 417px !important;
  }
}
.layout_container .ant-layout-header {
  z-index: 9999999;
}
.custom-tabs .ant-tabs-tab {
  border: none !important;
  padding: 8px 16px !important;
  margin: 10px 8px !important;
  color: black !important;
  /* Customize the text color */
  background-color: white !important;
  transition: color 0.3s ease, background-color 0.3s ease;
  /* Smooth transition for text color and background */
  position: relative;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #02aee8 !important;
  /* Customize the text color */
}
.ant-tabs-tab {
  font-size: 1.2rem !important;
}
@media (max-width: 768px) {
  .ant-tabs-tab {
    font-size: 1rem !important;
    margin-bottom: 20px !important;
  }
}
.custom-tabs .ant-tabs-tab-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  /* Customize the thickness of the border */
  background-color: #02aee8;
  /* Customize the border color */
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}
/* Apply a hover effect to show how the border moves */
.custom-tabs .ant-tabs-tab-active:hover::after,
.custom-tabs .ant-tabs-tab-active::after {
  transform: scaleX(1);
}
.custom-tabs .ant-tabs-tab:not(.ant-tabs-tab-active)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  /* Customize the thickness of the border */
  background-color: transparent;
  /* Hidden by default */
  transition: transform 0.3s ease-in-out;
  transform: scaleX(0);
}
.custom-tabs .ant-tabs-ink-bar {
  display: none !important;
  /* Ensure the ink bar is completely hidden */
  width: 0 !important;
  /* Prevent any potential rendering */
}
.ant-tabs-nav {
  margin-left: 26px !important;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border: none !important;
}
.custom-tabs .ant-tabs-tab:not(.ant-tabs-tab-active):hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  /* Same thickness as the active border */
  background-color: gray;
  /* Set the underline color to gray */
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
  transform: scaleX(1);
  /* Show the underline on hover */
}
.custom-tabs .ant-tabs-tab:not(.ant-tabs-tab-active)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  /* Customize the thickness of the border */
  background-color: transparent;
  /* Hidden by default */
  transition: transform 0.3s ease-in-out;
  transform: scaleX(0);
}
.custom-checkbox .ant-checkbox-inner {
  border-color: #707070;
}
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important;
  border-color: #02aee8 !important;
}
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #02aee8;
  /* This changes the checkmark symbol to black */
}
.custom-radio .ant-radio-inner {
  position: relative !important;
  top: 0 !important;
  left: 1px !important;
  margin-bottom: 5px;
  border-color: black;
}
/* Change the border and the selected symbol to red when the radio is checked */
.custom-radio .ant-radio-checked .ant-radio-inner {
  border-color: #02aee8 !important;
}
.custom-radio .ant-radio-checked .ant-radio-inner::after {
  background-color: #02aee8;
  /* This changes the inner circle (select symbol) to red */
}
.custom-radio .ant-radio-inner:hover {
  border-color: #02aee8;
  /* Change border color on hover */
}
.ant-radio-group {
  display: flex !important;
  align-items: start !important;
  justify-content: space-evenly !important;
}
.custom-radio-item {
  display: flex !important;
  align-items: center !important;
  /* Centers items vertically */
  justify-content: center !important;
}
.ant-switch-inner {
  color: #02aee8 !important;
}
.custom-switch-checked {
  background-color: white;
  /* Green when checked */
  border: 1px solid #b6b6be;
}
.ant-switch {
  min-width: 40px !important;
}
.custom-switch-unchecked {
  background-color: white;
  /* Green when checked */
  border: 1px solid #b6b6be;
}
.custom-switch-checked .ant-switch-handle {
  border-color: white;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
		.ant-select-customize-input
	) .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
		.ant-select-customize-input
	) .ant-select-arrow {
  color: #02aee8 !important;
}
.custom-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  /* Force the color to white */
  font-size: 12px;
  /* Adjust size as needed */
  background-color: #02aee8;
  /* Background color of the switch handle */
  border-radius: 50% !important;
  transform: translate(2%, -16%);
  content: "\2713";
}
.custom-switch-unchecked .ant-switch-handle::before {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  font-size: 12px;
  /* Adjust size as needed */
  background-color: #b6b6be;
  border-radius: 50% !important;
  transform: translate(-13%, -13%);
}
.custom-switch-unchecked .ant-switch-handle:hover::before {
  background-color: #70727b;
  /* Change to desired hover color */
}
.custom-switch-checked .ant-switch-handle {
  width: 21px;
  /* Adjust the width as needed */
  height: 22px;
  /* Adjust the height as needed */
}
.custom-switch-unchecked .ant-switch-handle {
  width: 21px;
  /* Ensure it's consistent when unchecked */
  height: 22px;
}
.purbleTag {
  cursor: pointer;
  color: #9e219e !important;
  background-color: #f1dff1;
  border: none !important;
  border-radius: 2 !important;
  padding: 2px 8px 3px 8px;
  transition: all 0.3s ease;
  /* Smooth transition */
  margin: 3px;
  max-width: 150px !important;
  margin-left: 0px !important;
  white-space: nowrap;
  /* Prevents the text from wrapping to the next line */
  overflow: hidden;
  /* Hides the overflowed text */
  text-overflow: ellipsis;
  /* Adds "..." to indicate overflow */
}
.ant-form-item-label > label {
  text-transform: uppercase;
}
.purbleTag:hover {
  background-color: #9e219e !important;
  /* Optional: change background on hover */
  color: white !important;
  /* Optional: change text color on hover */
}
.greenTag {
  cursor: pointer;
  color: #19ae54 !important;
  background-color: #def4e7;
  border: none !important;
  border-radius: 2 !important;
  padding: 2px 8px 3px 8px;
  transition: all 0.3s ease;
  /* Smooth transition */
  margin: 3px;
  max-width: 150px !important;
  white-space: nowrap;
  /* Prevents the text from wrapping to the next line */
  overflow: hidden;
  /* Hides the overflowed text */
  text-overflow: ellipsis;
  /* Adds "..." to indicate overflow */
  margin-left: 0px !important;
}
.greenTag:hover {
  background-color: #19ae54 !important;
  /* Optional: change background on hover */
  color: white !important;
  /* Optional: change text color on hover */
}
.MobileList .ant-collapse-item {
  background-color: #ecf4fa;
  /* Red background */
  border-radius: 5px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #02aee8 !important;
}
.custom-arrow {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #b6b6be;
  transition: transform 0.3s ease;
}
.panel-header {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  /* Adjust the height as needed */
}
.head {
  color: #2e373c !important;
  font-weight: 500;
}
.text {
  color: #2e373c !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  background-color: #f9fcfe;
}
.collapse-panel .ant-collapse-content > .ant-collapse-content-box {
  background-color: transparent;
}
.tournament-image {
  width: 32px !important;
  margin-right: 10px;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background-color: #f0faff !important;
}
.ant-table-tbody > tr > td:not(.ant-table-column-sort) {
  background-color: #f9fcfe !important;
  color: #999;
}
.ant-table-thead > tr > th {
  background-color: #ecf4fa !important;
  color: #2e373c;
}
.CompanyTableBox {
  border-radius: 8px;
  overflow: hidden;
  /* Ensures the border-radius applies properly */
}
.CompanyTableBox .ant-table {
  border-radius: 8px;
}
.CompanyTableBox .ant-table-thead > tr > th,
.CompanyTableBox .ant-table-tbody > tr > td {
  border-radius: 0;
  /* Prevents individual cells from having a border-radius */
}
.CompanyTableBox .ant-table-tbody tr td {
  border-bottom: 2px solid #b1c7dc !important;
  border-left: none !important;
  border-right: none !important;
}
.CompanyTableBox .ant-table-thead th {
  border-left: none !important;
  border-right: none !important;
}
.ant-table-column-sorter-up.active {
  color: #f4791f !important;
}
.ant-table-column-sorter-down.active {
  color: #f4791f !important;
}
.ant-table-cell span {
  color: #2e373c;
}
.ant-table-cell div {
  color: #2e373c;
}
.ant-table-cell {
  color: #2e373c !important;
}
.CompanyBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo {
  box-shadow: none !important;
  border: 1px solid #b1c7dc;
  border-radius: 8px;
  background-color: white;
  padding: 2px;
  width: 50px !important;
  height: 50px !important;
  margin-right: 15px !important;
}
.GameBox .CompanyTableBox .ant-table .Tabel_Company .Tabel_Company_Logo {
  box-shadow: none !important;
  border: 1px solid #b1c7dc;
  border-radius: 8px;
  background-color: white;
  padding: 2px;
  width: 50px !important;
  height: 50px !important;
  margin-right: 15px !important;
}
.Tabel_Company_Title {
  margin-left: 0 !important;
}
.Tabel_Company_Title p a {
  overflow: hidden;
  /* Hides overflowing text */
  text-overflow: ellipsis;
  /* Adds ellipsis (...) for truncated text */
  display: -webkit-box;
  /* Creates a flexible box for limiting text */
  -webkit-line-clamp: 3;
  /* Limits the number of visible lines */
  -webkit-box-orient: vertical;
  /* Sets the orientation of the box to vertical */
  word-wrap: break-word;
  /* Breaks long words to prevent overflow */
  max-width: 350px;
  /* Sets a maximum width */
}
.mainheader {
  margin-left: 50px;
  margin-top: 100px;
  color: #2e373c !important;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
}
.mainheader2 {
  margin-left: 50px;
  margin-top: 100px;
  color: #2864a1 !important;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: -10px;
}
.note {
  font-size: 1.2rem;
  font-weight: 600;
}
@media (max-width: 768px) {
  .note {
    word-wrap: break-word;
    /* Allows breaking long words */
    overflow-wrap: break-word;
    /* Ensures compatibility */
    white-space: normal;
    /* Breaks lines instead of keeping them on a single line */
  }
}
@media (max-width: 768px) {
  .mainheader2 {
    margin-left: 20px;
    margin-top: 50px;
    font-size: 32px;
    margin-bottom: -10px;
  }
}
.mainheader3 {
  margin-top: 100px;
  color: #2864a1 !important;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: -10px;
}
.blogvIEW {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .blogvIEW {
    justify-content: unset !important;
    padding-left: 30px;
  }
}
.subHeader {
  margin-left: 50px;
  /* margin-top: 20px; */
  color: #70727b;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 50px;
}
/* Mobile styles */
@media (max-width: 768px) {
  .mainheader {
    margin-left: 20px;
    /* Adjust margin for smaller screens */
    font-size: 30px;
    /* Smaller font size for mobile */
  }
  .subHeader {
    margin-left: 20px;
    font-size: 14px;
    /* Smaller font size for mobile */
    margin-bottom: 10px;
  }
}
.ant-tooltip-inner {
  background-color: gray;
  /* Change background color */
  color: white;
  /* Change text color */
  font-size: 14px;
  /* Customize font size */
  border-radius: 8px;
  /* Make the corners rounded */
  min-height: auto;
}
.ant-tooltip-arrow {
  display: none;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  border-radius: 4px !important;
  background-color: #418dc8 !important;
}
.ant-menu-item,
.ant-menu-submenu-title {
  white-space: unset !important;
  line-height: 48px !important;
}
.ant-table-column-sorters {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  /* This ensures the sort arrows are right next to the column header text */
  gap: 0 !important;
}
.ant-table-column-sorter {
  margin-left: 8px !important;
  /* Adjust the space between the header text and the sort arrows if needed */
}
.ant-table-column-title {
  display: inline-flex !important;
  align-items: center !important;
  gap: 0 !important;
  max-width: fit-content !important;
}
.custom-publisher-column .ant-table-column-sorters {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* This ensures the sort arrows are right next to the column header text */
  gap: 0 !important;
}
.ant-table-column-sorter {
  pointer-events: none !important;
  /* Disable tooltip appearance */
}
.ant-input-affix-wrapper {
  border-color: #d9d9d9;
  box-shadow: none !important;
  height: 48px !important;
  margin-top: 0px !important;
  transition: border-color 0.3s ease;
  /* Add transition for border */
}
.ant-input-affix-wrapper-focused {
  border-color: #4ad1ff !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #4ad1ff !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper > .ant-input {
  height: 35px !important;
  border: none !important;
}
.ant-input-affix-wrapper .ant-input-prefix {
  color: #d9d9d9;
  transition: color 0.3s ease;
  /* Smooth transition for the icon color */
}
.ant-input-affix-wrapper-focused .ant-input-prefix,
.ant-input-affix-wrapper:hover .ant-input-prefix,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover .ant-input-prefix {
  color: #4ad1ff !important;
}
.filterBtnSelected {
  width: 108px !important;
  height: 42px !important;
  padding-top: 6px;
  color: #2864a1 !important;
  background-color: white !important;
  border: 3px solid #4ad1ff !important;
  border-radius: 20px !important;
  transition: background-color 0.3s ease;
  /* Smooth transition */
}
.filterresetBtn {
  color: #2864a1 !important;
  width: 108px !important;
  height: 42px !important;
  border-radius: 20px !important;
  border: 1px solid #88acce !important;
  padding-top: 6px;
  transition: background-color 0.3s ease;
  /* Smooth transition */
  background-color: white !important;
}
.filterresetBtn:hover {
  background-color: #ecf4fa !important;
  /* Darker shade for hover */
}
.ant-col-24.ant-form-item-label {
  padding: 0 !important;
}
.filter-modal .ant-modal-content {
  border: 1px solid #b6b6be;
  border-radius: 8px;
  box-shadow: 0px 4px 8px #27313b43;
  opacity: 1;
}
.filter-modal .ant-modal-body {
  padding-top: 45px !important;
}
.ant-modal-close-x {
  font-size: 18px !important;
  color: #1f1f1f !important;
}
.listSearch {
  display: flex;
  justify-content: space-between;
  margin-bottom: -30px;
}
.topBox {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}
@media (max-width: 768px) {
  .listSearch {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-bottom: -10px;
  }
}
@media (max-width: 768px) {
  .checkTitle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start !important;
  }
}
@media (max-width: 768px) {
  .topBox {
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}
.exclusiveBox {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  align-items: center;
  height: 60px;
}
@media (max-width: 768px) {
  .exclusiveBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    height: 60px;
  }
}
.ant-form-item-explain-connected {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ant-form-item-explain-error {
  font-size: 0.8rem;
}
.IcafeBox {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding: 0 3vw !important;
  height: 60px !important;
  margin-bottom: -20px !important;
  margin-top: 10px !important;
}
.password {
  height: 60px !important;
  border: 1px solid #b6b6be !important;
  border-radius: 8px !important;
}
.LoginBox .ant-input-affix-wrapper > input.ant-input {
  border: none !important;
  margin-top: 0px !important;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 40px !important;
}
.customInputBlog {
  height: 45px !important;
  border: 1px solid #b6b6be !important;
  border-radius: 8px !important;
  width: 100% !important;
}
.anticon-search {
  font-size: 20px !important;
}
.anticon svg {
  width: 18px !important;
  height: 18px !important;
}
.layout_container .layout_main .ant-layout-sider-trigger:hover {
  /* Add your hover styles here */
  background-color: #0297c9;
  /* Example: change background color */
  cursor: pointer;
  /* Change cursor to pointer */
  transition: background-color 0.3s ease;
  /* Smooth transition */
}
.layout_container .layout_main .ant-layout-sider-trigger {
  width: 100% !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: black !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
.greyTag {
  max-width: 150px !important;
  margin: 3px !important;
  margin-left: 0px !important;
  padding: 2px 8px 3px 8px !important;
  overflow: hidden;
  /* Hides the overflowed text */
  color: #70727b !important;
  white-space: nowrap;
  /* Prevents the text from wrapping to the next line */
  text-overflow: ellipsis;
  /* Adds "..." to indicate overflow */
  background-color: #efefef !important;
  border: none !important;
  border-radius: 6px !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  /* Smooth transition */
}
.greyTag:hover {
  color: #efefef !important;
  /* Optional: change text color on hover */
  background-color: #70727b !important;
  /* Optional: change background on hover */
}
.radio-new-line {
  display: block;
  /* This makes the div block-level, putting it on a new line */
}
@media (max-width: 373px) {
  .inputBox {
    width: 60%;
  }
}
@media (max-width: 355px) {
  .inputBoxvana input {
    width: 100%;
  }
  .inputBoxvana {
    width: 65%;
  }
}
.wide {
  color: #2864a1 !important;
  width: 140px !important;
  height: 42px !important;
  border-radius: 20px !important;
  border: 1px solid #88acce !important;
  padding-top: 6px;
  transition: background-color 0.3s ease;
  /* Smooth transition */
  background-color: white !important;
}
.wide:hover {
  background-color: #ecf4fa !important;
  /* Darker shade for hover */
}
.insight-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  appearance: none;
  /* Hides the default checkbox */
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: white;
  /* Default background color */
  transition: background-color 0.3s ease, border-color 0.3s ease;
  position: relative;
  /* Needed for the pseudo-element */
}
.insight-checkbox:checked {
  background-color: #02aee8;
  border-color: #02aee8;
}
.insight-checkbox:checked::before {
  content: "✔";
  /* Unicode character for checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  /* Adjust size as needed */
  color: white;
  /* Checkmark color */
  font-weight: bold;
}
.GridBox {
  padding: 25px 30px;
  background-size: 100%;
}
@media (max-width: 768px) {
  .GridBox {
    padding: 10px;
  }
}
.GridBox .ant-table-thead > tr > th {
  background: #f0efef;
}
.GridBox .Home_Title {
  color: #44609d;
  font-size: 28px;
  margin: 0;
  margin-bottom: 12px;
}
.GridBox .RowBox .box_One {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.GridBox .RowBox .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(
				.ant-table-row-expand-icon-cell
			):not([colspan])::before {
  width: 0;
}
.GridBox .RowBox .HomeBox {
  border-radius: 8px;
  border: 1px solid rgba(40, 57, 94, 0.2);
  overflow: hidden;
}
.GridBox .RowBox .HomeBox ul.Lft_Box > li {
  min-height: 80px;
  padding-bottom: 10px;
}
.GridBox .RowBox .HomeBox ul.Lft_Box > li .ant-typography {
  color: #333;
  font-weight: bold;
}
.GridBox .RowBox .HomeBox ul.Lft_Box > li span {
  font-weight: lighter;
}
.GridBox .RowBox .HomeBox ul.Lft_Box > li p {
  color: #333;
  font-size: 18px;
  /* word-break: break-all;
						margin-bottom: 10px;
						overflow:hidden;
						text-overflow:ellipsis;
						 white-space:nowrap;
						-webkit-box-orient: vertical;
   						 -webkit-line-clamp: 2;  */
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}
.GridBox .RowBox .HomeBox ul.Lft_Box > li p:nth-child(2) {
  line-height: 1em;
  min-height: 2.5em;
  font-weight: lighter;
  margin-bottom: 0px;
  font-size: 16px;
}
.GridBox .RowBox .HomeBox .home_games_box li .home_games_Lst {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
}
.GridBox .RowBox .HomeBox .home_games_box li .home_games_Lst div:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 45%;
}
.GridBox .RowBox .HomeBox .home_games_box li .home_games_Lst div:nth-child(1) .home_games_list_li_ico {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 8px;
  background-color: #efefef;
  border: 1px solid rgba(40, 57, 94, 0.1);
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
}
.GridBox .RowBox .HomeBox .home_games_box li .home_games_Lst div:nth-child(1) div {
  margin-left: 10px;
}
.GridBox .RowBox .HomeBox .home_games_box li .home_games_Lst div:nth-child(1) div p {
  color: #333333;
  font-size: 16px;
  text-align: left;
  font-family: HelveticaNeue-Medium;
}
.GridBox .RowBox .HomeBox .home_games_box li .home_games_Lst > span {
  width: 25%;
  color: #80a0e7;
  font-size: 16px;
  text-align: left;
  font-family: HelveticaNeue-Medium;
}
.GridBox .RowBox .HomeBox .home_games_box li .home_games_Lst div:nth-child(3) {
  width: 30%;
  color: #333333;
  font-size: 16px;
  text-align: right;
  font-family: HelveticaNeue-Medium;
}
.GridBox .RowBox .HomeBox .home_games_box li .home_games_Lst p {
  margin-bottom: 0;
}
.GridBox .RowBox .HomeBox .home_games_box li li:hover {
  background-color: #e4ecfb;
}
.GridBox .RowBox .width_Max_Box ul li:hover {
  background-color: #f2f5fc;
}
.GridBox .RowBox .width_Max_Box ul li .Home_Insight_lst {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*   >img {
            width: 120px;
            height: 120px;
          } */
}
.GridBox .RowBox .width_Max_Box ul li .Home_Insight_lst .insght_pic {
  background-repeat: no-repeat;
  /*背景图像不平铺*/
  background-position: center;
  /*图片居中显示*/
  background-size: cover;
  /*隐藏不需要显示的图片*/
  height: 120px;
  width: 120px;
  border-radius: 8px;
  overflow: hidden;
}
.GridBox .RowBox .width_Max_Box ul li .Home_Insight_lst .insight_list_cont {
  width: calc(100% - 130px);
}
.GridBox .RowBox .width_Max_Box ul li .Home_Insight_lst .insight_list_cont h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.GridBox .RowBox .width_Max_Box ul li .Home_Insight_lst .insight_list_cont h3 div {
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.GridBox .RowBox .width_Max_Box ul li .Home_Insight_lst .Tag_List {
  margin-bottom: 10px;
}
.GridBox .Esport_Tabel {
  background: none;
}
.GridBox .RM_Tabel {
  width: 100%;
}
.GridBox .RM_Tabel td.ant-table-cell {
  height: 76px;
}
.GridBox .home_Card_Row {
  display: flex;
  justify-content: space-between;
}
.GridBox .home_Card_Row .home_Card_Box {
  border: none;
  width: calc(25% - 5px);
}
.GridBox .home_Card_Row .home_Card_Box > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 239px;
  border-radius: 2px;
  overflow: hidden;
  text-align: center;
}
.GridBox .home_Card_Row .home_Card_Box > div div:nth-child(2) {
  width: 60%;
  text-align: center;
}
.GridBox .home_Card_Row .home_Card_Box > div .ant-statistic-title {
  font-size: "24px";
}
.GridBox .home_Card_Row .home_Card_Box > div .ant-statistic {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.GridBox .home_Card_Row > div:nth-child(1) {
  background-color: rgba(255, 152, 152, 0.1);
}
.GridBox .home_Card_Row > div:nth-child(2) {
  background-color: rgba(109, 65, 221, 0.1);
}
.GridBox .home_Card_Row > div:nth-child(3) {
  background-color: rgba(234, 148, 25, 0.1);
}
.GridBox .home_Card_Row > div:nth-child(4) {
  background-color: rgba(128, 160, 231, 0.1);
}
.GridBox .footer_Mod_Box .footer_Mod_Label {
  padding: 40px;
  background-color: #fcfbfb;
}
.GridBox .footer_Mod_Box .footer_Mod_Label .title {
  display: flex;
  justify-content: space-between;
}
.GridBox .footer_Mod_Box .footer_Mod_Label .title_span {
  font-size: 18px;
  font-weight: 600;
}
.GridBox .footer_Mod_Box .footer_Mod_Label .date_span {
  text-align: right;
  color: #999999;
  font-size: 16px;
}
.GridBox .footer_Mod_Box .footer_Mod_Label h3 {
  font-size: 18px;
  color: #28395e;
  font-weight: bold;
  margin-bottom: 18px;
}
.GridBox .footer_Mod_Box .footer_Mod_Label h3 span {
  float: right;
  color: #999;
  font-size: 16px;
  font-weight: lighter;
}
.GridBox .footer_Mod_Box .footer_Mod_Label .Niko_Index_Box {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 300px;
  justify-content: space-between;
  padding: 9px 0;
}
.GridBox .footer_Mod_Box .footer_Mod_Label .Niko_Index_Box .Niko_Index_Text_Box {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
}
.SearchList_Box .Tab_box {
  padding: 0 30px;
}
.SearchList_Box .Tab_box .BlogBox {
  padding: 0;
  width: 100%;
}
.Global_title {
  margin-bottom: 0 !important;
}
.SearchList_Box {
  min-height: 90vh;
}
.SearchList_Box .ant-table {
  border: 0 !important;
}
.SearchList_Box .ant-table-thead {
  background: none !important;
}
.New_Home_Box .New_Home_Banner_Box .slick-slide img {
  width: 100%;
  max-height: 396px;
  object-fit: contain;
}
.New_Home_Box .New_Home_Banner_Box .slick-list {
  border-radius: 5px;
  overflow: hidden;
}
.New_Home_Box .New_Home_Banner_Box .ant-carousel .slick-dots-bottom {
  bottom: 0;
}
.New_Home_Box .New_Home_Banner_Box .ant-carousel .slick-dots {
  position: relative;
  margin: 35px 0;
}
.New_Home_Box .New_Home_Banner_Box .ant-carousel .slick-dots li.slick-active {
  width: 50px;
}
.New_Home_Box .New_Home_Banner_Box .ant-carousel .slick-dots li.slick-active button {
  background: #124281;
}
.New_Home_Box .New_Home_Banner_Box .ant-carousel .slick-dots li {
  width: 18px;
}
.New_Home_Box .New_Home_Banner_Box .ant-carousel .slick-dots li button {
  background: #d8d8d8;
  opacity: 1;
}
.New_Home_Box .News_Box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.New_Home_Box .News_Box .News_lft_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 425px;
}
.New_Home_Box .News_Box .News_lft_box .News_list_box {
  width: calc(34% - 35px);
}
.New_Home_Box .News_Box .News_lft_box .News_list_box ul li h3 {
  margin-top: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.New_Home_Box .News_Box .News_lft_box .News_list_box ul li h3 .Type_span {
  font-weight: 400;
  margin-right: 10px;
}
.New_Home_Box .News_Box .News_lft_box .News_list_box ul li h3 a {
  font-size: 20px;
  color: #333;
  font-weight: 700;
}
.New_Home_Box .News_Box .News_lft_box .News_list_box ul li > div {
  min-height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 16px;
}
.New_Home_Box .News_Box .News_rgt_box {
  width: 535px;
  height: 425px;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li {
  margin-top: 4px;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  width: 100%;
  padding: 5px;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a img {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  object-fit: contain;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a > div:nth-child(1) {
  width: 60%;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a > div:nth-child(2) {
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a > div:nth-child(3) {
  width: 20%;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a > div p {
  height: 25px;
  overflow: hidden;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a > div > div {
  margin-left: 20px;
  height: 60px;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a > div > div p {
  margin-bottom: 0px;
  color: #333;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a > div.Home_Gamelist_Color {
  font-weight: 500;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a > div.Home_Gamelist_platform {
  font-size: 12px;
  color: #333;
  font-weight: 500;
  line-height: 25px;
}
.New_Home_Box .News_Box .News_rgt_box .News_Gamelist_box ul li a:hover {
  background-color: #f5f5f5;
}
.New_Home_Box .New_Home_Stream_Box .New_Home_Title,
.New_Home_Box .New_Home_RM_Box .New_Home_Title,
.New_Home_Box .New_Home_ISBN_Box .New_Home_Title,
.New_Home_Box .New_Home_Esport_Box .New_Home_Title,
.New_Home_Box .Games_List_box .New_Home_Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 25px 0;
  padding: 0 1px;
}
.New_Home_Box .New_Home_Stream_Box .New_Home_Title .Insights_Slide_flip a,
.New_Home_Box .New_Home_RM_Box .New_Home_Title .Insights_Slide_flip a,
.New_Home_Box .New_Home_ISBN_Box .New_Home_Title .Insights_Slide_flip a,
.New_Home_Box .New_Home_Esport_Box .New_Home_Title .Insights_Slide_flip a,
.New_Home_Box .Games_List_box .New_Home_Title .Insights_Slide_flip a {
  padding: 0 22px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.New_Home_Box .New_Home_Stream_Box .slick_slide,
.New_Home_Box .New_Home_RM_Box .slick_slide,
.New_Home_Box .New_Home_ISBN_Box .slick_slide,
.New_Home_Box .New_Home_Esport_Box .slick_slide,
.New_Home_Box .Games_List_box .slick_slide {
  text-align: center;
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  margin-right: 30px;
}
.New_Home_Box .New_Home_Stream_Box .slick_slide .Game_Slider_Img img,
.New_Home_Box .New_Home_RM_Box .slick_slide .Game_Slider_Img img,
.New_Home_Box .New_Home_ISBN_Box .slick_slide .Game_Slider_Img img,
.New_Home_Box .New_Home_Esport_Box .slick_slide .Game_Slider_Img img,
.New_Home_Box .Games_List_box .slick_slide .Game_Slider_Img img {
  width: 100%;
  height: 203px;
  object-fit: contain;
  padding: 25px 25px 0 25px;
}
.New_Home_Box .New_Home_Stream_Box .slick_slide .New_Home_Game_date,
.New_Home_Box .New_Home_RM_Box .slick_slide .New_Home_Game_date,
.New_Home_Box .New_Home_ISBN_Box .slick_slide .New_Home_Game_date,
.New_Home_Box .New_Home_Esport_Box .slick_slide .New_Home_Game_date,
.New_Home_Box .Games_List_box .slick_slide .New_Home_Game_date {
  text-align: right;
}
.New_Home_Box .New_Home_Stream_Box .slick_slide .New_Home_Game_date span,
.New_Home_Box .New_Home_RM_Box .slick_slide .New_Home_Game_date span,
.New_Home_Box .New_Home_ISBN_Box .slick_slide .New_Home_Game_date span,
.New_Home_Box .New_Home_Esport_Box .slick_slide .New_Home_Game_date span,
.New_Home_Box .Games_List_box .slick_slide .New_Home_Game_date span {
  font-size: 12px;
  line-height: 10px;
  padding: 5px 5px 6px;
  display: inline-block;
  border-bottom: 3px solid #003778;
  color: #999999;
}
.New_Home_Box .New_Home_Stream_Box .slick_slide .New_Home_Game_info,
.New_Home_Box .New_Home_RM_Box .slick_slide .New_Home_Game_info,
.New_Home_Box .New_Home_ISBN_Box .slick_slide .New_Home_Game_info,
.New_Home_Box .New_Home_Esport_Box .slick_slide .New_Home_Game_info,
.New_Home_Box .Games_List_box .slick_slide .New_Home_Game_info {
  color: #333;
  padding: 10px 25px 25px 25px;
}
.New_Home_Box .New_Home_Stream_Box .slick_slide .New_Home_Game_info h3,
.New_Home_Box .New_Home_RM_Box .slick_slide .New_Home_Game_info h3,
.New_Home_Box .New_Home_ISBN_Box .slick_slide .New_Home_Game_info h3,
.New_Home_Box .New_Home_Esport_Box .slick_slide .New_Home_Game_info h3,
.New_Home_Box .Games_List_box .slick_slide .New_Home_Game_info h3 {
  font-size: 20px;
  overflow: hidden;
  height: 30px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.New_Home_Box .New_Home_Stream_Box .slick_slide .New_Home_Game_info h3 a,
.New_Home_Box .New_Home_RM_Box .slick_slide .New_Home_Game_info h3 a,
.New_Home_Box .New_Home_ISBN_Box .slick_slide .New_Home_Game_info h3 a,
.New_Home_Box .New_Home_Esport_Box .slick_slide .New_Home_Game_info h3 a,
.New_Home_Box .Games_List_box .slick_slide .New_Home_Game_info h3 a {
  color: #333;
}
.New_Home_Box .New_Home_Stream_Box .slick_slide .New_Home_Game_info .New_Home_Game_info_content,
.New_Home_Box .New_Home_RM_Box .slick_slide .New_Home_Game_info .New_Home_Game_info_content,
.New_Home_Box .New_Home_ISBN_Box .slick_slide .New_Home_Game_info .New_Home_Game_info_content,
.New_Home_Box .New_Home_Esport_Box .slick_slide .New_Home_Game_info .New_Home_Game_info_content,
.New_Home_Box .Games_List_box .slick_slide .New_Home_Game_info .New_Home_Game_info_content {
  overflow: hidden;
  font-size: 16px;
}
.New_Home_Box .New_Home_Stream_Box .slick_slide .New_Home_Game_info .New_Home_Game_Taglist,
.New_Home_Box .New_Home_RM_Box .slick_slide .New_Home_Game_info .New_Home_Game_Taglist,
.New_Home_Box .New_Home_ISBN_Box .slick_slide .New_Home_Game_info .New_Home_Game_Taglist,
.New_Home_Box .New_Home_Esport_Box .slick_slide .New_Home_Game_info .New_Home_Game_Taglist,
.New_Home_Box .Games_List_box .slick_slide .New_Home_Game_info .New_Home_Game_Taglist {
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.New_Home_Box .New_Home_Stream_Box .slick_slide .New_Home_Game_info .New_Home_Game_Taglist span.ant-tag,
.New_Home_Box .New_Home_RM_Box .slick_slide .New_Home_Game_info .New_Home_Game_Taglist span.ant-tag,
.New_Home_Box .New_Home_ISBN_Box .slick_slide .New_Home_Game_info .New_Home_Game_Taglist span.ant-tag,
.New_Home_Box .New_Home_Esport_Box .slick_slide .New_Home_Game_info .New_Home_Game_Taglist span.ant-tag,
.New_Home_Box .Games_List_box .slick_slide .New_Home_Game_info .New_Home_Game_Taglist span.ant-tag {
  cursor: pointer;
}
.New_Home_Box .New_Home_RM_Box .New_Home_Title,
.New_Home_Box .New_Home_Esport_Box .New_Home_Title {
  margin-bottom: 0;
}
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table table,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table table,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table table,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table table,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table table,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table table {
  border-collapse: separate;
  border-spacing: 0px 20px;
}
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table-thead > tr > th,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table-thead > tr > th,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table-thead > tr > th,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table-thead > tr > th,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table-thead > tr > th,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table-thead > tr > th {
  color: #003778;
  border: none;
  font-size: 16px;
}
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table-tbody > tr,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table-tbody > tr,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table-tbody > tr,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table-tbody > tr,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table-tbody > tr,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table-tbody > tr {
  box-shadow: 0px 0px 4px #d6d6d6;
  border-radius: 8px;
  height: 58px;
}
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table-tbody > tr > td {
  font-size: 14px;
  padding: 0 14px;
  color: #333;
}
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table-tbody > tr > td p,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-table-tbody > tr > td p,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table-tbody > tr > td p,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-table-tbody > tr > td p,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table-tbody > tr > td p,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-table-tbody > tr > td p {
  margin: 0;
}
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_rgt a.ant-typography,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_rgt a.ant-typography,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_lft a.ant-typography,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_lft a.ant-typography,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_Lft a.ant-typography,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_Lft a.ant-typography,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-typography a,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_rgt .ant-typography a,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-typography a,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_RM_lft .ant-typography a,
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-typography a,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_Lft .ant-typography a {
  color: #2e65dd;
  text-decoration: underline;
}
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_rgt,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_rgt {
  margin-left: 73px;
  max-height: 481px;
}
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box {
  height: 100%;
}
.New_Home_Box .New_Home_RM_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img,
.New_Home_Box .New_Home_Esport_Box .New_Home_Esport_Label .New_Home_Esport_rgt a.New_Home_Esport_rgt_AD_Box img {
  width: 100%;
  max-height: 290px;
  margin-top: 20px;
}
.New_Home_Box .New_Home_ISBN_Box .New_Home_ISBN_Card_List {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.New_Home_Box .New_Home_ISBN_Box .New_Home_ISBN_Card_List .New_Home_ISBN_Card_Lbale {
  width: calc(25% - 28px);
  height: 202px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.New_Home_Box .New_Home_ISBN_Box .New_Home_ISBN_Card_List .New_Home_ISBN_Card_Lbale img {
  width: 72px;
  height: 72px;
  object-fit: contain;
}
.New_Home_Box .New_Home_ISBN_Box .New_Home_ISBN_Card_List .New_Home_ISBN_Card_Lbale > div {
  margin-left: 16px;
}
.New_Home_Box .New_Home_ISBN_Box .New_Home_ISBN_Card_List .New_Home_ISBN_Card_Lbale > div h2 {
  margin-bottom: 15px;
  color: #333;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}
.New_Home_Box .New_Home_ISBN_Box .New_Home_ISBN_Card_List .New_Home_ISBN_Card_Lbale > div h2 span {
  font-size: 16px;
}
.New_Home_Box .New_Home_ISBN_Box .New_Home_ISBN_Card_List .New_Home_ISBN_Card_Lbale > div p {
  margin: 0;
  font-size: 16px;
  color: #999;
  line-height: 18px;
}
.New_Home_Box .New_Home_ISBN_Box .New_Home_ISBN_Card_List .New_Home_ISBN_Card_Lbale:nth-child(1) {
  background-color: #faf8f4;
}
.New_Home_Box .New_Home_ISBN_Box .New_Home_ISBN_Card_List .New_Home_ISBN_Card_Lbale:nth-child(2) {
  background-color: #f1f5ff;
}
.New_Home_Box .New_Home_ISBN_Box .New_Home_ISBN_Card_List .New_Home_ISBN_Card_Lbale:nth-child(3) {
  background-color: #edf6f7;
}
.New_Home_Box .New_Home_ISBN_Box .New_Home_ISBN_Card_List .New_Home_ISBN_Card_Lbale:nth-child(4) {
  background-color: #e6f5f9;
}
.New_Home_Box .New_Home_RM_Box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.New_Home_Box .New_Home_RM_Box .New_Home_RM_lft,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_rgt {
  width: calc(50% - 20px);
}
.New_Home_Box .New_Home_RM_Box .New_Home_RM_lft .New_Home_Title,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_rgt .New_Home_Title {
  margin-bottom: 0;
}
.New_Home_Box .New_Home_RM_Box .New_Home_RM_lft .ant-table table,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_rgt .ant-table table {
  border-collapse: separate;
  border-spacing: 0px 20px;
  min-height: 487px;
}
.New_Home_Box .New_Home_RM_Box .New_Home_RM_lft .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_rgt .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_lft .ant-table-thead > tr > th,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_rgt .ant-table-thead > tr > th {
  color: #003778;
  border: none;
  font-size: 16px;
}
.New_Home_Box .New_Home_RM_Box .New_Home_RM_lft .ant-table-tbody > tr,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_rgt .ant-table-tbody > tr {
  box-shadow: 0px 0px 4px #d6d6d6;
  border-radius: 8px;
  height: 58px;
}
.New_Home_Box .New_Home_RM_Box .New_Home_RM_lft .ant-table-tbody > tr > td,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_rgt .ant-table-tbody > tr > td {
  font-size: 14px;
  padding: 0 14px;
  color: #333;
}
.New_Home_Box .New_Home_RM_Box .New_Home_RM_lft .ant-table-tbody > tr > td p,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_rgt .ant-table-tbody > tr > td p {
  margin: 0;
}
.New_Home_Box .New_Home_RM_Box .New_Home_RM_lft a.ant-typography,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_rgt a.ant-typography,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_lft .ant-typography a,
.New_Home_Box .New_Home_RM_Box .New_Home_RM_rgt .ant-typography a {
  color: #2e65dd;
  text-decoration: underline;
}
.New_Home_Box .New_Home_RM_Box .New_Home_RM_rgt .New_Home_RM_Tabel a {
  color: #2e65dd;
  text-decoration: underline;
}
.New_Home_Box .New_Home_Stream_Box {
  padding-bottom: 50px;
}
.New_Home_Box .New_Home_Stream_Box .New_Home_Title {
  margin-top: 22px;
}
.New_Home_Box .New_Home_Stream_Box .footer_Mod_Label {
  background: #fff;
  border: 0.94px solid #d2d2d2;
  border-radius: 8px;
  padding: 24px 21px;
}
.New_Home_Box .New_Home_Stream_Box .footer_Mod_Label h3 {
  font-size: 22.47px !important;
  color: #28395e;
}
.New_Home_Box .New_Home_Stream_Box .footer_Mod_Label h3 span {
  font-size: 18.73px;
  color: #999;
}
.New_Home_Box .New_Home_Stream_Box .footer_Mod_Label .Niko_Index_Box {
  padding: 25px 0;
  margin: 0 auto;
  max-width: 330px;
  display: flex;
  justify-content: space-between;
}
.New_Home_Box .New_Home_Stream_Box .footer_Mod_Label .Niko_Index_Box img {
  width: 65px;
  height: 72px;
  object-fit: contain;
}
.New_Home_Box .New_Home_Stream_Box .footer_Mod_Label .Niko_Index_Box .Niko_Index_Text_Box {
  font-size: 37.45px !important;
  padding-left: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
