.homeContainer {
  width: 100%;
  background-color: #fff;
}
.homeContainer .homeContent .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 30px 40px;
  background-color: #fff;
}
.homeContainer .homeContent .body .bodyItem {
  display: flex;
  flex-direction: column;
  width: 498px;
  height: 720px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemTitle {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: 600;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 40px 20px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .tip {
  position: absolute;
  left: -1px;
  top: -1px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left {
  display: flex;
  align-items: center;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left .img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left .img .pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left .name {
  margin-left: 16px;
  color: #333;
  font-size: 24px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .right .score {
  color: #f49420;
  font-size: 40px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .info {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 20px 0;
  background-image: linear-gradient(to bottom right, #f1f1f1, #fcfcfc);
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .info .left {
  display: flex;
  align-items: center;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .info .left .text2 {
  margin-left: 80px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemRank {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  font-size: 24px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .two {
  background-color: #d0d3dc;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .three {
  background-color: #f1bf78;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo .ItemImg {
  width: 32px;
  height: 32px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo .ItemImg .pic {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo .ItemName {
  width: 120px;
  margin-left: 10px;
}
.homeContainer .homeContent .footer {
  display: flex;
  padding-bottom: 100px;
  background-color: #fff;
  overflow: hidden;
}
.homeContainer .homeContent .footer .bgc {
  position: relative;
  z-index: 1;
  left: -130px;
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  background-image: linear-gradient(rgba(87, 160, 209, 0.1), rgba(44, 103, 164, 0.1));
}
.homeContainer .homeContent .footer .polyLine {
  display: flex;
  position: relative;
  margin: 100px 0 0 -530px;
  z-index: 999;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .info {
  color: #333;
  font-size: 40px;
  font-weight: 600;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .question {
  margin: 36px 0 50px 0;
  width: 478px;
  color: #666;
  font-size: 20px;
  line-height: 28px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type {
  display: flex;
  align-items: center;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left,
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left .l,
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right .l {
  width: 22px;
  height: 8px;
  margin-right: 20px;
  background-color: #5883f7;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left .r,
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right .r {
  color: #333;
  font-size: 16px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left {
  margin-right: 40px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right .l {
  background-color: #5dd29d;
}
@media only screen and (min-width: 800px) {
  .GridBox .footer_Mod_Box .footer_Mod_Label .Niko_Index_Box .Niko_Index_Text_Box {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1000px) {
  .GridBox .footer_Mod_Box .footer_Mod_Label .Niko_Index_Box .Niko_Index_Text_Box {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1260px) {
  .GridBox .footer_Mod_Box .footer_Mod_Label .Niko_Index_Box .Niko_Index_Text_Box {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1580px) {
  .GridBox .footer_Mod_Box .footer_Mod_Label .Niko_Index_Box .Niko_Index_Text_Box {
    font-size: 22px;
  }
}
.GridBox .RowBox .Icafe_Details_Top {
  display: flex;
  margin-bottom: 25px;
  flex-direction: row;
}
.GridBox .RowBox .Icafe_Details_Top .Icafe_Details_Logo {
  width: 180px;
  height: 180px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  border-radius: 4px;
}
.GridBox .RowBox .Icafe_Details_Top > div:nth-child(2) {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
}
.GridBox .RowBox .Icafe_Details_Top > div:nth-child(2) .Icafe_Name {
  text-align: left;
  font-size: 26px;
}
.GridBox .RowBox .Icafe_Details_Top > div:nth-child(2) .Icafe_details {
  font-size: 20px;
}
.GridBox .RowBox .Icafe_Details_Top > div:nth-child(2) .Icafe_details span {
  color: #456bd3;
}
