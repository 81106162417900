.container {
  height: 100vh;
  /* Set the height to the full viewport height */
  background-image: url("../../assets/loginBG.png");
  background-size: cover;
  /* Ensures the image covers the entire container */
  background-position: center;
  /* Centers the image */
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
}
div.ant-typography {
  margin-bottom: 0 !important;
}
.content {
  background-color: aliceblue;
  height: 80%;
  overflow: auto;
  /* Enables scrolling when content overflows */
  max-width: 90%;
  margin: auto;
  padding: 70px 50px;
  border-radius: 40px;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For IE and Edge */
  /* Hide scrollbar for Webkit browsers */
}
.content::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}
@media screen and (max-width: 480px) {
  .content {
    padding: 50px 20px;
  }
}
.contentfaq {
  background-color: white;
  height: 80%;
  overflow: auto;
  /* Enables scrolling when content overflows */
  max-width: 90%;
  margin: auto;
  padding: 70px 50px;
  border-radius: 40px;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For IE and Edge */
  width: 100%;
  /* Hide scrollbar for Webkit browsers */
}
.contentfaq::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}
@media screen and (max-width: 480px) {
  .contentfaq {
    padding: 50px 5px;
  }
}
header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerfaq {
  text-align: center;
  color: #2864a1;
}
.faqAnswer {
  font-size: 20px;
}
.faqAnswer .anticon svg {
  width: 14px !important;
  height: 14px !important;
}
@media screen and (max-width: 480px) {
  .pageTitle .ant-typography {
    width: unset !important;
  }
}
.ant-collapse-content > .ant-collapse-content-box {
  background-color: #f7f7f7 !important;
}
.container {
  padding: 0px 60px 52px 60px;
}
@media screen and (max-width: 480px) {
  .container {
    padding: 1.25rem;
  }
}
.container .pageTitle {
  text-align: center;
  color: #2864a1;
}
.container .subTitle {
  font-size: 1.5rem;
  color: #2864a1;
  margin-top: 0 !important;
}
@media screen and (max-width: 480px) {
  .container .subTitle {
    font-size: 1.5rem;
  }
}
.container .content {
  font-size: 1.5rem;
}
@media screen and (max-width: 480px) {
  .container .content {
    font-size: 1rem;
  }
}
