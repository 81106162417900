@mobile: 480px;
@tablet: 768px;
.custom-pagination .ant-pagination-total-text {
	order: 1;
	margin-right: 16px;
	margin-left: 32px;
}

.custom-pagination .ant-pagination-options {
	order: 2;
}

.custom-pagination .ant-pagination-item {
	order: 0;
}
.leftSection {
	width: 50%;

	@media screen and (max-width: 1024px) {
		width: 100% !important;
		padding-left: 20px;
	}
}
.pdf {
	height: 100%;
}
.rich_pdf {
	width: 50%;
	margin-bottom: 8rem;
	@media screen and (max-width: 1024px) {
		width: 100% !important;
	}
}
.ant-spin-nested-loading {
	height: 100%;
	width: 100%;
}
.ant-spin-container {
	height: 100%;
	// width: 100%;
}
.subControls {
	margin-left: -20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;
	// width: 60%;
}
.controls {
	padding-right: 20px;
	margin-top: 40px;
	// width: 50%;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 53px;
	@media screen and (max-width: @mobile) {
		margin-top: 0;
		margin-left: 0;
		display: flex;
		align-self: center;
		justify-content: space-between;
		padding-right: 0px;
	}
	.btn_action {
		border: 1px solid #88acce;
		border-radius: 25px;
		display: flex;
		padding: 5px;
		padding-left: 20px;
		padding-right: 25px;
		justify-content: center;
		align-items: center;
		transition: background-color 0.3s, color 0.3s, border 0.3s;
		align-items: center;

		@media screen and (max-width: @mobile) {
			margin-bottom: unset;
			padding: 3px;
			padding-left: 10px;
			padding-right: 15px;
		}
		cursor: pointer;
		.icon_action {
			width: 20px;
			height: 20px;

			.icon {
				width: 20px;
				height: 20px;
				color: #88acce;
			}
		}
		.option {
			font-size: 18.67px;
			font-weight: 500;
			color: #2864a1;
			// width: 90px;
			margin-left: 15px;
			margin-top: 1px;
			text-align: -webkit-center;
		}
	}
	.btn_action:hover {
		transition: background-color 0.3s, color 0.3s, border 0.3s;
		color: #2864a1 !important;
		background-color: #ecf4fa !important;
		border: #2864a1 2px solid !important;
	}
	@media screen and (max-width: @mobile) {
		width: 100% !important;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}
.exclusives_container {
	padding: 48px 0px 0px 48px;
	@media screen and (max-width: @mobile) {
		padding: 12px 15px 0px 15px;
	}
	// zoom: 0.8;
	.active {
		display: flex;
		// border-radius: 10.67px;
		// border: 1.33px solid rgba(40, 57, 94, 0.2);
		// .head {
		// 	align-items: center;
		// 	padding: 0px 40px 0px 36px;
		// 	@media screen and (max-width: @mobile) {
		// 		padding: 0px 15px 0px 12px;
		// 	}
		// 	display: flex;
		// 	justify-content: space-between;
		// 	height: 109.33px;
		// 	opacity: 1;
		// 	border-radius: 10.67px 10.67px, 0px, 0px;
		// 	background: linear-gradient(
		// 		90deg,
		// 		rgba(44, 103, 164, 0.08) 0%,
		// 		rgba(208, 222, 237, 0.08) 100%
		// 	);
		// }

		.left_title {
			width: 90%;
			font-size: 32px;
			@media screen and (max-width: @mobile) {
				font-size: 25px;
				width: 100% !important;
			}
			font-weight: 400;
			color: #2e373c;
			vertical-align: top;
			.icon_article {
				margin-right: 21px;
			}
		}
		.right_time {
			font: normal normal normal 14px/20px Inter;
			font-size: 16.33px;
			font-weight: 400;
			color: #70727b;
			@media screen and (max-width: @mobile) {
				font-size: 16px;
			}
		}
		.title {
			border-top: 1px solid #bdcbd8;
			border-bottom: 1px solid #bdcbd8;

			width: 97%;
			text-align: left;
			font: normal normal normal 18px/28px Inter;
			letter-spacing: 0px;
			font-weight: 400;
			color: #2e373c;
			// padding-left: 41px;
			padding-top: 29px;
			padding-bottom: 29px;
			// margin-bottom: 23px;
			@media screen and (max-width: @mobile) {
				padding-left: 10px;
				padding-top: 10px;
				width: 100%;
				max-width: 100%; /* Adjust width as needed */
				word-wrap: break-word; /* Forces text to wrap to the next line */
				white-space: normal; /* Allows line breaks within the element */
			}
		}
		.rich_pdf {
			position: relative;
		}

		.pdf-toolbar {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0.5; /* Low opacity */
			background-color: rgba(
				255,
				255,
				255,
				0.7
			); /* Semi-transparent background */
			padding: 10px;
			border-radius: 8px;
			display: flex;
			gap: 8px;
			transition: opacity 0.3s;
		}

		/* Optional: Show toolbar fully on hover */
		.rich_pdf:hover .pdf-toolbar {
			opacity: 1;
		}

		.rich_pdf {
			transform-origin: top center;
			display: flex;
			justify-content: center;
			height: 45rem; /* Default height for normal 100% DPI screens */

			/* Adjustments for 150% DPI on desktop */
			// @media screen and (min-resolution: 144dpi) {
			// 	height: 100vh; /* Increased height for desktops at 150% DPI */
			// }

			// /* Adjustments for 150% DPI on mobile */
			// @media screen and (min-resolution: 144dpi) and (max-width: 600px) {
			// 	height: 120vh; /* Increased height for mobile at 150% DPI */
			// }

			// /* Screen-specific adjustments */
			// @media screen and (max-width: 1260px) {
			// 	height: 75vh;
			// }
			// @media screen and (max-width: 1200px) {
			// 	height: 70vh;
			// }
			// @media screen and (max-width: 1100px) {
			// 	height: 80vh;
			// }
			// @media screen and (max-width: 1024px) {
			// 	margin-top: 20px;
			// 	height: 85vh;
			// }
			// @media screen and (max-width: 600px) {
			// 	height: 80rem;
			// }
			@media screen and (max-width: 500px) {
				height: 30rem;
			}
			@media screen and (max-width: 400px) {
				height: 20rem;
			}
			// @media screen and (max-width: 320px) {
			// 	height: 15rem;
			// }
		}
		// .rich_pdf {
		// 	transform-origin: top center;
		// 	display: flex;
		// 	justify-content: center;
		// 	height: 80vh; /* Default height for normal 100% DPI screens */

		// 	/* Adjustments for 150% DPI on desktop */
		// 	@media screen and (min-resolution: 144dpi) {
		// 		height: 100vh; /* Increased height for desktops at 150% DPI */
		// 	}

		// 	/* Adjustments for 150% DPI on mobile */
		// 	@media screen and (min-resolution: 144dpi) and (max-width: 600px) {
		// 		height: 120vh; /* Increased height for mobile at 150% DPI */
		// 	}

		// 	/* Screen-specific adjustments */
		// 	@media screen and (max-width: 1260px) {
		// 		height: 75vh;
		// 	}
		// 	@media screen and (max-width: 1200px) {
		// 		height: 70vh;
		// 	}
		// 	@media screen and (max-width: 1100px) {
		// 		height: 80vh;
		// 	}
		// 	@media screen and (max-width: 1024px) {
		// 		margin-top: 20px;
		// 		height: 85vh;
		// 	}
		// 	@media screen and (max-width: 600px) {
		// 		height: 80vh;
		// 	}
		// 	@media screen and (max-width: 550px) {
		// 		height: 60vh;
		// 	}
		// 	@media screen and (max-width: 370px) {
		// 		flex-direction: column;
		// 		justify-content: space-between;
		// 		height: 40vh;
		// 	}
		// 	@media screen and (max-width: 320px) {
		// 		flex-direction: column;
		// 		justify-content: space-between;
		// 		height: 30vh;
		// 	}
		// }

		@media screen and (max-width: 1024px) {
			flex-direction: column;
		}
	}
	.history_box {
		zoom: 0.8;
		width: 100%;
		text-align: center;
		cursor: pointer;
		.history {
			margin-top: 0;
			// margin-bottom: 45px;
			margin-bottom: 3.4rem;
			width: 492px;
			height: 66.67px;
			border-radius: 30px;
			font-size: 26px;
			font-weight: 400;
			color: white;
			background-color: #02aee8 !important;
			border: none !important;
			transition: background-color 0.3s, color 0.3s;
		}
		@media screen and (max-width: 768px) {
			.history {
				width: 100%;
				height: fit-content !important;
			}
			.history span {
				display: inline-block; /* Ensures the span behaves like a block for breaking lines */
				word-wrap: break-word; /* Break long words onto the next line */
				word-break: break-word; /* Supports breaking words across multiple lines */
				white-space: normal; /* Allows wrapping instead of keeping text on a single line */
			}
		}

		.history:hover {
			background-color: #0099cc !important; /* Slightly darker shade */
			color: white; /* Slightly less bright */
		}
	}
	.form_box {
		margin-top: 40px;
		background-color: rgb(247, 249, 251);
		padding: 30px 50px;
		margin-bottom: 32px;
		.ant-form-item-label > label {
			font-size: 20px;
		}
	}
	.area {
		margin-left: 2px;
		color: #70727b;

		letter-spacing: var(--unnamed-character-spacing-0);

		text-align: left;
		font: normal normal normal 18px/28px Inter;
		letter-spacing: 0px;
		color: #70727b;
		opacity: 1;
	}
	.area_Tag_List_Box {
		display: flex;
		justify-content: flex-start;
		gap: 20px;
		align-items: center;
		// width: 20%;
		@media screen and (max-width: @mobile) {
			width: 100%;
		}
	}
	.data_list {
		// zoom: 0.8;
		padding-top: 10px;
		padding-bottom: 24px;
		// border-radius: 10.67px;
		// border: 1.33px solid rgba(0, 55, 120, 0.2);
		.data_item {
			border-top: 1px solid #bdcbd8;
			// padding-bottom: 24px;
			// padding-left: 56px;
			// padding-right: 40px;
			// padding-top: 24px;
			padding: 25px 20px;
			@media screen and (max-width: @mobile) {
				padding-left: 10px;
				padding-right: 10px;
			}
			.head {
				display: flex;
				justify-content: space-between;
				margin-bottom: 10px;
				.title {
					/* font: normal normal medium 30px/30px Inter; */
					font-size: 1.5rem;
					font-weight: 700;
					color: #2e373c;
					max-width: 100%; /* Adjust width as needed */
					word-wrap: break-word; /* Forces text to wrap to the next line */
					white-space: normal; /* Allows line breaks within the element */
				}
				.time {
					font-size: 16px;
					font-weight: 600;
					margin-right: 10px;
					color: #999999;
				}
			}
			.mid {
				font-weight: 400;
				margin-bottom: 21px;
				text-align: left;
				font-size: 1.4rem;
				color: #2e373c;
				padding-top: 10px;
			}
			.bottom {
				display: flex;
				justify-content: flex-start;
				gap: 10px;
				width: 480px;
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 0px;
				line-height: 0px;
				color: #02aee8;
				text-align: left;
				vertical-align: top;
				.action {
					cursor: pointer;
					width: 30%;
					img {
						object-fit: contain;
						width: 21.33px;
						height: 21.33px;
						margin-right: 11px;
					}
				}
			}
		}
	}
}

@primary-color: #003778;@border-radius-base: 6px;@layout-header-background: #ffffff;@layout-sider-background: #003778;@menu-bg: #003778;@menu-inline-toplevel-item-height: 94px;@menu-item-height: 53px;@menu-item-color: #ffffff;@menu-highlight-color: #ffffff;@menu-inline-submenu-bg: #003778;@menu-item-active-bg: rgba(128, 160, 231, 0.3);@menu-popup-bg: #003778;@menu-item-active-border-width: 0px;