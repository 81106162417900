@mobile: 480px;

.newHomeContainer {
	// padding: 4rem 90px 0px 60px;
	background-size: 100% 100%;
	// background-image: url("../../assets/images/home_bg.png");
	background-repeat: no-repeat;
	zoom: 0.75;
	@media screen and (max-width: @mobile) {
		padding: 0px 15px 0px 15px;
	}
	ul,
	li,
	ol {
		list-style: disc;
	}
	.row_spaceBetween {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		flex-direction: row;
		@media screen and (max-width: @mobile) {
			flex-direction: column;
		}
	}
	.head {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 26px;
		font-weight: 400;
		margin-bottom: 4rem;
		color: rgba(0, 55, 120, 1);
		background: linear-gradient(to top, #ffffff, #f0faff);
		padding: 5rem;
		@media screen and (max-width: @mobile) {
			flex-direction: column;
		}

		.divider {
			height: 5rem;
			border-left: 2px solid #15181a53;
			@media screen and (max-width: @mobile) {
				height: 0;
				border-left: 0;
				border-bottom: 2px solid #15181a53;
				width: 5rem;
			}
		}
		.head_img {
			object-fit: contain;
			height: 90px;
			margin-right: 53px;
			@media screen and (max-width: @mobile) {
				margin-right: unset;
			}
		}
		.imgTitle {
			display: flex;
			align-items: center;
			color: #2864a1;
			font-size: 35px;
			font-weight: 500;
			margin-left: 3rem;

			p {
				margin: 0;
			}
		}
	}
	.carouselContainer {
		width: 85%;
		margin: auto;
		border-radius: 10px;

		.carouselTitle {
			font-size: 2.5rem;
			font-weight: 600;
			color: #2864a1;
			text-align: center;
		}

		.ant-carousel .slick-dots-bottom {
			bottom: -70px;
		}

		.btns {
			display: flex;
			justify-content: flex-end;
			margin: 24px 0;

			.last-btn {
				margin-left: 1rem;
			}
			.btnIcon {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				cursor: pointer;
				border: 1px solid #88acce;
				img {
					width: 45%;
					height: 45%;
				}
			}
			.btnIcon:hover {
				background-color: #ecf4fa;
			}
		}

		.homeCarousel {
			// height: 490px;
			max-height: 490px;
			height: fit-content;
			margin: 0;
			padding: 50px 40px;
			color: #2e373c;
			text-align: left;
			background: #ecf4faa3;
			border-radius: 15px;

			.carouselTop {
				display: flex;
				justify-content: space-between;

				h2 {
					font-size: 2rem;
					cursor: pointer;
				}
				h2:hover {
					color: #f4791f;
				}

				.moduleTitle {
					color: #70727b;
					font-size: 1.5rem;
				}
			}
			.carouselDesc {
				font-size: 1.5rem;

				ol,
				ul {
					margin-left: 25px;
				}
			}
		}

		.custom-dots li {
			width: 20px;
			height: 20px;
			margin: 0 11px;
		}

		.custom-dots li button {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background-color: #dbebfc;
			border: none;
			opacity: 1;
		}

		.custom-dots li.slick-active button {
			background-color: #02aee8;
		}
	}

	.newHomeBox {
		margin-top: 33px;
		.head {
			padding-left: 41px;
			display: flex;
			align-items: center;
			font-size: 32px;
			font-weight: 400;
			color: rgba(244, 123, 32, 1);
			text-align: left;
			height: 110px;

			border-radius: 10.67px 10.67px, 0px, 0px;
			background: linear-gradient(
				90deg,
				rgba(44, 103, 164, 0.08) 0%,
				rgba(208, 222, 237, 0.08) 100%
			);
			img {
				width: 40px;
				margin-right: 16px;
				// margin-top: 12px;
			}
		}
		.head span {
			cursor: pointer;
		}
		.exclusivesBox {
			border-radius: 10.67px;
			border: 1.33px solid rgba(40, 57, 94, 0.2);
			.list {
				margin-top: 27px;
				ul {
					display: flex;
					height: 100%;
					width: 100%;
					justify-content: space-between;
					flex-wrap: wrap;
				}
				li {
					margin-left: 5%;
					width: 45%;
					font-size: 28px;
					font-weight: 500;
					color: rgba(50, 60, 71, 1);
					margin-bottom: 20px;
				}
			}
		}

		.articlesBox {
			width: 49%;
			margin-top: 30px;
			border-radius: 10.67px;
			border: 1.33px solid rgba(40, 57, 94, 0.2);
			@media screen and (max-width: @mobile) {
				width: 100%;
			}
			.list {
				margin-top: 27px;
				ul {
					display: flex;
					height: 100%;
					width: 100%;
					flex-direction: column;
				}
				li {
					margin-left: 70px;
					font-size: 28px;
					font-weight: 500;
					color: rgba(50, 60, 71, 1);
					margin-bottom: 45px;
					@media screen and (max-width: @mobile) {
						margin-left: 50px;
					}
				}
			}
		}
		.gamesBox {
			width: 49%;
			margin-top: 30px;
			border-radius: 10.67px;
			border: 1.33px solid rgba(40, 57, 94, 0.2);
			@media screen and (max-width: @mobile) {
				width: 100%;
			}
			.list {
				margin-top: 27px;
				ul {
					display: flex;
					height: 100%;
					width: 100%;
					flex-direction: column;
				}
				li {
					margin-left: 70px;
					font-size: 28px;
					font-weight: 500;
					color: rgba(50, 60, 71, 1);
					margin-bottom: 25px;
					@media screen and (max-width: @mobile) {
						margin-left: 50px;
					}
				}
				span {
					font-size: 28px;
					font-weight: 700;
					color: rgba(0, 55, 120, 1);
					margin-right: 10px;
				}
			}
		}
		.financialBox {
			margin-top: 30px;
			width: 49%;
			border-radius: 10.67px;
			border: 1.33px solid rgba(40, 57, 94, 0.2);
			@media screen and (max-width: @mobile) {
				width: 100%;
			}
			.info {
				margin-top: 27px;
				margin-left: 50px;
				font-size: 28px;
				font-weight: 500;
				color: rgba(50, 60, 71, 1);
				@media screen and (max-width: @mobile) {
					margin-left: 15px;
				}
			}
			.list {
				margin-top: 47px;
				ul {
					display: flex;
					flex-direction: row;
					width: 100%;
					flex-wrap: wrap;
					justify-content: space-between;
				}
				li {
					width: 40%;
					margin-left: 70px;
					font-size: 28px;
					font-weight: 500;
					color: rgba(50, 60, 71, 1);
					margin-bottom: 25px;
					@media screen and (max-width: @mobile) {
						margin-left: 50px;
					}
				}
			}
		}
		.esportsBox {
			margin-top: 30px;
			width: 49%;
			border-radius: 10.67px;
			border: 1.33px solid rgba(40, 57, 94, 0.2);
			@media screen and (max-width: @mobile) {
				width: 100%;
			}
			.info {
				margin-top: 27px;
				margin-left: 50px;
				font-size: 28px;
				font-weight: 500;
				color: rgba(50, 60, 71, 1);
				@media screen and (max-width: @mobile) {
					margin-left: 15px;
				}
			}
			.list {
				margin-top: 17px;
				ul {
					display: flex;
					flex-direction: row;
					width: 100%;
					flex-wrap: wrap;
					justify-content: space-between;
				}
				li {
					width: 40%;
					margin-left: 70px;
					font-size: 28px;
					font-weight: 500;
					color: rgba(50, 60, 71, 1);
					margin-bottom: 18px;
					@media screen and (max-width: @mobile) {
						margin-left: 50px;
					}
				}
			}
		}
	}
}

.flexRow {
	flex-direction: row;
}

// .cardContainer {
// 	border-radius: 20px;
// }

.card {
	border: 3px solid #2864a15c;
	border-radius: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	text-align: center;
	height: 100%; /* Maintain consistent height */
	background-color: #f9fcfe;
	padding-left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.card-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		.top-part {
			flex-grow: 1;
			margin-bottom: 2.2rem;
		}
		.bottom-part {
			display: flex;
			justify-content: center;
			margin-top: auto;
			margin-bottom: 1rem;
		}
	}
}

.card-description {
	font-size: 1.5vw;
	color: #15181a;
	margin-bottom: 1rem;
	text-align: center;
	letter-spacing: 0px;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.card-links {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.card-link {
	font-size: 1.5rem;
	color: #f4791f;
	text-decoration: underline;
	font-weight: normal;
	cursor: pointer;
	margin-bottom: 5px;
	margin-top: auto; /* Push to the bottom */
	align-items: center;
	display: inline-flex;
}
.card-title {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 8px;
}
.card-btm-link {
	margin-top: 5rem;
}
.card-link .linkIcon {
	transition: transform 0.4s;
	margin-left: 3px;
}

.card-link:hover .linkIcon {
	transform: translateX(10px);
}

.card-soon {
	color: #656774;
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 0 !important;
}

.trendHeader {
	color: #2864a1;
	margin-left: 50px;
	margin-top: 60px;
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 10px;
	@media screen and (max-width: @mobile) {
		margin-left: unset;
	}
}

.textOnly {
	font-size: 20px;
	font-style: italic;
	color: #f4791f;
	margin-right: 3rem;
}
.homeSelect {
	height: 50px !important;
	margin-right: 50px !important;
	margin-top: 70px !important;

	.ant-select-selector {
		border: none !important;
		font-size: 1.5rem;
	}
	.ant-select-arrow {
		color: black !important;
	}
	.ant-select-selection-item {
		padding-right: 30px !important;
	}
}

.headCollapse {
	color: #2e373c !important;
	font-weight: 500;
}
.textCollapse {
	color: #2e373c !important;
}

//最小宽度分辨率媒体查询
@media (min-width: 2500px) {
	.newHomeContainer {
		zoom: 0.7;
	}
}

@media (max-width: 500px) {
	.card-description {
		font-size: 1.7rem;
	}
}

@media (min-width: 500px) and (max-width: 995px) {
	.card-description {
		font-size: 1.5rem;
	}
}

@media (max-width: 995px) {
	.cardContainer {
		margin-bottom: 2rem;
	}
}

@media (min-width: 995px) {
	.card-description {
		font-size: 1.5rem;
	}
	.customCol {
		display: block;
		flex: 0 0 24%;
		max-width: 24%;
	}
}

@primary-color: #003778;@border-radius-base: 6px;@layout-header-background: #ffffff;@layout-sider-background: #003778;@menu-bg: #003778;@menu-inline-toplevel-item-height: 94px;@menu-item-height: 53px;@menu-item-color: #ffffff;@menu-highlight-color: #ffffff;@menu-inline-submenu-bg: #003778;@menu-item-active-bg: rgba(128, 160, 231, 0.3);@menu-popup-bg: #003778;@menu-item-active-border-width: 0px;