.BlogDetailLabel img {
  max-width: 100vw !important;
}
.ant-breadcrumb ol li {
  cursor: unset;
}
.ant-breadcrumb ol li span {
  color: #2e373c;
}
.ant-select-dropdown {
  top: 35px !important;
  left: 0 !important;
}
.truncate {
  white-space: nowrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Hides the overflow */
  text-overflow: ellipsis;
  /* Adds ellipsis (...) for overflow text */
  display: block;
  /* Makes the div a block-level element */
  max-width: 90vw;
  /* Limits the width to the parent container */
  box-sizing: border-box;
  /* Ensures padding/border are included in the width */
}
.ant-breadcrumb {
  width: 80vw;
  margin-top: 3rem !important;
  margin-left: 50px !important;
}
@media (max-width: 768px) {
  .ant-breadcrumb {
    margin-left: 20px !important;
  }
}
/* Adjustments for small screens */
@media (max-width: 768px) {
  .layout_page_header {
    padding-bottom: 10px;
  }
  .ant-breadcrumb {
    width: 77vw;
  }
  .truncate {
    max-width: 90vw;
    /* Limits the width to the parent container */
    margin-left: 0px !important;
  }
  .truncate2 {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .footer-links {
    flex-wrap: wrap;
  }
}
@media (max-width: 550px) {
  .ant-breadcrumb ol {
    flex-wrap: wrap !important;
    /* Allow items to wrap onto multiple lines */
    align-items: center !important;
    width: 100%;
    /* Adjust width as needed */
    white-space: normal;
    /* Ensure text wraps properly */
  }
  .ant-breadcrumb ol li {
    cursor: pointer !important;
  }
  .ant-breadcrumb::-webkit-scrollbar {
    height: 2px !important;
    /* Set the height of the scrollbar (thickness) */
  }
  .ant-breadcrumb::-webkit-scrollbar-thumb {
    background-color: #b6b6b6;
    /* Set the color of the scrollbar thumb */
    border-radius: 40px;
    /* Optional: round the edges of the scrollbar thumb */
  }
  /* Custom scrollbar for Firefox */
  .ant-breadcrumb {
    scrollbar-width: thin;
    /* Make the scrollbar thinner */
    scrollbar-color: #b6b6b6 transparent;
    /* Set the thumb color and track color */
  }
  .truncate {
    width: 300px;
    /* Set the desired width for the container */
    word-wrap: break-word;
    /* Allows breaking of long words */
    word-break: break-word;
    /* Ensures long words are broken */
    overflow-wrap: break-word;
    /* Modern equivalent for consistent behavior */
    white-space: normal;
    /* Allows text to wrap naturally */
    overflow: visible;
    /* Ensures no content is hidden */
  }
  .truncate2 {
    width: 1000px;
  }
  .ant-layout-header {
    position: static !important;
  }
}
@media (max-width: 400px) {
  .ant-breadcrumb {
    white-space: nowrap;
    /* Prevent line breaks */
    margin-top: 0.5rem !important;
    margin-left: 16px !important;
  }
}
.common {
  height: unset !important;
}
.common .btn_1 {
  font-size: 18px;
  width: 228px;
  height: 54px;
  align-items: center;
  color: #2864a1 !important;
  background-color: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}
.common .btn_1:hover {
  color: #2864a1 !important;
  border: #2864a1 2px solid !important;
}
.common .btn_2 {
  font-size: 18px;
  height: 54px;
  width: 228px;
  align-items: center;
  color: #ffffff !important;
  background-color: #f47b23 !important;
  border: none !important;
  border-radius: 6px;
  transition: background-color 0.3s, color 0.3s;
}
.common .btn_2:hover {
  background-color: #d3691c !important;
  /* Slightly darker shade */
}
@media (max-width: 600px) {
  .common .btn_1 {
    width: 128px;
  }
  .common .btn_2 {
    width: 128px;
  }
}
@media (max-width: 360px) {
  .common .btn_1 {
    width: 90px;
  }
  .common .btn_2 {
    width: 90px;
  }
}
.common .ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  line-height: 1.8;
  text-align: center;
}
.common .pro .ant-input {
  height: 20px !important;
}
.common .ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
}
.common .ant-input- .change_Password {
  padding: 20px 0px 50px 0px;
}
.common .ant-input- .change_Password .ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  height: 43px;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}
.common .ant-input- .change_Password .ant-input-affix-wrapper > input.ant-input {
  font-size: 16px;
  padding: 0;
  border: none;
  outline: none;
}
.common .ant-input- .change_Password .ant-form-item-label > label {
  padding-top: 6px;
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: #666666;
  font-size: 18px;
}
.common .ant-modal-title {
  padding-left: 17px;
  font-size: 20px;
}
.common .ant-modal-body {
  padding: 24px;
}
.common .ant-form {
  border-bottom: 1px solid #f0f0f0;
}
.common .ant-result {
  border-bottom: 1px solid #f0f0f0 !important;
}
.layout_container {
  /* Media Queries for smaller screens */
}
.layout_container .ant-layout-header {
  width: 100%;
  box-shadow: 0px 2px 12px 0px rgba(40, 57, 94, 0.11);
  height: auto;
  line-height: unset;
  padding: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
}
.layout_container .layout_page_header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.layout_container .layout_page_header .logo {
  width: 137px;
  height: 31px;
  margin-right: 40px;
}
@media (max-width: 480px) {
  .layout_container .layout_page_header .logo {
    width: 120px;
    height: 31px;
    margin-right: 15px;
  }
}
.layout_container .layout_page_header .header_rows {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.layout_container .layout_page_header .left_content {
  padding: 22px 72px 22px 23px;
}
@media (max-width: 480px) {
  .layout_container .layout_page_header .left_content {
    padding: 16px 0px 0px 0px;
  }
}
.layout_container .layout_page_header .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  background-color: #eaebef;
  color: #8992a6;
}
.layout_container .layout_page_header .rows_search .ant-input-affix-wrapper {
  width: 35vw;
  height: 40px;
  border-radius: 28px;
  overflow: hidden;
  margin: 0px 30px 0px 0px;
  padding: 0 32px;
  background-color: #eaebef;
  border: 1px solid #9ca4b5;
}
.layout_container .layout_page_header .rows_search .ant-input-group .ant-input-group-addon:last-child {
  display: none;
}
.layout_container .layout_page_header .help_doc {
  margin: 0 40px;
  cursor: pointer;
}
.layout_container .layout_page_header .suffix_icon {
  font-size: 20px;
  vertical-align: bottom;
}
.layout_container .layout_page_header .help_tips {
  font-size: 16px;
  color: #999;
}
.layout_container .layout_page_header .login_con {
  min-width: 200px;
  height: 100%;
  padding: 5px 22px 0px 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .layout_container .layout_page_header .login_con {
    justify-content: flex-end;
  }
}
.layout_container .layout_page_header .login_con .login_user_icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  width: 56px;
  height: 56px;
  background-color: #f5f5f5;
  border-radius: 50%;
  overflow: hidden;
}
.layout_container .layout_page_header .login_con .login_user_name {
  margin: 0 20px;
  font-size: 16px;
  color: #333;
}
@media (max-width: 550px) {
  .layout_container .layout_page_header .login_con {
    min-width: unset !important;
    margin-top: 5px;
  }
}
@media (max-width: 550px) {
  .layout_container .layout_page_header .login_user_name {
    overflow-x: scroll;
    /* Enable horizontal scrolling */
    white-space: nowrap;
    /* Prevent line breaks */
    width: 80px;
  }
}
.layout_container .layout_page_header .login_con:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.layout_container .layout_main .overflowY {
  overflow: hidden;
}
.layout_container .layout_main .ant-menu {
  background: none;
}
.layout_container .layout_main .ant-menu-item {
  height: 45px;
  margin-bottom: 6px;
}
.layout_container .layout_main .ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 calc(50% - 22px / 2) !important;
  height: 48px !important;
}
.layout_container .layout_main .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 calc(50% - 22px / 2) !important;
  height: 48px !important;
}
.layout_container .layout_main .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
  margin-bottom: 5px;
}
.layout_container .layout_main .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon {
  margin-bottom: 5px;
}
.layout_container .layout_main .ant-menu-submenu-title {
  line-height: 45px;
  height: 45px;
  margin-bottom: 6px;
}
.layout_container .layout_main .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  margin-top: 5px;
}
.layout_container .layout_main .ant-menu-title-content {
  line-height: 45px;
  height: 45px;
}
.layout_container .layout_main .ant-menu-item:hover {
  border-radius: 4px;
  background-color: #418dc8;
}
@media (max-width: 767px) {
  .layout_container .layout_main {
    /* This is the breakpoint for mobile devices */
  }
  .layout_container .layout_main .ant-menu-item {
    text-align: center;
  }
  .layout_container .layout_main .ant-menu-submenu {
    text-align: center;
  }
}
.layout_container .layout_main .ant-menu-inline {
  border-right: none;
}
.layout_container .layout_main .ant-menu-item-selected {
  border-radius: 4px;
  background-color: #418dc8;
}
.layout_container .layout_main .subMenu_icon {
  width: 34px;
  height: 32px;
}
.layout_container .layout_main .ant-layout-sider {
  background: transparent linear-gradient(180deg, #1272bc 0%, #024f91 100%) 0% 0% no-repeat padding-box;
}
.layout_container .layout_main .ant-menu-vertical > .ant-menu-item {
  line-height: 0;
}
.layout_container .layout_main .ant-layout-sider-trigger {
  position: relative;
  background: #02aee8;
}
@media (max-width: 767px) {
  .layout_container {
    /* This is the breakpoint for mobile devices */
  }
  .layout_container .ant-menu-title-content span {
    width: 0px !important;
  }
  .layout_container .ant-menu-item span {
    text-align: center;
    width: 0px !important;
    height: 0px !important;
  }
  .layout_container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    text-align: center;
  }
}
.layout_container .footer-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 4rem;
}
.layout_container .footer-container .custom-footer {
  width: 100%;
  background-color: #f7f7f7;
  padding: 30px 30px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.layout_container .footer-container .custom-footer .footer-links {
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
  gap: 2.5rem;
}
.layout_container .footer-container .custom-footer .footer-links .footer-link {
  color: #656774;
  text-decoration: underline;
  font-size: 0.9rem;
  font-weight: 500;
}
.layout_container .footer-container .custom-footer .footer-lower {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.layout_container .footer-container .custom-footer .footer-lower .footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.layout_container .footer-container .custom-footer .footer-lower .footer-content .footer-logo {
  margin-right: 2.5rem;
  width: 9rem;
  height: 2rem;
}
.layout_container .footer-container .custom-footer .footer-lower .footer-content .footer-text {
  font-size: 0.8rem;
  color: #656774;
  text-align: left;
}
.layout_container .footer-container .custom-footer .footer-lower .footer-copyright {
  font-size: 0.8rem;
  color: #656774;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-width: 768px) {
  .layout_container .custom-footer {
    padding: 10px 10px !important;
    flex-direction: column;
    align-items: center;
  }
  .layout_container .custom-footer .footer-links {
    gap: 0rem;
  }
  .layout_container .custom-footer .footer-lower {
    flex-direction: column;
    align-items: center;
  }
  .layout_container .custom-footer .footer-lower .footer-content {
    flex-direction: column;
    align-items: center;
  }
  .layout_container .custom-footer .footer-lower .footer-content .footer-logo {
    margin: 0 0 1rem 0;
  }
  .layout_container .custom-footer .footer-lower .footer-copyright {
    text-align: center;
  }
}
@media (max-width: 480px) {
  .layout_container .footer-link {
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
  }
  .layout_container .footer-text,
  .layout_container .footer-copyright {
    font-size: 0.7rem;
  }
}
.ant-breadcrumb-separator {
  margin: 0 16px;
  vertical-align: text-bottom;
  color: #2e373c !important;
}
.breadcrumb_item {
  font-size: 16px;
  color: #02aee8;
}
.breadcrumb_item_active {
  color: #2e373c;
}
.breadcrumb_item_esports span {
  color: #02aee8 !important;
  cursor: pointer !important;
}
.breadcrumb-three li:nth-child(2) span {
  color: #02aee8;
  cursor: pointer !important;
}
