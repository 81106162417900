@media (max-width: 768px) {
  .insght_lst_link {
    display: inline-block !important;
    width: 100% !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }
}
@media (max-width: 768px) {
  .secBlue {
    width: 120px;
    color: #ffffff !important;
    background-color: #02aee8 !important;
    border: none !important;
    border-radius: 20px;
    height: 100% !important;
    padding-top: 8px;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
    margin-bottom: 0;
    margin-top: 30px;
    margin-left: 10px;
  }
}
.secBlue {
  min-width: 108px !important;
  min-height: 40px !important;
  display: flex !important;
  align-items: center !important;
  color: #ffffff !important;
  background-color: #02aee8 !important;
  border: none !important;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  margin-bottom: 0;
}
/* Disabled state styles */
.secBlue:disabled {
  color: #a8e1f5 !important;
  background-color: #e3f6fc !important;
  border: none !important;
  pointer-events: none;
  /* Prevents hover styles from being applied */
}
/* Hover state styles */
.secBlue:hover {
  background-color: #0099cc !important;
}
/* Prevent hover styles when disabled */
.secBlue:disabled:hover {
  color: #a8e1f5 !important;
  background-color: #e3f6fc;
  border: none !important;
}
.homeContainer {
  width: 100%;
  background-color: #fff;
}
.homeContainer .homeContent .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 30px 40px;
  background-color: #fff;
}
.homeContainer .homeContent .body .bodyItem {
  display: flex;
  flex-direction: column;
  width: 498px;
  height: 720px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemTitle {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: 600;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 40px 20px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .tip {
  position: absolute;
  left: -1px;
  top: -1px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left {
  display: flex;
  align-items: center;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left .img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left .img .pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .left .name {
  margin-left: 16px;
  color: #333;
  font-size: 24px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .top .right .score {
  color: #f49420;
  font-size: 40px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .info {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 20px 0;
  background-image: linear-gradient(to bottom right, #f1f1f1, #fcfcfc);
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .info .left {
  display: flex;
  align-items: center;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .info .left .text2 {
  margin-left: 80px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemRank {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  font-size: 24px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .two {
  background-color: #d0d3dc;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .three {
  background-color: #f1bf78;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo .ItemImg {
  width: 32px;
  height: 32px;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo .ItemImg .pic {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.homeContainer .homeContent .body .bodyItem .bodyItemCon .level .levelItem .levelItemL .ItemInfo .ItemName {
  width: 120px;
  margin-left: 10px;
}
.homeContainer .homeContent .footer {
  display: flex;
  padding-bottom: 100px;
  background-color: #fff;
  overflow: hidden;
}
.homeContainer .homeContent .footer .bgc {
  position: relative;
  z-index: 1;
  left: -130px;
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  background-image: linear-gradient(rgba(87, 160, 209, 0.1), rgba(44, 103, 164, 0.1));
}
.homeContainer .homeContent .footer .polyLine {
  display: flex;
  position: relative;
  margin: 100px 0 0 -530px;
  z-index: 999;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .info {
  color: #333;
  font-size: 40px;
  font-weight: 600;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .question {
  margin: 36px 0 50px 0;
  width: 478px;
  color: #666;
  font-size: 20px;
  line-height: 28px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type {
  display: flex;
  align-items: center;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left,
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left .l,
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right .l {
  width: 22px;
  height: 8px;
  margin-right: 20px;
  background-color: #5883f7;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left .r,
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right .r {
  color: #333;
  font-size: 16px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .left {
  margin-right: 40px;
}
.homeContainer .homeContent .footer .polyLine .polyLineTitle .type .right .l {
  background-color: #5dd29d;
}
.newDetail {
  background-size: 100% 100% !important;
  padding-top: 68px;
  width: 100%;
}
.newDetail .newContainer {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
@media (max-width: 768px) {
  .newDetail .newContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.newDetail .newContainer .title {
  font-size: 3rem;
  font-weight: 700;
  color: #28395e;
}
@media (max-width: 768px) {
  .newDetail .newContainer .title {
    font-size: 1.5rem;
  }
}
.newDetail .newContainer .companyTags,
.newDetail .newContainer .gameTags {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 500;
}
.newDetail .newContainer .gameTags {
  background-color: #def4e7;
  color: #19ae54;
}
.newDetail .newContainer .gameTags:hover {
  color: white;
  background-color: #19ae54;
}
.newDetail .newContainer .companyTags {
  background-color: #f1dff1;
  color: #9e219e;
}
.newDetail .newContainer .companyTags:hover {
  color: white;
  background-color: #9e219e;
}
.newDetail .newContainer .headerInfo {
  margin-top: 27px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 33px;
  border-bottom: 1px solid #c7d9f2;
}
@media (max-width: 768px) {
  .newDetail .newContainer .headerInfo {
    flex-direction: column-reverse;
    gap: 20px;
  }
}
.newDetail .newContainer .headerInfo span {
  margin-right: 2.5rem;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}
.newDetail .newContainer .headerInfo span:last-child {
  margin-right: 0;
}
.newDetail .newContainer .headerInfo .link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.newDetail .newContainer .contentBox {
  margin-top: 18px;
  width: 100%;
}
.newDetail .newContainer .contentBox p,
.newDetail .newContainer .contentBox span,
.newDetail .newContainer .contentBox font {
  font-size: 18px !important;
}
.newDetail .newContainer .contentBox img {
  width: 100% !important;
  height: fit-content !important;
  border-radius: 12px;
}
.newDetail .newContainer .contentBox ul li {
  list-style: disc inside !important;
  padding-left: 1em;
  /* Adjust the padding as needed */
  font-size: medium;
}
.newDetail .newContainer .contentBox ol li {
  list-style: decimal inside !important;
  padding-left: 1em;
  /* Adjust the padding as needed */
  font-size: medium;
}
.newDetail .newContainer .footerInfo {
  margin-top: 8px;
}
.newDetail .newContainer .footerInfo .link {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 3rem;
  color: #02aee8;
}
.newDetail .newContainer .footerInfo .link span {
  color: rgba(153, 153, 153);
}
.newDetail .newContainer .footerInfo .link a {
  display: flex;
  font-weight: 500;
  color: #02aee8;
}
.newDetail .newContainer .footerInfo .link a span {
  color: #02aee8;
}
.newDetail .newContainer .footerInfo .link a:hover {
  text-decoration: underline;
}
.newDetail .newContainer .footerInfo .btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
.newDetail .newContainer .footerInfo .btns .last-btn {
  margin-left: 1rem;
}
.newDetail .newContainer .footerInfo .btns .btnIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #88acce;
}
.newDetail .newContainer .footerInfo .btns .btnIcon img {
  width: 45%;
  height: 45%;
}
.newDetail .newContainer .footerInfo .btns .btnIcon:hover {
  background-color: #ecf4fa;
}
.newDetail .newContainer .topNews,
.newDetail .newContainer .topBlogs {
  margin-top: 74px;
  height: fit-content;
  border-radius: 12px;
  box-sizing: border-box;
}
.newDetail .newContainer .topNews .title,
.newDetail .newContainer .topBlogs .title {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  height: 83px;
  line-height: 83px;
  font-size: 30px;
  font-weight: 700;
  color: #2864a1;
  border-bottom: 1px solid #c7d9f2;
}
.newDetail .newContainer .topNews .newsItem,
.newDetail .newContainer .topBlogs .newsItem {
  padding: 25px 30px 35px 30px;
  border-bottom: 1px solid #c7d9f2;
}
.newDetail .newContainer .topNews .newsItem .head,
.newDetail .newContainer .topBlogs .newsItem .head {
  cursor: pointer;
  font-size: 27px;
  font-weight: 700;
  color: #2e373c;
  letter-spacing: -0.1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newDetail .newContainer .topNews .newsItem .head .topDate,
.newDetail .newContainer .topBlogs .newsItem .head .topDate {
  color: #70727b;
  font-size: 14px;
  font-weight: normal;
}
.newDetail .newContainer .topNews .newsItem .text,
.newDetail .newContainer .topBlogs .newsItem .text {
  margin-top: 8px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}
.newDetail .newContainer .topNews .newsItem:hover,
.newDetail .newContainer .topBlogs .newsItem:hover {
  background-color: #f4fbff;
}
.breaker {
  border-bottom: 1px solid #c7d9f2;
}
.listLink {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  margin: 1rem 1rem 1.5rem 0;
  color: rgba(153, 153, 153);
}
@media (max-width: 768px) {
  .text-element {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .listLink {
    margin: 0rem 0rem 0rem 0;
  }
}
.listLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
