.homeContainer {
	width: 100%;
	background-color: #fff;
	.homeContent {
		.body {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			padding: 30px 40px;
			background-color: #fff;
			.bodyItem {
				display: flex;
				flex-direction: column;
				width: 498px;
				height: 720px;
				.bodyItemTitle {
					margin-bottom: 20px;
					color: #333;
					font-size: 24px;
					font-weight: 600;
				}
				.bodyItemCon {
					position: relative;
					display: flex;
					flex-direction: column;
					border: 1px solid #ccc;
					border-radius: 4px;
					padding: 40px 20px;
					.tip {
						position: absolute;
						left: -1px;
						top: -1px;
					}
					.top {
						display: flex;
						align-items: center;
						justify-content: space-between;
						.left {
							display: flex;
							align-items: center;
							.img {
								width: 100px;
								height: 100px;
								border-radius: 50%;
								.pic {
									width: 100px;
									height: 100px;
									border-radius: 50%;
								}
							}
							.name {
								margin-left: 16px;
								color: #333;
								font-size: 24px;
							}
						}
						.right {
							.score {
								color: #f49420;
								font-size: 40px;
							}
						}
					}
					.info {
						display: flex;
						justify-content: space-between;
						margin-top: 30px;
						padding: 20px 0;
						background-image: linear-gradient(
							to bottom right,
							#f1f1f1,
							#fcfcfc
						);
						.left {
							display: flex;
							align-items: center;
							.text2 {
								margin-left: 80px;
							}
						}
					}
					.level {
						display: flex;
						flex-direction: column;
						padding-top: 30px;
						.levelItem {
							display: flex;
							align-items: center;
							justify-content: space-between;
							.levelItemL {
								display: flex;
								align-items: center;
								margin-bottom: 36px;
								.ItemRank {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 32px;
									height: 32px;
									border-radius: 50%;
									background-color: #ccc;
									color: #fff;
									font-size: 24px;
								}
								.two {
									background-color: #d0d3dc;
								}
								.three {
									background-color: #f1bf78;
								}
								.ItemInfo {
									display: flex;
									align-items: center;
									justify-content: center;
									margin-left: 40px;
									.ItemImg {
										width: 32px;
										height: 32px;
										.pic {
											width: 32px;
											height: 32px;
											border-radius: 50%;
										}
									}
									.ItemName {
										width: 120px;
										margin-left: 10px;
									}
								}
							}
						}
					}
				}
			}
		}
		.footer {
			display: flex;
			padding-bottom: 100px;
			background-color: #fff;
			overflow: hidden;
			.bgc {
				position: relative;
				z-index: 1;
				left: -130px;
				width: 40rem;
				height: 40rem;
				border-radius: 50%;
				background-image: linear-gradient(
					rgba(87, 160, 209, 0.1),
					rgba(44, 103, 164, 0.1)
				);
			}
			.polyLine {
				display: flex;
				position: relative;
				margin: 100px 0 0 -530px;
				z-index: 999;
				.polyLineTitle {
					display: flex;
					flex-direction: column;
					margin-right: 50px;
					.info {
						color: #333;
						font-size: 40px;
						font-weight: 600;
					}
					.question {
						margin: 36px 0 50px 0;
						width: 478px;
						color: #666;
						font-size: 20px;
						line-height: 28px;
					}
					.type {
						display: flex;
						align-items: center;
						.left,
						.right {
							display: flex;
							align-items: center;
							justify-content: center;
							.l {
								width: 22px;
								height: 8px;
								margin-right: 20px;
								background-color: #5883f7;
							}
							.r {
								color: #333;
								font-size: 16px;
							}
						}
						.left {
							margin-right: 40px;
						}
						.right {
							.l {
								background-color: #5dd29d;
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-width: 800px) {
	.GridBox
		.footer_Mod_Box
		.footer_Mod_Label
		.Niko_Index_Box
		.Niko_Index_Text_Box {
		font-size: 16px;
	}
}
@media only screen and (min-width: 1000px) {
	.GridBox
		.footer_Mod_Box
		.footer_Mod_Label
		.Niko_Index_Box
		.Niko_Index_Text_Box {
		font-size: 18px;
	}
}

@media only screen and (min-width: 1260px) {
	.GridBox
		.footer_Mod_Box
		.footer_Mod_Label
		.Niko_Index_Box
		.Niko_Index_Text_Box {
		font-size: 20px;
	}
}

@media only screen and (min-width: 1580px) {
	.GridBox
		.footer_Mod_Box
		.footer_Mod_Label
		.Niko_Index_Box
		.Niko_Index_Text_Box {
		font-size: 22px;
	}
}
.GridBox {
	.RowBox {
		.Icafe_Details_Top {
			display: flex;
			margin-bottom: 25px;
			flex-direction: row;

			.Icafe_Details_Logo {
				width: 180px;
				height: 180px;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100% auto;
				border-radius: 4px;
			}

			> div:nth-child(2) {
				display: flex;
				margin-left: 20px;
				flex-direction: column;
				align-content: flex-start;
				justify-content: center;

				.Icafe_Name {
					text-align: left;
					font-size: 26px;
				}

				.Icafe_details {
					font-size: 20px;

					span {
						color: #456bd3;
					}
				}
			}
		}
	}
}

@primary-color: #003778;@border-radius-base: 6px;@layout-header-background: #ffffff;@layout-sider-background: #003778;@menu-bg: #003778;@menu-inline-toplevel-item-height: 94px;@menu-item-height: 53px;@menu-item-color: #ffffff;@menu-highlight-color: #ffffff;@menu-inline-submenu-bg: #003778;@menu-item-active-bg: rgba(128, 160, 231, 0.3);@menu-popup-bg: #003778;@menu-item-active-border-width: 0px;